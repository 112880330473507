import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { DeepScanTargetDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { useRescanDeepScanTarget } from 'queries/useDeepScan';
import React, { useCallback } from 'react';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    margin: theme.spacing(4),
  },
  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  deepScanTarget: DeepScanTargetDTO;
  className?: string;
  locked?: boolean;
}

export const RescanButton: React.FC<Props> = ({ deepScanTarget, className, locked }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useRescanDeepScanTarget(deepScanTarget.deepScanId, deepScanTarget.id, {
    onSuccess: () => {
      setOpen(false);
      showToaster('Rescan scheduled successfully');
    },
    onError: (error) => {
      setOpen(false);
      toasterErrorHandler(error, 'Failed to schedule rescan');
    },
  });

  const handleConfirmClick = useCallback(() => {
    mutate();
  }, [mutate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        className={className}
        onClick={handleClickOpen}
        startIcon={<RefreshRoundedIcon />}
      >
        Rescan
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {locked ? (
          <div className={classes.disclaimer}>
            <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
              Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> to upgrade account.
            </SecurityCard.Badge>
          </div>
        ) : (
          <>
            <DialogTitle>Confirm Rescan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This will remove existing data and schedule rescan on device &quot;{deepScanTarget.hostname}&quot;.
                Please ensure you have latest report generated, if you want a snapshot of current data. Do you want to
                proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                onClick={handleConfirmClick}
                variant='contained'
                color='primary'
                disabled={isLoading}
                startIcon={<RefreshRoundedIcon />}
              >
                Confirm
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
