import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  singleRecord?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  table: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    overflowX: 'auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },

    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${COLORS.GREY_4}`,
      ...(props.singleRecord && {
        borderTop: `1px solid ${COLORS.GREY_4}`,
      }),
    },
  }),
  row: (props) => ({
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'grid',

      ...(props.singleRecord && {
        gridTemplateColumns: '1fr 1fr',
      }),

      ...(!props.singleRecord && {
        padding: `${theme.spacing(1.5)}px 0`,
      }),
    },
  }),
  rowInnerWrapper: (props) => ({
    display: 'flex',
    width: '100%',
    gap: `0 ${theme.spacing(6)}px`,

    [theme.breakpoints.down('sm')]: {
      gap: `0 ${theme.spacing(2)}px`,

      ...(!props.singleRecord && {
        color: COLORS.GREY_2,
      }),
    },
  }),

  cell: (props) => ({
    ...TYPOGRAPHY.REGULAR_REGULAR,
    padding: `0 ${theme.spacing(6)}px`,
    paddingBottom: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      ...(props.singleRecord && {
        padding: `0 ${theme.spacing(14.5)}px`,
      }),
    },

    [theme.breakpoints.down('sm')]: {
      padding: 0,

      ...(props.singleRecord && {
        paddingLeft: theme.spacing(2.5),
      }),
    },
  }),

  firstCell: {
    [theme.breakpoints.up('md')]: {
      paddingTop: `${theme.spacing(3)}px !important`,
    },
  },

  headWrapper: {
    borderRight: `1px solid ${COLORS.GREY_4}`,

    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  head: (props) => ({
    width: 200,
    maxWidth: 200,
    padding: `0 ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(3),
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,

    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'initial',
      padding: 0,
      paddingBottom: theme.spacing(2.5),

      ...(props.singleRecord && {
        ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
        paddingRight: theme.spacing(2.5),
      }),

      ...(!props.singleRecord && {
        paddingBottom: theme.spacing(),
      }),
    },
  }),

  dividerBorder: {
    borderTop: `1px solid ${COLORS.GREY_4}`,
    paddingTop: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },

  dividerSpace: {
    paddingTop: theme.spacing(6.5),

    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
}));

type Divider = 'space' | 'border';

export interface Column<DataType> {
  title: string;
  render: (data?: DataType) => React.ReactNode | string;
  renderColumn?: (columnTitle?: string) => React.ReactNode | string;
  divider?: Divider;
}

export interface Props<DataType> {
  data: DataType[];
  columns: Array<Column<DataType>>;
  rowKey?: (el: DataType) => string;
}
export const VerticalTable = function <DataType>(props: Props<DataType>) {
  const { data, columns, rowKey } = props;
  const classes = useStyles({ singleRecord: data.length === 1 });

  return (
    <div className={classes.tableContainer}>
      <div className={classes.table}>
        {columns.map((column, idx) => {
          return (
            <div key={idx} className={classes.row}>
              <div
                className={cx(
                  classes.headWrapper,
                  column.divider === 'space' && classes.dividerSpace,
                  column.divider === 'border' && classes.dividerBorder,
                )}
              >
                <div className={cx(classes.cell, classes.head, idx === 0 && classes.firstCell)}>
                  {column.renderColumn ? column.renderColumn(column.title) : column.title}
                </div>
              </div>
              <div
                className={cx(
                  classes.rowInnerWrapper,
                  column.divider === 'space' && classes.dividerSpace,
                  column.divider === 'border' && classes.dividerBorder,
                )}
              >
                {data &&
                  data.map((el) => {
                    const elKey = rowKey ? rowKey(el) : idx;

                    return (
                      <div key={elKey} className={cx(classes.cell, idx === 0 && classes.firstCell)}>
                        {column.render(el)}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
