import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { useProposal } from 'queries/useProposal';
import React from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

interface ParamsType {
  id: string;
}

export const WebProposalRedirectView = () => {
  const { id } = useParams<ParamsType>();
  const { data, isLoading, isError } = useProposal(id);

  if (isLoading) {
    return <LoadingContainer fullScreen />;
  }

  if (isError || !data?.application) {
    return <NotFoundContainer fullScreen />;
  }

  return <Redirect to={insertIdToRoute(ROUTES.application.PROPOSAL, data.applicationId).replace(':proposalId', id)} />;
};
