import { Hidden, makeStyles } from '@material-ui/core';
import { AxiosError } from 'axios';
import { AgentLogo } from 'components/AgentLogo';
import { AttestationBreadcrumbs } from 'components/AttestationBreadcrumbs';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { verifyApplicationToken } from 'components/ProtectedApplicationRoute';
import { ApplicationStatus } from 'dtos/application';
import { ProposalWithApplicationDTO } from 'dtos/proposal';
import { QuoteDTO } from 'dtos/quotes';
import { useApplication, useApplicationDeclinations, useApplicationQuotes } from 'queries/useApplications';
import { useLatestProposalForApplication, useProposal } from 'queries/useProposal';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { useHistory, useParams } from 'react-router';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ChooseQuoteSection } from './sections/ChooseQuoteSection';
import { ReviewQuoteSelectionSection } from './sections/ReviewQuoteSelectionSection';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: COLORS.WHITE,
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: 0,
    padding: `0 ${theme.spacing(7.5)}px`,
    height: 60,
  },
  agentLogo: {
    position: 'absolute',
  },

  heading: {
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
  },
  link: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.BLUE_1,
  },
}));

interface ParamsType {
  id: string;
  proposalId: string;
}

export const WebProposalView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, proposalId } = useParams<ParamsType>();
  const [activeItemIdx, setActiveItemIdx] = useState(1);
  const [selectedQuote, setSelectedQuote] = useState<QuoteDTO>();
  const [isCheckingToken, setIsCheckingToken] = useState(proposalId ? true : false);

  const isProposalBased = !!proposalId;

  const { isLoading: isLoadingApplication } = useApplication(id, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // redirect to the form if the application is not >= submitted
      [ApplicationStatus.NOT_STARTED, ApplicationStatus.IN_PROGRESS, ApplicationStatus.IN_REVIEW].includes(
        data.applicationStatus,
      ) && history.push(insertIdToRoute(ROUTES.application.FORM, id));
    },
  });

  // proposal V1
  const fnHook: (
    id: string,
    options?: UseQueryOptions<ProposalWithApplicationDTO, AxiosError>,
  ) => UseQueryResult<ProposalWithApplicationDTO> =
    proposalId == 'latest' ? useLatestProposalForApplication : useProposal;

  const {
    data: proposalData,
    isLoading: isLoadingProposal,
    isError: isErrorProposal,
  } = fnHook(proposalId == 'latest' ? id : proposalId, {
    enabled: isProposalBased,
    onSuccess: () => {
      verifyApplicationToken(id, setIsCheckingToken);
    },
    onError: () => setIsCheckingToken(false),
  });

  // proposal V2
  const {
    data: quotesData,
    isLoading: isLoadingQuotes,
    isError: isErrorQuotes,
  } = useApplicationQuotes(id, {
    enabled: !isProposalBased,
  });

  const quotes = proposalData?.quotes || quotesData;

  const { data: declinations, isLoading: isLoadingDeclinations } = useApplicationDeclinations(id);

  const handleSelectDifferentQuote = () => {
    setSelectedQuote(undefined);
    setActiveItemIdx(1);
  };

  const handleSelectQuote = (quote: QuoteDTO) => {
    setSelectedQuote(quote);
    setActiveItemIdx(2);
  };

  if (isLoadingProposal || isLoadingQuotes || isCheckingToken || isLoadingDeclinations || isLoadingApplication) {
    return <LoadingContainer fullScreen />;
  }

  if (isErrorProposal || isErrorQuotes || (isProposalBased && !proposalData) || (!isProposalBased && !quotesData)) {
    return <NotFoundContainer fullScreen />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Web Proposal</title>
      </Helmet>

      <Hidden smDown>
        <nav className={classes.nav}>
          <AgentLogo applicationId={id} logoSize={30} className={classes.agentLogo} />
          <AttestationBreadcrumbs activeItemIdx={activeItemIdx} applicationId={id} />
        </nav>
      </Hidden>

      {(!quotes || quotes.length === 0) && !declinations ? (
        <div className={classes.heading} key='heading'>
          <h1 className={classes.title}>
            <Hidden>We weren’t able to find any quotes for you...</Hidden>
          </h1>
        </div>
      ) : selectedQuote ? (
        <>
          <ReviewQuoteSelectionSection
            proposal={proposalData}
            selectedQuote={selectedQuote}
            quotes={quotes}
            selectDifferentQuote={() => handleSelectDifferentQuote()}
            applicationId={id}
          />
        </>
      ) : (
        <ChooseQuoteSection
          proposal={proposalData}
          quotes={quotes}
          handleSelectQuote={(quote) => handleSelectQuote(quote)}
          applicationId={id}
          declinations={declinations}
          isLoadingDeclinations={isLoadingDeclinations}
        />
      )}
    </div>
  );
};
