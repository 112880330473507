import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import { useToasters } from 'hooks/useToasters';
import { useMarkApplicationRequestForReport } from 'queries/useApplications';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  applicationId: string;
  className?: string;
  isRequestedForReport?: boolean;
  requestPermitted?: boolean;
}

export const RequestForReportButton: React.FC<Props> = ({
  applicationId,
  className,
  isRequestedForReport,
  requestPermitted,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useMarkApplicationRequestForReport(applicationId, {
    onSuccess: () => {
      showToaster(
        `Scheduled reports generation successfully. We will notify you via email once the reports are ready.`,
      );
      setIsModalOpen(false);
    },
    onError: (e) => {
      toasterErrorHandler(e);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const MODAL_TITLE = requestPermitted ? 'Confirm Request Report' : 'Unable to Request Report';
  const MODAL_TEXT = requestPermitted
    ? 'The report will be a snapshot of data currently visible under security tab. Do you want to proceed?'
    : 'Report can be requested only if atleast 1 agent is installed';

  return (
    <>
      <Tooltip title='Request Report'>
        <Button
          className={className}
          onClick={handleModalOpen}
          variant={isRequestedForReport ? 'text' : 'contained'}
          color='primary'
          disabled={isRequestedForReport}
          startIcon={<AssessmentRoundedIcon />}
        >
          {isRequestedForReport ? 'Report Requested' : 'Request Report'}
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            {MODAL_TITLE}
          </Typography>
          <Typography variant='body1'>{MODAL_TEXT}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>{requestPermitted ? 'Cancel' : 'Close'}</Button>
          {requestPermitted && (
            <Button
              color='primary'
              variant='contained'
              disabled={isLoading}
              onClick={() => mutate()}
              startIcon={<AssessmentRoundedIcon />}
            >
              {isLoading ? (isRequestedForReport ? 'Saving...' : 'Submitting...') : 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
