import { Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { ReactComponent as ConfirmIcon } from 'images/confirm.svg';
import React from 'react';
import { useHistory } from 'react-router';
import { createSecurityRoute, ROUTES } from 'telivy-constants';
import { isScanApplication, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SubmitApplicationButton } from 'views/client/SubmitApplicationButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: COLORS.GREY_1,
    height: 'calc(100vh - 160px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 156px)',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& h2': {
      margin: 0,
      ...TYPOGRAPHY.TITLE_2,

      [theme.breakpoints.down('sm')]: {
        ...TYPOGRAPHY.TITLE_3,
      },
    },

    '& p': {
      ...TYPOGRAPHY.REGULAR_REGULAR,
      color: COLORS.GREY_2,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        ...TYPOGRAPHY.SMALL_REGULAR,
      },
    },
  },

  icon: {
    marginBottom: theme.spacing(1.5),
  },

  submitButton: {
    minWidth: 120,

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      width: `calc(100% - ${theme.spacing(2)}px)`,
      borderRadius: `${theme.spacing(4.75)}px !important`,
      height: theme.spacing(5.75),
    },
  },
}));

const ABOUT_TO_SUBMIT_COPY = (isSecurityApplication?: boolean) => {
  if (isSecurityApplication) {
    return <p>Submit your assessment to see security scan.</p>;
  }
  return <p>Submit your application to start getting quotes.</p>;
};

const SUBMITTED_COPY = (isSecurityApplication?: boolean) => {
  if (isSecurityApplication) {
    return (
      <p>
        This assessment is now submitted for review.
        <br />
        Security assessment should be ready in no time.
      </p>
    );
  }

  return (
    <p>
      This application is now submitted for review.
      <br />
      Your agent will reach out to you soon with a consolidated cyber insurance proposal.
    </p>
  );
};

interface Props {
  isValid: boolean;
  application: ApplicationWithAgentDTO;
  redirectToTheForm: () => void;
}

export const SubmitSection = ({ application, isValid, redirectToTheForm }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const isSubmitted = ![ApplicationStatus.IN_PROGRESS, ApplicationStatus.NOT_STARTED].includes(
    application.applicationStatus,
  );

  return (
    <div className={classes.root}>
      {isSubmitted && <ConfirmIcon className={classes.icon} />}
      <h2>
        {isSubmitted ? (
          isSecurityApplication(application) ? (
            'Assessment Submitted'
          ) : (
            'Application Submitted'
          )
        ) : (
          <>
            Great Job! <br />
            <Hidden smDown>
              {isSecurityApplication(application) ? 'Your assessment is complete.' : 'Your application is complete.'}
            </Hidden>
            <Hidden mdUp>
              {isSecurityApplication(application) ? 'Assessment is complete.' : 'Application is complete.'}
            </Hidden>
          </>
        )}
      </h2>
      {isSubmitted
        ? SUBMITTED_COPY(isSecurityApplication(application))
        : ABOUT_TO_SUBMIT_COPY(isSecurityApplication(application))}
      {!isSubmitted && (
        <SubmitApplicationButton
          applicationId={application.id}
          isValid={isValid}
          redirectToTheForm={redirectToTheForm}
          isSecurityApplication={isSecurityApplication(application) && !isScanApplication(application)}
        />
      )}
      {isSubmitted && isSecurityApplication(application) && (
        <Button
          className={classes.submitButton}
          color='primary'
          variant='contained'
          data-cy='form-submit-button'
          onClick={() =>
            isValid && !isScanApplication(application)
              ? history.push(createSecurityRoute(ROUTES.application.security.ROOT, application.id, 'latest'))
              : redirectToTheForm()
          }
        >
          Submit
        </Button>
      )}
    </div>
  );
};
