import { Button, makeStyles } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import cx from 'classnames';
import { Badge } from 'components/Badge';
import { LoadingContainer } from 'components/LoadingContainer';
import { SecurityScanDTO } from 'dtos/application';
import { SecurityFindingBySlugDTO } from 'dtos/security-finding';
import startCase from 'lodash/startCase';
import { useSecurityFindings, useSecurityFindingsBySlug, useSecurityGrades } from 'queries/useSecurityFinding';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { getGradeBadgeVariant } from '../components/ExternalScanItem';
import { FindingsTable } from '../components/FindingsTable';
import { ResolveFindingModal, Type } from '../components/ResolveFindingModal';
import { SecurityFindingsTable } from '../components/SecurityFindingsTable';
import { getSeverityBadgeVariant } from '../helpers';
import { SourceRoute } from '../index';
import { SCAN_TYPE_NAME } from './SecurityExternalScan';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(5),
  },
  backButton: {
    color: COLORS.GREY_2,
    border: 'none',
  },

  heading: {
    borderBottom: `1px solid ${COLORS.GREY_5}`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
    padding: `${theme.spacing(2.5)}px 0`,
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    margin: 0,

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.LARGE,
    },
  },

  content: {
    marginTop: theme.spacing(5),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: theme.spacing(10),
    rowGap: theme.spacing(4),

    '& a': {
      wordWrap: 'anywhere',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      gridTemplateColumns: '1fr',
      rowGap: theme.spacing(2),
    },
  },

  section: {
    marginTop: theme.spacing(5),
  },
  sectionBorderTop: {
    borderTop: `1px solid ${COLORS.GREY_5}`,
    paddingTop: theme.spacing(1),
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(2),
    },
  },

  sectionTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    flexWrap: 'wrap',
  },
  sectionContent: {
    ...TYPOGRAPHY.SMALL_REGULAR,
  },

  findingsTitleWrapper: {
    display: 'flex',
    gap: theme.spacing(),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  findingsTitle: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
  },
  findingsTitleButtons: {
    display: 'flex',
    gap: theme.spacing(),
    marginLeft: theme.spacing(2),
  },

  resolveButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

interface ParamsType {
  id: string;
  slug: string;
}

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  sourceRoute: SourceRoute;
  securityScanId: string;
}

interface FindingAction {
  findingIds?: string[];
  type?: Type;
}

export const SecurityFinding = ({ securityScan, applicationId, securityScanId, sourceRoute }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const { slug } = useParams<ParamsType>();
  const [action, setAction] = useState<FindingAction | null>(null);
  const { data, isLoading } = useSecurityFindings({ scanId: securityScan.id, slug });
  const { data: grades } = useSecurityGrades({ scanId: securityScan.id });
  const { data: otherFindings, isLoading: loadingFindings } = useSecurityFindingsBySlug({
    scanId: securityScan.id, // TODO: Add type to be more efficient
  });

  if (loadingFindings || isLoading) {
    return <LoadingContainer />;
  }

  const handleClick = (finding: SecurityFindingBySlugDTO) => {
    if (sourceRoute) {
      history.push(createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug));
    }
  };

  const slugDetails = otherFindings?.find((finding) => finding.slug === slug);
  const name = slugDetails ? slugDetails?.name : startCase(slug);
  const severity = data ? data[0]?.severity : undefined;
  const type = data ? data[0]?.type : undefined;
  const grade = grades && type ? grades?.[type] : undefined;
  const moreFindings = otherFindings?.filter((f) => f.scanType === type && f.slug !== slug) || [];

  return (
    <div className={classes.root}>
      <Button
        onClick={() =>
          history?.location?.key
            ? history.goBack()
            : history.push(createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScanId))
        }
        startIcon={<ArrowBackRoundedIcon />}
        className={classes.backButton}
      >
        Back
      </Button>
      <div className={classes.heading}>
        <h1 className={classes.title}>{name}</h1>
        {severity && (
          <Badge bordered variant={getSeverityBadgeVariant(severity)} style={{ minWidth: 80, width: 80 }}>
            {startCase(severity)}
          </Badge>
        )}
      </div>
      {slugDetails && (
        <div className={classes.content}>
          <div className={classes.column}>
            {slugDetails.description && (
              <div>
                <h3 className={classes.sectionTitle}>Details</h3>
                <div
                  className={classes.sectionContent}
                  dangerouslySetInnerHTML={{ __html: slugDetails.description }}
                ></div>
              </div>
            )}
          </div>

          <div className={classes.column}>
            {slugDetails.risk && slugDetails.risk.trim().length > 0 && (
              <div>
                <h3 className={classes.sectionTitle}>Risk</h3>
                <div className={classes.sectionContent} dangerouslySetInnerHTML={{ __html: slugDetails.risk }}></div>
              </div>
            )}
            {slugDetails.recommendation && (
              <div>
                <h3 className={classes.sectionTitle}>Recommendation</h3>
                <div
                  className={classes.sectionContent}
                  dangerouslySetInnerHTML={{ __html: slugDetails.recommendation }}
                ></div>
              </div>
            )}
            {slugDetails.references && slugDetails.references.length > 0 && (
              <div>
                <h3 className={classes.sectionTitle}>References</h3>
                {slugDetails.references.map((v, index) => (
                  <div key={index} className={classes.sectionContent}>
                    <a href={v} target='_blank' rel='noreferrer'>
                      {v}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className={cx(classes.section, classes.sectionBorderTop)}>
        <div>
          <div className={classes.findingsTitleWrapper}>
            <h3 className={classes.findingsTitle}>
              There are <b>{data?.length || 0} findings</b> in this Issue
            </h3>
            <div className={classes.findingsTitleButtons}>
              <Button
                variant='contained'
                color='primary'
                disabled={!action || action?.findingIds?.length === 0}
                className={classes.resolveButton}
                onClick={() => setAction({ findingIds: action?.findingIds, type: 'accept' })}
              >
                Accept
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={!action || action?.findingIds?.length === 0}
                onClick={() => setAction({ findingIds: action?.findingIds, type: 'resolve' })}
              >
                Resolve
              </Button>
            </div>
          </div>
          <div className={classes.sectionContent}>
            <SecurityFindingsTable
              data={data || []}
              isLoading={isLoading}
              onSelect={(ids) => setAction({ ...action, findingIds: ids })}
              selectedIds={action?.findingIds || []}
            />

            {action && action.type && (
              <ResolveFindingModal
                type={action.type}
                finding={data?.filter((f) => action.findingIds?.includes(f.id)) || undefined}
                onClose={() => setAction(null)}
              />
            )}
          </div>
        </div>
      </div>

      <div className={classes.section}>
        {moreFindings.length > 0 && type && (
          <div>
            <h3 className={classes.sectionTitle}>
              More findings in {SCAN_TYPE_NAME[type]} {/* TODO */}
              <Badge typography={TYPOGRAPHY.REGULAR_BOLD} variant={getGradeBadgeVariant(grade)}>
                {grade || '-'}
              </Badge>
            </h3>
            <div className={classes.sectionContent}>
              <FindingsTable onRowClick={handleClick} data={moreFindings} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
