import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { ApplicationDocumentType, ApplicationWithAgentDTO } from 'dtos/application';
import { ToasterVariant, useToasters } from 'hooks/useToasters';
import {
  useCreateApplicationDocument,
  useGenerateApplicationDocument,
  useUploadApplicationDocument,
} from 'queries/useApplications';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { GenerateReportsButton } from 'templates/SecurityAssessments/components/GenerateReports';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(),
    border: `1px solid ${COLORS.GREY_5}`,
    display: 'flex',
    marginBottom: theme.spacing(3),
  },

  // Content
  content: {
    ...TYPOGRAPHY.REGULAR_BOLD,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  select: {
    ...TYPOGRAPHY.SMALL_BOLD,
    padding: theme.spacing(0, 2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: `1px solid ${COLORS.GREY_5}`,
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  reports?: boolean;
}

const VALID_TYPES = (reports?: boolean) => {
  return Object.values(ApplicationDocumentType).filter((type) => {
    // Deprecated types
    if (type === ApplicationDocumentType.AUGUMENT_REPORT || type === ApplicationDocumentType.TELIVY_COMPLETE_REPORT) {
      return false;
    }

    if (reports) {
      const notValidTypes = [
        ApplicationDocumentType.BENCHMARK,
        ApplicationDocumentType.RISK_CALCULATOR,
        ApplicationDocumentType.SECURITY_REPORT,
        ApplicationDocumentType.EMPLOYEE_LIST,
      ];

      return !notValidTypes.includes(type);
    }

    return true;
  });
};

export const UploadDocument = ({ application, reports }: Props) => {
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const fileInput = useRef<null | HTMLInputElement>(null);
  const [documentType, setDocumentType] = useState<ApplicationDocumentType>(VALID_TYPES(reports)[0]);
  const [file, setFile] = useState<File | null>(null);
  const { mutate: createDocument, isLoading: isCreating } = useCreateApplicationDocument(application.id);
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadApplicationDocument(application.id);
  const { mutateAsync: generateFile, isLoading: isGenerating } = useGenerateApplicationDocument(application.id);

  const generatePossible = useMemo(() => {
    const allowed = [
      ApplicationDocumentType.TELIVY_COMPLETE_REPORT_PDF,
      ApplicationDocumentType.TELIVY_COMPLETE_REPORT_DOCX,
      ApplicationDocumentType.AUGMENTT_OVERVIEW_REPORT,
      ApplicationDocumentType.AUGUMENT_DETAILED_REPORT,
      ApplicationDocumentType.NODEWARE_TOP_VULNERABILITIES_REPORT,
      ApplicationDocumentType.TELIVY_VULNERABILITIES_REPORT,
      ApplicationDocumentType.NODEWARE_EXECUTIVE_REPORT,
      ApplicationDocumentType.TELIVY_EXTERNAL_SCAN_REPORT,
      ApplicationDocumentType.TELIVY_PASSWORDS_REPORT,
      ApplicationDocumentType.TELIVY_PASSWORDS_REUSE_REPORT,
      ApplicationDocumentType.TELIVY_DARK_WEB_REPORT,
      ApplicationDocumentType.TELIVY_NETWORK_INVENTORY_REPORT,
      ApplicationDocumentType.ACTIFILE_SENSITIVE_DOCUMENTS,
      ApplicationDocumentType.ACTIFILE_DETAILED_RISK,
      ApplicationDocumentType.M365_SECURITY_REPORT,
      ApplicationDocumentType.M365_SECURITY_DATA_REPORT,
      ApplicationDocumentType.NODEWARE_NETWORK_REPORT,
      ApplicationDocumentType.PII_EXCEL_REPORT_BUNDLE,
    ];

    return allowed.indexOf(documentType) > -1;
  }, [documentType]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const list = e.target.files;

      if (list) {
        setFile(list[0]);
      }
    },
    [setFile],
  );

  const handleUploadClick = useCallback(async () => {
    if (file) {
      try {
        if (
          documentType === ApplicationDocumentType.SECURITY_REPORT &&
          !['image/jpeg', 'image/png'].includes(file['type'])
        ) {
          return showToaster({ message: 'Security Report must be a JPEG or PNG', variant: ToasterVariant.ERROR });
        }
        const data = await uploadFile({
          type: documentType,
          file: file,
        });

        await createDocument({
          documentPath: data.path,
          fileName: file.name,
          documentType: documentType,
        });

        showToaster(`Successfully uploaded a ${DOCUMENT_TYPE_HUMANIZED_NAME[documentType]}`);
        setFile(null);
        setDocumentType(VALID_TYPES(reports)[0]);

        if (fileInput.current) {
          fileInput.current.value = '';
        }
      } catch (e) {
        toasterErrorHandler(e);
      }
    }
  }, [file, documentType, uploadFile, createDocument, showToaster, reports, toasterErrorHandler]);

  const handleGenerateClick = useCallback(async () => {
    try {
      await generateFile({
        documentType: documentType,
      });

      showToaster(`Successfully generated ${DOCUMENT_TYPE_HUMANIZED_NAME[documentType]}`);
      setFile(null);
      setDocumentType(VALID_TYPES(reports)[0]);

      if (fileInput.current) {
        fileInput.current.value = '';
      }
    } catch (e) {
      toasterErrorHandler(e);
    }
  }, [documentType, generateFile, showToaster, reports, toasterErrorHandler]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.row}>
          <GenerateReportsButton application={application} />
        </div>

        <div className={classes.row}>
          <Select
            className={classes.select}
            value={documentType}
            onChange={(el) => {
              setDocumentType(el.target.value as ApplicationDocumentType);
            }}
          >
            {VALID_TYPES(reports).map((type) => (
              <MenuItem key={type} value={type}>
                {DOCUMENT_TYPE_HUMANIZED_NAME[type]}
              </MenuItem>
            ))}
          </Select>

          <Button disabled={isGenerating || !generatePossible} className={classes.button} onClick={handleGenerateClick}>
            {isGenerating ? 'Generating...' : 'Generate'}
          </Button>

          <input style={{ display: 'none' }} type='file' ref={fileInput} onChange={handleInputChange} />

          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              if (fileInput.current) {
                fileInput.current.click();
              }
            }}
          >
            {file ? `Selected ${file.name}` : 'Select Document'}
          </Button>

          <Button disabled={!file} className={classes.button} onClick={handleUploadClick}>
            {isCreating || isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
      </div>
    </div>
  );
};
