import { makeStyles } from '@material-ui/core/styles';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';
import { Helmet } from 'react-helmet';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { RequestPenTestButton } from './RequestPenTestButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
}

export const ApplicationServicesView = ({ application }: Props) => {
  const classes = useStyles();

  return (
    <ApplicationViewContainer>
      <div className={classes.root}>
        <Helmet>
          <title>Services - {application?.applicationResponse?.organization_name}</title>
        </Helmet>

        <div className={classes.section}>
          <div className={classes.title}>Services</div>

          <div className={classes.headerRow}>
            <div className={classes.boxBorderPadding}>
              <div className={classes.headerRow}>
                <img src='/hds.webp' alt='HDS' />
                <div>
                  <div className={classes.title}>Telivy + HDS PenTesting</div>
                  <div>
                    HDS, in partnership with Telivy, enhances Pen Testing with Telivy&apos;s scanning expertise and
                    HDS&apos;s use of Kali Linux tools and ethical hacking.
                    <br />
                    <br />
                  </div>
                  <RequestPenTestButton
                    applicationId={application.id}
                    pentestRequested={false /*application.pentestRequested*/}
                  />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </ApplicationViewContainer>
  );
};
