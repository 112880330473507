import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS } from 'telivy-theme';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: COLORS.WHITE,
    border: `1px solid ${COLORS.WHITE}`,
    borderRadius: theme.spacing(3),
    padding: 0,

    [theme.breakpoints.up('md')]: {
      boxShadow: '0px 2px 4px rgba(35, 40, 48, 0.16), 0px 0px 1px rgba(35, 40, 48, 0.5)',
    },
  },
}));

interface Props {
  className?: string;
}

export const Card: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={cx(classes.root, className)}>{children}</div>;
};
