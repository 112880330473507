import { LinearProgress, makeStyles, Typography, withStyles } from '@material-ui/core';
import { AgentLogo } from 'components/AgentLogo';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { ApplicationApiQuotingStatus, ApplicationStatus } from 'dtos/application';
import { useApplication } from 'queries/useApplications';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { generateRefetchIntervalMs, insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: theme.spacing(3.75),
    borderRadius: theme.spacing(3.75),
    width: '100%',
    backgroundColor: COLORS.GREY_6,
    // https://github.com/mui/material-ui/issues/23204
    zIndex: 0,

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3),
    },
  },
  bar2Buffer: {
    borderRadius: theme.spacing(2.5),
    backgroundColor: COLORS.GREEN_2,
  },
  bar1Buffer: {
    borderRadius: theme.spacing(2.5),
    backgroundColor: COLORS.GREEN_1,
  },

  dashedColorPrimary: {
    background: 'none',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {},

  nav: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(6),
    margin: 0,
    padding: `0 ${theme.spacing(7.5)}px`,
    height: 60,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 620,
    margin: '0 auto',
    paddingTop: theme.spacing(19.625),

    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },

  heading: {
    ...TYPOGRAPHY.TITLE_2,
    textAlign: 'center',
    letterSpacing: '-0.03em',

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.LARGE,
    },
  },

  description: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    textAlign: 'center',
    letterSpacing: '-0.03em',
    color: COLORS.GREY_2,
    margin: `${theme.spacing(3)}px 0`,

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.SMALL_REGULAR,
    },
  },
}));

interface ParamsType {
  id: string;
}

const REFETCH_INTERVAL = 1000 * 10; // 10sec;
const REFETCH_NUMBER = 9; // 90 seconds

export const QuotingView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<ParamsType>();

  const [refetchNumber, setRefetchNumber] = useState(0);
  const [progress, setProgress] = useState(2.5);
  const [buffer, setBuffer] = useState(5);

  const {
    data: applicationData,
    isLoading,
    isError,
  } = useApplication(id, {
    refetchInterval: (data) =>
      data?.apiQuotingStatus !== ApplicationApiQuotingStatus.FETCHED && refetchNumber < REFETCH_NUMBER
        ? generateRefetchIntervalMs()
        : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // redirect to the form if the application is not >= submitted
      [ApplicationStatus.NOT_STARTED, ApplicationStatus.IN_PROGRESS, ApplicationStatus.IN_REVIEW].includes(
        data.applicationStatus,
      ) && history.push(insertIdToRoute(ROUTES.application.FORM, id));

      // redirect to the proposal if the apiQuotingStatus is fetched
      data.apiQuotingStatus === ApplicationApiQuotingStatus.FETCHED &&
        setTimeout(
          () => history.push(insertIdToRoute(ROUTES.application.PROPOSAL, data.id).replace(':proposalId', '')),
          1500,
        );
    },
  });

  const isDoneQuoting = applicationData?.apiQuotingStatus === ApplicationApiQuotingStatus.FETCHED;

  useEffect(() => {
    const refetchCounter = setInterval(() => {
      setRefetchNumber((prev) => prev + 1);
    }, REFETCH_INTERVAL);

    refetchNumber >= REFETCH_NUMBER && clearInterval(refetchCounter);

    return () => {
      clearInterval(refetchCounter);
    };
  }, [applicationData, isDoneQuoting, refetchNumber]);

  useEffect(() => {
    const progressTimer = setInterval(() => {
      const diff = REFETCH_NUMBER / (Math.random() * 20 + 10);
      const diffBuffer = diff + REFETCH_NUMBER / (Math.random() * 30 + 5);

      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + diff : 100));
      setBuffer((prevBuffer) => (prevBuffer < 100 ? prevBuffer + diffBuffer : 100));
    }, 500);

    return () => {
      clearInterval(progressTimer);
    };
  }, []);

  if (isLoading) {
    return <LoadingContainer fullScreen />;
  }

  if (isError || !applicationData || (!isDoneQuoting && refetchNumber >= REFETCH_NUMBER)) {
    return <NotFoundContainer fullScreen />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Quoting</title>
      </Helmet>

      <nav className={classes.nav}>
        <AgentLogo applicationId={id} logoSize={30} />
      </nav>

      <div className={classes.wrapper}>
        <Typography variant={'h1'} className={classes.heading}>
          Finding you some awesome quotes...
        </Typography>
        <p className={classes.description}>Sit tight, this may take a minute.</p>
        <CustomLinearProgress
          variant='buffer'
          value={isDoneQuoting ? 100 : progress}
          valueBuffer={isDoneQuoting ? 100 : buffer}
        />
      </div>
    </div>
  );
};
