import { SecurityGrade, SecurityScanDTO } from './application';

export enum SecurityScanType {
  SOCIAL_ENGINEERING = 'socialEngineering',
  HACKER_CHATTER = 'breaches',
  NETWORK_SECURITY = 'networkScan',
  ENDPOINT_SECURITY = 'endpointSecurity',
  APPLICATION_SECURITY = 'applicationSecurity',
  DNS_HEALTH = 'dnsHealth',
  IP_REPUTATION = 'ipReputation',
  PATCHING_CADENCE = 'patchingCadence',
  INSURABILITY = 'insurability',
}

export enum InternalScanType {
  NETWORK_SECURITY = 'NETWORK_SECURITY',
  DATA_SECURITY = 'DATA_SECURITY',
  IDENTITY_ACCESS_MANAGEMENT = 'IDENTITY_ACCESS_MANAGEMENT',
  DARK_WEB_PRESENCE = 'DARK_WEB_PRESENCE',
  M365_SECURITY = 'M365_SECURITY',
  APPLICATION_SECURITY = 'APPLICATION_SECURITY',
}

export enum SecurityFindingStatus {
  OPEN = 'open',
  IN_REVIEW = 'in_review',
  RESOLVED = 'resolved',
  ACCEPTED = 'accepted',
}

export enum SecuritySeverity {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  INFO = 'info',
}

export enum SecurityFindingSlug {
  // social-engineering
  NO_EMPLOYEE_TRAINING = 'no-employee-training',
  PERSONAL_EMAILS_FOUND = 'personal-emails-found',
  WORK_EMAILS_FOUND = 'work-emails-found',
  TYPO_SQUATTING = 'typos-squatting',

  // hacker-chatter

  // patching-candidates
  HIGH_SEVERITY_CVEs_PATCHING_CADENCE = 'high-severity-cves-patching-cadence',
  MEDIUM_SEVERITY_CVEs_PATCHING_CADENCE = 'medium-severity-cves-patching-cadence',
  LOW_SEVERITY_CVEs_PATCHING_CADENCE = 'low-severity-cves-patching-cadence',
  DETECTED_SERVICES = 'detected-services',

  // network-security
  LOW_SEVERITY_PORT_DETECTED = 'low-severity-port-detected',
  MEDIUM_SEVERITY_PORT_DETECTED = 'medium-severity-port-detected',
  HIGH_SEVERITY_PORT_DETECTED = 'high-severity-port-detected',
  CERT_NO_ISSUER = 'cert-no-issuer',
  CERT_NOT_FOUND = 'cert-not-found',
  CERT_REVOKED = 'cert-revoked',
  EMAIL_NO_SSL_TLS = 'email-no-ssl-tls',
  HTTP_NO_SSL_TLS = 'http-no-ssl-tls',
  CERT_EXPIRED = 'cert-expired',
  CERT_ABOUT_TO_EXPIRE = 'cert-about-to-expire',
  CERT_SELF_SIGNED = 'cert-self-signed',
  CERT_WEAK_SIGN_ALGO = 'cert-weak-sign-algo',
  CERT_HIGH_LIFE = 'cert-high-life',

  // endpoint-security
  OUTDATED_BROWSER_DETECTED = 'outdated-browser-detected',
  OUTDATED_OS_DETECTED = 'outdated-os-detected',

  // application-security
  HIGH_SEVERITY_CMS_ISSUES = 'high-severity-cms-issues',
  HTTPS_NOT_ENFORCED = 'https-not-enforced',
  CSP_MISSING = 'csp-missing',
  INSECURE_HTTPS_REDIRECT_PATTERN = 'insecure-https-redirect-pattern',
  MEDIUM_SEVERITY_CMS_ISSUES = 'medium-severity-cms-issues',
  HTTP_IN_REDIRECT_CHAIN = 'http-in-redirect-chain',
  HSTS_NOT_IMPLEMENTED = 'hsts-not-implemented',
  CSP_BROAD_DIRECTIVES = 'csp-broad-directives',
  COOKIE_MISSING_HTTPONLY = 'cookie-missing-httponly',
  COOKIE_MISSING_SECURE = 'cookie-missing-secure',
  CONTENT_TYPE_OPTIONS_NOT_IMPLEMENTED = 'content-type-options-not-implemented',
  FRAME_OPTIONS_NOT_IMPLEMENTED = 'frame-options-not-implemented',
  LOW_SEVERITY_CMS_ISSUES = 'medium-severity-cms-issues',
  SERVER_VERSION_MASKING_NOT_IMPLEMENTED = 'server-version-masking-not-implemented',
  GIT_CONFIG_DETECTED = 'git-config-detected',
  OWA_SERVICE_DETECTED = 'owa-service-detected',
  CHECK_LINKS_IN_SOURCE_CODE = 'check-links-in-source-code',

  // dns-health
  OPENDNS_RESOLVER_PROBLEM = 'opendns-resolver-problem',
  SPF_RECORD_MISSING = 'spf-record-missing',
  SPF_CRITICAL_PROBLEMS = 'spf-critical-problems',
  DMARC_CRITICAL_PROBLEMS = 'dmarc-critical-problems',
  DMARC_IMPROPER_CONFIG = 'dmarc-improper-config',
  SPF_IMPROPER_CONFIG = 'spf-improper-config',

  // ip-reputation-scan
  MALWARE_INFECTION = 'malware-infection',
  SOCIAL_ENGINEERING_INFECTION = 'social-engineering-infection',
  UNWANTED_SOFTWARE_INFECTION = 'unwanted-software-infection',
  SPAM_DOMAIN = 'spam-domain',
  EXPLOITS_DOMAIN = 'exploits-domain',
  PHISHING_DOMAIN = 'phishing-domain',
  MALWARE_DOMAIN = 'malware-domain',
  BTONET_CNC_DOMAIN = 'botnet-cnc-domain',
  ABUSED_LEGIT_SPAM_DOMAIN = 'abused-legit-spam-domain',
  ABUSED_SPAMMED_REDIRECTOR_DOMAIN = 'abused-spammed-redirector-domain',
  ABUSED_LEGIT_PHISHING_DOMAIN = 'abused-legit-phishing-domain',
  ABUSED_LEGIT_MALWARE_DOMAIN = 'abused-legit-malware-domain',
  ABUSED_LEGIT_BOTNET_CNC_DOMAIN = 'abused-legit-botnet-cnc-domain',
  SMTP_UNUSUAL_PORT = 'smtp-unusual-port',
}

export interface SecurityFindingDTO {
  id: string;
  scan: SecurityScanDTO; // this will always link to a root domain scan
  scanId: string;
  comment?: string | null;
  rootDomain: string; // eg. google.com
  domain: string; // eg. mail.google.com
  slug: SecurityFindingSlug | string; // please update the enum if possible
  type: SecurityScanType;
  severity: SecuritySeverity;
  value: string; // The value of the finding
  formattedValue: string | null; // If present, this will be used for the UI
  devLogs?: string | null;
  status: SecurityFindingStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface SecurityFindingsDetailsDTO {
  name: string;
  description?: string;
  risk?: string;
  recommendation?: string;
  references?: string[];
  scanType: SecurityScanType;
  severity: SecuritySeverity;
}

export interface SecurityFindingBySlugDTO extends SecurityFindingsDetailsDTO {
  slug: SecurityFindingSlug;
  count: number;
}

export interface SecurityFindingByDomainDTO {
  count: number;
  domain: string;
}

export interface SecurityFindingsQueryDTO {
  scanId: string;
  type?: SecurityScanType;
  slug?: string;
  domains?: string[];
  offset?: number;
  limit?: number;
}

export interface SecurityFindingsBySlugQueryDTO {
  scanId: string;
  domains?: string[];
}

export interface SecurityFindingsByDomainQueryDTO {
  scanId: string;
}

export interface SecurityGradesQueryDTO {
  scanId: string;
}

export type SecurityGradesDTO = {
  [key in SecurityScanType]: SecurityGrade;
};

export interface SecurityFindingsByTypeQueryDTO {
  scanId: string;
}

export interface UpdateSecurityFindingDTO {
  comment?: string;
  status: SecurityFindingStatus;
}
