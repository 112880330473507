import { Button } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { useToasters } from 'hooks/useToasters';
import React, { useCallback } from 'react';

interface Props {
  applicationResponse: any;
  isSecurityApplication: boolean;
  className?: string;
}

export const CopyApplicationResponseButton: React.FC<Props> = ({
  applicationResponse,
  isSecurityApplication,
  className,
}) => {
  const { showToaster } = useToasters();

  const handleCopyResponse = useCallback(() => {
    const formattedResponse = JSON.stringify(applicationResponse, undefined, 2);
    navigator.clipboard.writeText(formattedResponse);
    showToaster(`${isSecurityApplication ? 'Assessment' : 'Application'} Response is copied to clipboard`);
  }, [applicationResponse, isSecurityApplication, showToaster]);

  return (
    <Button className={className} onClick={handleCopyResponse}>
      <FileCopyRoundedIcon />
    </Button>
  );
};
