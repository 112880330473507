import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { CarrierDTO } from 'dtos/carrier';
import React from 'react';

const useStyles = makeStyles<Theme>({
  root: {},
});

interface Props {
  carrier?: CarrierDTO;
  className?: string;
}

export const CarrierLogo = ({ carrier, className }: Props) => {
  const classes = useStyles();

  if (carrier && carrier.logoUrl) {
    return <img src={carrier.logoUrl} alt='Carrier logo' className={cx(classes.root, className)} />;
  }

  return <>{carrier?.displayName}</>;
};
