import { ChartRecord, HistoryGrades } from 'components/charts/HistoryGrades';
import { MonitoringSnapshotDTO, SecurityGrade } from 'dtos/application';
import { InternalScanType as SecurityScanType } from 'dtos/security-finding';
// import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';
import { parseScoreToGrade } from 'templates/SecurityAssessments';

export const getChartValueForGrade = (grade: SecurityGrade) => {
  switch (grade) {
    case SecurityGrade.A:
      return 100;
    case SecurityGrade.B:
      return 75;
    case SecurityGrade.C:
      return 50;
    case SecurityGrade.D:
      return 25;
    default:
      return 0;
  }
};

export const useMonitoringInternalGradesHistoryChart = (
  enableCharts: boolean,
  data?: MonitoringSnapshotDTO[],
  classname?: string,
) => {
  const gradesHistory: ChartRecord[] = useMemo(() => {
    if (!data) return [];

    return data
      ?.map((row) => {
        return {
          date: row.isLive ? 'Scan In Progress' : moment(row.createdAt).format('Do MMMM YYYY'),
          // securityOverallScore: securityScan?.securityOverallScore,
          [SecurityScanType.NETWORK_SECURITY]: getChartValueForGrade(
            parseScoreToGrade((row.reportData.networkSecurity?.score || 0) * 100),
          ),
          [SecurityScanType.DATA_SECURITY]: getChartValueForGrade(
            parseScoreToGrade((row.reportData.dataSecurity?.score || 0) * 100),
          ),
          [SecurityScanType.IDENTITY_ACCESS_MANAGEMENT]: getChartValueForGrade(
            parseScoreToGrade((row.reportData.identityAccessManagement?.score || 0) * 100),
          ),
          [SecurityScanType.DARK_WEB_PRESENCE]: getChartValueForGrade(
            parseScoreToGrade((row.reportData.securityAwarenessTraining?.score || 0) * 100),
          ),
          [SecurityScanType.M365_SECURITY]: getChartValueForGrade(
            parseScoreToGrade(row.reportData.m365Security?.score || 0),
          ),
          [SecurityScanType.APPLICATION_SECURITY]: getChartValueForGrade(
            parseScoreToGrade((row.reportData.applicationSecurity?.score || 0) * 100),
          ),
        };
      })
      .slice(0, 365);
  }, [data]);

  const chart = useMemo(() => {
    return (
      <HistoryGrades
        locked={!enableCharts}
        id='monitoring-risk-grades-history'
        className={classname}
        data={gradesHistory}
        // title='Critical Vulnerabilities'
      />
    );
  }, [classname, enableCharts, gradesHistory]);

  return {
    chart,
    gradesHistory,
  };
};
