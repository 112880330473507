import { makeStyles } from '@material-ui/core';
import { EmptyState } from 'components/EmptyState';
import { Money } from 'components/Money';
import { CarrierColumn } from 'components/QuotesTable/CarrierColumn';
import { Column, Table } from 'components/Table';
import { DeclinationDTO } from 'dtos/declinations';
import { ReactComponent as QuoteIcon } from 'images/quote.svg';
import React from 'react';
import { COLORS } from 'telivy-theme';

const useStyles = makeStyles(() => ({
  table: {
    '& thead tr td:last-of-type': {
      color: COLORS.RED_1,
    },
    '& tbody tr td:last-of-type': {
      color: COLORS.RED_1,
    },
  },
}));

interface Props {
  declinations?: DeclinationDTO[];
  isLoadingDeclinations?: boolean;
}

const columns: Array<Column<DeclinationDTO, keyof DeclinationDTO>> = [
  {
    title: 'Carrier',
    width: '25%',
    render: (item) => <CarrierColumn carrier={item.carrier} />,
  },
  {
    title: 'Limit',
    width: '25%',
    render: (item) => <Money value={item.requestedAggregateLimit} />,
  },
  {
    title: 'Retention',
    width: '25%',
    render: (item) => <Money value={item.requestedRetention} />,
  },
  {
    title: 'Reason',
    width: '25%',
    render: (item) => item.reason,
  },
];

export const RejectedQuotes = ({ declinations, isLoadingDeclinations }: Props) => {
  const classes = useStyles();

  if (!isLoadingDeclinations && declinations && declinations.length === 0) {
    return <EmptyState title='No Declined Quotes' icon={<QuoteIcon />} />;
  }

  return (
    <Table<DeclinationDTO>
      hideHeadersWhenEmpty
      columns={columns}
      loading={isLoadingDeclinations}
      data={declinations}
      rowKey={(el) => el.id}
      className={classes.table}
      bordered
    />
  );
};
