import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { DeepScanDTO, PII_TYPE_LABELS, PiiCountries, PiiCurrency, PiiTypes } from 'dtos/deep-scan';
import { useUpdateDeepScan } from 'queries/useDeepScan';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    // position: 'absolute',
    marginRight: theme.spacing(1),
    // top: theme.spacing(3),
    // color: theme.palette.grey[500],
  },
}));

interface Props {
  deepScan: DeepScanDTO;
  applicationId?: string;
  className?: string;
}

interface Form {
  country: keyof typeof PiiCountries;
  currency: string;
  costs: PiiTypes;
}

export const ConfigurePiiCostsModal = ({ deepScan, applicationId, className }: Props) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isLoading } = useUpdateDeepScan(deepScan.id, applicationId, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const { handleSubmit, control, watch, setValue } = useForm<Form>({
    defaultValues: {
      country: deepScan.piiConfig.country || 'US',
      currency: deepScan.piiConfig.currency,
      costs: deepScan.piiConfig.costs,
    },
  });

  const country = watch('country');

  const onSubmit = (data: Form) => {
    mutate({
      piiConfig: {
        country: data.country,
        currency: data.currency,
        costs: data.costs,
      },
    });
  };

  const FORM_ID = 'edit_deep_scan_pii_config';

  return (
    <>
      <Button className={className} onClick={handleClick} variant='text' startIcon={<SettingsRoundedIcon />}>
        Configure PII Costs
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Configure PII Costs</DialogTitle>
        <DialogContent key='content'>
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              <Controller
                name='country'
                control={control}
                defaultValue='US'
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id='select-pii-country'>
                      Country * (Rescan All required to effect updating country)
                    </InputLabel>
                    <Select
                      {...field}
                      labelId='select-pii-country'
                      label='Country'
                      value={field.value}
                      required
                      onChange={(el) => {
                        setValue('country', el.target.value as string);
                        setValue('currency', PiiCurrency[el.target.value as keyof typeof PiiCurrency]);
                      }}
                    >
                      {Object.keys(PiiCountries).map((code) => (
                        <MenuItem key={code} value={code}>
                          {PiiCountries[code]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                name='currency'
                control={control}
                defaultValue=''
                render={({ field }) => <CustomTextFieldShrink {...field} label='Currency' required />}
              />

              {Object.keys(PII_TYPE_LABELS[country]).map((k) => (
                <Controller
                  name={`costs.${k}` as any}
                  key={k}
                  control={control}
                  defaultValue={deepScan.piiConfig.costs[k as keyof PiiTypes]}
                  render={({ field }) => (
                    <CustomTextFieldShrink {...field} label={PII_TYPE_LABELS[country][k]} required />
                  )}
                />
              ))}
            </section>
          </form>
        </DialogContent>
        <DialogActions key='actions'>
          <div>
            <Button color='primary' variant='outlined' onClick={onClose} className={classes.closeButton}>
              Cancel
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} form={FORM_ID}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
