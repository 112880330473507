import { Hidden } from '@material-ui/core';
import { Card } from 'components/Card';
import { PolicyDTO } from 'dtos/policy';
import { ReactComponent as CheckGreenIcon } from 'images/confirm.svg';
import {
  useDownloadCombinedSignedPolicyDocuments,
  useDownloadPolicyDocument,
  usePolicyDocumentsToSign,
} from 'queries/usePolicy';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';

import { QuoteSummary } from '../QuoteSummary';
import { useSectionStyles } from '../useSectionStyles';
import { DownloadDocumentsButton } from './DownloadDocumentsButton';

interface Props {
  policy: PolicyDTO;
}

export const ThankYouSection = ({ policy }: Props) => {
  const classes = useSectionStyles({ variant: 'thankyou' });
  const { data: documents } = usePolicyDocumentsToSign(policy.id);
  const { mutate: downloadPolicyDocument, isLoading: isDownloadingDocument } = useDownloadPolicyDocument(policy.id);
  const { mutate: downloadCombinedDocument, isLoading: isDownloadingCombined } =
    useDownloadCombinedSignedPolicyDocuments(policy.id);

  const handleDownloadPolicyDocument = useCallback(
    (document) => {
      downloadPolicyDocument(document);
    },
    [downloadPolicyDocument],
  );

  const handleDownloadSignedPolicyDocument = useCallback(
    (policy) => {
      downloadCombinedDocument(policy);
    },
    [downloadCombinedDocument],
  );

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Thank You</title>
      </Helmet>

      <section className={classes.section}>
        <div className={classes.heading} key='heading'>
          <h1 className={classes.title}>Thank You</h1>

          <p className={classes.description}>
            <Hidden smDown>The agent will reach out to you soon with next steps.</Hidden>
            <Hidden mdUp>The agent will reach out to you soon.</Hidden>
          </p>
        </div>

        <div className={classes.cardWrapper}>
          <div>
            <Hidden mdUp>
              <h3 className={classes.cardTitle}>Summary</h3>
            </Hidden>
            <Card className={classes.outlinedCard}>
              <div className={classes.cardInnerWrapper}>
                <Hidden smDown>
                  <h3 className={classes.cardTitle}>
                    <CheckGreenIcon className={classes.cardTitleIcon} />
                    Summary
                  </h3>
                </Hidden>
                <QuoteSummary policy={policy} variant='thankyou' />

                <div className={classes.viewWrapper}>
                  {policy?.signedDocumentsPath && (
                    <div className={classes.viewSection}>
                      <div className={classes.viewHeader}>
                        <p>Signed Documents</p>
                        <DownloadDocumentsButton
                          isDownloading={isDownloadingCombined}
                          onClick={() => handleDownloadSignedPolicyDocument(policy)}
                        />
                      </div>
                    </div>
                  )}

                  {documents && (
                    <div className={classes.viewSection}>
                      <div className={classes.viewHeader}>
                        <p>All Documents</p>
                        <DownloadDocumentsButton
                          isDownloading={isDownloadingDocument}
                          onClick={() => documents.forEach((document) => handleDownloadPolicyDocument(document))}
                        />
                      </div>
                      {documents.map((document) => (
                        <div className={classes.viewItem} key={document.id}>
                          <p className={classes.viewItemKey}>{DOCUMENT_TYPE_HUMANIZED_NAME[document.documentType]}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
};
