import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { useToasters } from 'hooks/useToasters';
import { useMarkApplicationArchivedBulk } from 'queries/useApplications';
import React, { useState } from 'react';
// import { useHistory } from 'react-router';
// import { insertIdToRoute, ROUTES } from 'telivy-constants';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  applicationIds: string[];
  className?: string;
  isArchived?: boolean;
  onBulkAction?: () => void;
}

export const BulkArchivedButton: React.FC<Props> = ({ applicationIds, className, isArchived, onBulkAction }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  // const history = useHistory();

  const { mutate, isLoading } = useMarkApplicationArchivedBulk(applicationIds, {
    onSuccess: () => {
      showToaster(`Successfully marked applications as archived`);
      setIsModalOpen(false);
      if (onBulkAction) onBulkAction();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const MODAL_TEXT = (() => {
    if (isArchived) {
      return 'Once unarchived, the assessments will be visible on your dashboard.';
    } else {
      return (
        <p>
          Once archived, the assessments will not be visible on your dashboard, but you can search for it in case you
          want to retrieve it.
          <br />
          <br />
          For Risk assessments, we will trigger an uninstall on all online devices, if not already uninstalled, once
          they are archived. You will have to manually run the uninstall command on offline devices.
        </p>
      );
    }
  })();

  const MODAL_TITLE = (() => {
    if (isArchived) {
      return 'Confirm unarchiving the assessments';
    } else {
      return 'Confirm archiving the assessments';
    }
  })();

  if (applicationIds.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        className={className}
        onClick={handleModalOpen}
        variant='outlined'
        color='secondary'
        startIcon={isArchived ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
      >
        {isArchived ? 'Unarchive' : 'Archive'}
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            {MODAL_TITLE}
          </Typography>
          <Typography variant='body1'>{MODAL_TEXT}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button
            variant='contained'
            color='secondary'
            disabled={isLoading}
            onClick={() => mutate()}
            startIcon={isArchived ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
          >
            {isLoading ? (isArchived ? 'Unarchiving...' : 'Archiving...') : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
