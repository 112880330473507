import { Button, Hidden, makeStyles } from '@material-ui/core';
import { BottomActionsNavigation } from 'components/BottomActionsNavigation';
import { Card } from 'components/Card';
import { Money } from 'components/Money';
import { Column, VerticalTable } from 'components/VerticalTable';
import { ApplicationStatus } from 'dtos/application';
import { ProposalWithApplicationDTO } from 'dtos/proposal';
import { QuoteDocumentType, QuoteDTO } from 'dtos/quotes';
import { useQuoteSublimits } from 'hooks/useQuoteSublimits';
import BackgroundImage from 'images/proposals-bg.svg';
import { useApplication, useClientApplicationActivePolicy } from 'queries/useApplications';
import { useDownloadQuoteDocument } from 'queries/useQuote';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { DOCUMENT_TYPE_HUMANIZED_NAME, QUOTE_INFO_MAP } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ProposalInfoModal } from 'views/web-proposal/ProposalInfoModal';

import { DownloadQuoteDocument } from '../ChooseQuoteSection/QuoteCard/DownloadQuoteDocument';
import { EnrollButton } from './EnrollButton';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(10),
      background: `url(${BackgroundImage}) no-repeat center ${theme.spacing(7.5)}px`,
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      minHeight: `calc(100vh - ${theme.spacing(7.5)}px)`,
      boxSizing: 'border-box',
    },

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(20),
      background: COLORS.WHITE,
    },
  },

  section: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },

  heading: {
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  description: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.GREY_2,
    margin: 0,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },

  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      gap: theme.spacing(2),
    },
  },

  card: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: theme.spacing(5),
    },
  },

  cardInnerWrapper: {
    padding: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  tableHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  premiumTable: {
    ...TYPOGRAPHY.MONO_TITLE3,

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.MONO_BOLD_REGULAR,
      color: COLORS.GREY_1,
    },
  },

  cardTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },

  summaryDescription: {
    ...TYPOGRAPHY.MICRO_MEDIUM,
    color: COLORS.GREY_2,
    margin: 0,
  },
  summaryPremium: {
    ...TYPOGRAPHY.TITLE_2,
    margin: `${theme.spacing()}px 0`,
  },
  summaryCarrier: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    margin: 0,
  },
  summaryButtonWrapper: {
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(1.5)}px 0`,
    },
  },
  summaryButton: {
    [theme.breakpoints.up('md')]: {
      width: '100%',

      '& + &': {
        marginTop: theme.spacing(1.5),
      },
    },

    [theme.breakpoints.down('sm')]: {
      '&:first-of-type': {
        ...TYPOGRAPHY.SMALL_MEDIUM,
      },
    },
  },

  link: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.BLUE_1,
  },
}));

interface Props {
  proposal?: ProposalWithApplicationDTO;
  selectedQuote: QuoteDTO;
  quotes?: QuoteDTO[];
  selectDifferentQuote: () => void;
  applicationId: string;
}

export const ReviewQuoteSelectionSection = ({
  selectedQuote,
  proposal,
  selectDifferentQuote,
  applicationId,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: applicationData } = useApplication(applicationId);
  const { lists: sublimitsList } = useQuoteSublimits(selectedQuote, {
    moneyTypography: TYPOGRAPHY.MONO_REGULAR_REGULAR,
  });
  useClientApplicationActivePolicy(applicationId, {
    enabled:
      applicationData &&
      [(ApplicationStatus.BIND_REQUESTED, ApplicationStatus.BOUND)].includes(applicationData?.applicationStatus),
    refetchInterval: false,
    retry: false,
    onSuccess: (policy) => {
      policy.attestationDone && history.push(insertIdToRoute(ROUTES.application.POLICY, applicationId));
    },
  });

  const { mutate: mutateDownloadDocument, isLoading: isDownloading } = useDownloadQuoteDocument(selectedQuote.id);

  const quoteDocument = selectedQuote.documents.find(
    (document) => document.documentType === QuoteDocumentType.QUOTE_LETTER,
  );

  const handleDownloadQuoteDocument = useCallback(
    (document) => {
      mutateDownloadDocument(document);
    },
    [mutateDownloadDocument],
  );

  const quoteSublimits: Array<Column<QuoteDTO>> = sublimitsList.map((sublimit) => ({
    title: sublimit.title,
    renderColumn: () => sublimit.label,
    render: () => sublimit?.value,
    divider: [
      'networkAndInformationSecurityLiability',
      'breachResponseCostsAndServices',
      'creditIdentityMonitoringServices',
      'businessInterruptionContingent3rdPartyComputerSystems',
      'fundsTransferFraudSystemsBreach',
      'hardwareReplacementCostsBricking',
    ].includes(sublimit.key)
      ? 'border'
      : undefined,
  }));

  const basicQuoteColumns: Array<Column<QuoteDTO>> = [
    {
      title: 'Annual Cost',
      render: (quote) => {
        return <Money value={quote?.annualPolicyCost || quote?.premium || 0} className={classes.premiumTable} />;
      },
    },
    {
      title: 'Carrier',
      render: (quote) => <>{quote?.carrier.displayName}</>,
    },
    {
      title: 'Rating',
      render: (quote) => <>{quote?.carrier.amBestRating}</>,
    },
    {
      title: 'Info',
      render: (quote) => <>{quote?.surplusLinesCost}</>,
    },
    {
      title: QUOTE_INFO_MAP['retention'].title,
      divider: 'border',
      renderColumn: () => <ProposalInfoModal name='retention' />,
      render: (quote) => <Money value={quote?.retention || 0} typography={TYPOGRAPHY.MONO_REGULAR_REGULAR} />,
    },
    {
      title: QUOTE_INFO_MAP['premium'].title,
      renderColumn: () => <ProposalInfoModal name='premium' />,
      render: (quote) => <Money value={quote?.premium || 0} typography={TYPOGRAPHY.MONO_REGULAR_REGULAR} />,
    },
    {
      title: QUOTE_INFO_MAP['aggLimit'].title,
      renderColumn: () => <ProposalInfoModal name='aggLimit' />,
      render: (quote) => <Money value={quote?.aggregateLimit || 0} typography={TYPOGRAPHY.MONO_REGULAR_REGULAR} />,
    },
  ];

  const columns: Array<Column<QuoteDTO>> = [
    ...basicQuoteColumns,
    ...quoteSublimits,
    {
      title: 'Documents',
      divider: 'border',
      render: (quote) => {
        return (
          <div style={{ paddingBottom: 24 }}>
            {quote?.documents.map((document) => (
              <DownloadQuoteDocument
                className={classes.link}
                key={document.id}
                quote={quote}
                document={document}
                label={DOCUMENT_TYPE_HUMANIZED_NAME[document.documentType]}
              />
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <section className={classes.section}>
        <div className={classes.heading} key='heading'>
          <h1 className={classes.title}>
            <Hidden smDown>Review Your Quote Selection</Hidden>
            <Hidden mdUp>Review Your Selection</Hidden>
          </h1>
          <p className={classes.description}>Enroll to proceed to the next step.</p>
        </div>
        <div className={classes.cardWrapper}>
          <Card>
            <div className={classes.cardInnerWrapper}>
              <Hidden smDown>
                <div className={classes.tableHeading}>
                  <h3 className={classes.cardTitle}>Selected Quote</h3>
                  <Button onClick={() => selectDifferentQuote()}>Select Different Quote</Button>
                </div>
              </Hidden>
              <VerticalTable<QuoteDTO> data={[selectedQuote]} columns={columns} />
            </div>
          </Card>

          <Hidden smDown>
            <div>
              <Card className={classes.card}>
                <div className={classes.cardInnerWrapper}>
                  <h3 className={classes.cardTitle}>Quote Summary</h3>
                  <p className={classes.summaryDescription}>Annual Cost</p>
                  <h2 className={classes.summaryPremium}>
                    <Money
                      value={selectedQuote.annualPolicyCost || selectedQuote.premium}
                      typography={TYPOGRAPHY.TITLE_2}
                    />
                  </h2>
                  <p className={classes.summaryCarrier}>{selectedQuote.carrier.displayName}</p>
                  <div className={classes.summaryButtonWrapper}>
                    <Button
                      className={classes.summaryButton}
                      disabled={isDownloading}
                      onClick={quoteDocument ? () => handleDownloadQuoteDocument(quoteDocument) : undefined}
                    >
                      {isDownloading ? 'Downloading' : 'Download Quote'}
                    </Button>
                    <EnrollButton
                      proposal={proposal}
                      className={classes.summaryButton}
                      applicationId={applicationId}
                      selectedQuote={selectedQuote}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </Hidden>
          <Hidden mdUp>
            <BottomActionsNavigation>
              <Button className={classes.summaryButton} onClick={() => selectDifferentQuote()}>
                Select Different Quote
              </Button>
              <EnrollButton
                proposal={proposal}
                className={classes.summaryButton}
                applicationId={applicationId}
                selectedQuote={selectedQuote}
              />
            </BottomActionsNavigation>
          </Hidden>
        </div>
      </section>
    </div>
  );
};
