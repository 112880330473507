import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { ApplicationDTO } from 'dtos/application';
import { TermsDTO } from 'dtos/quotes';
import { useToasters } from 'hooks/useToasters';
import { useCarriers } from 'queries/useCarriers';
import { useScheduleQuoting } from 'queries/useQuote';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `${theme.spacing(3)}px !important`,
    paddingTop: '0 !important',
  },
}));

interface Props {
  application: ApplicationDTO;
  className?: string;
}

export const ScheduleQuotingButton: React.FC<Props> = ({ application, className }) => {
  const [selectedCarrier, setSelectedCarrier] = useState<string>('all');
  const { data: carriers, isLoading: isLoadingCarriers } = useCarriers();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { preferred_retention, limit_aggregate, target_effective_date } = application.applicationResponse;
  const { mutate, isLoading } = useScheduleQuoting(application.id, {
    onSuccess: () => {
      showToaster('Scheduled API Quoting for application');
      handleCloseModal();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handleCloseModal = useCallback(() => {
    setSelectedCarrier('all');
    setOpenModal(false);
  }, []);

  const handleScheduleClick = useCallback(() => {
    let terms: TermsDTO | undefined;

    if (preferred_retention && limit_aggregate) {
      terms = {
        retention: parseInt(preferred_retention.replace(/,/g, ''), 10),
        limit: parseInt(limit_aggregate.replace(/,/g, ''), 10),
      };
    }

    mutate({
      carrierId: selectedCarrier === 'all' ? undefined : selectedCarrier,
      terms,
    });
  }, [preferred_retention, limit_aggregate, mutate, selectedCarrier]);

  return (
    <>
      <Button className={className} startIcon={<ScheduleIcon />} onClick={() => setOpenModal(true)}>
        Schedule quoting
      </Button>
      <Dialog fullWidth={true} open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Schedule API Quoting</DialogTitle>
        <DialogContent className={classes.content}>
          <p style={{ marginTop: 0 }}>Quote selected carrier using the requested terms.</p>
          <p>
            <IconButton
              color='primary'
              style={{ padding: 0, marginRight: 8 }}
              aria-label='edit terms'
              onClick={() =>
                history.push(
                  `${insertIdToRoute(ROUTES.agent.application.FORM, application.id)}?section=requested-terms`,
                )
              }
            >
              <EditRoundedIcon style={{ fontSize: 16 }} />
            </IconButton>
            <b>Limit:</b> ${limit_aggregate}, <b>retention</b>: ${preferred_retention}, <b>target effective date:</b>{' '}
            {target_effective_date}
          </p>
          <CustomTextFieldShrink
            select
            disabled={isLoadingCarriers}
            value={selectedCarrier}
            label='Carrier'
            onChange={(el) => setSelectedCarrier(el.target.value as string)}
          >
            <MenuItem key='undefined' value='all'>
              All carriers
            </MenuItem>
            {carriers
              ?.filter((c) => c.apiQuoting)
              .map((carrier) => (
                <MenuItem key={carrier.id} value={carrier.id}>
                  {carrier.displayName}
                </MenuItem>
              ))}
          </CustomTextFieldShrink>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
          <Button onClick={handleScheduleClick} disabled={isLoading}>
            {isLoading ? 'Scheduling...' : 'Schedule Quoting'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
