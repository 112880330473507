import { SecurityScanDTO, SecurityScanStatus, TargetType } from 'dtos/application';
import { uniq } from 'lodash';
import { useMemo } from 'react';

interface Props {
  securityScan: SecurityScanDTO;
}

export const useExternalScan = ({ securityScan }: Props) => {
  const allSubdomains = useMemo(
    () =>
      uniq(
        [securityScan.domain]
          .concat(securityScan?.subDomains || [])
          .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
      ),
    [securityScan],
  );

  const allSubdomainsIPAddresses = useMemo(
    () =>
      uniq(
        [securityScan.domain]
          .concat(securityScan?.subDomains || [])
          .concat(securityScan?.ipAddresses || [])
          .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
      ),
    [securityScan],
  );

  const allDomains = useMemo(
    () =>
      uniq(
        [securityScan]
          .concat((securityScan?.subDomainScans || []).filter((s) => s.targetType === TargetType.DOMAIN))
          .map((s) => s.domain)
          .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
      ),
    [securityScan],
  );

  const subDomainScans = useMemo(
    () =>
      [securityScan].concat((securityScan?.subDomainScans || []).filter((s) => s.targetType === TargetType.SUB_DOMAIN)),
    [securityScan],
  );

  const subDomainIPAddressScans = useMemo(
    () => [securityScan].concat(securityScan?.subDomainScans || []),
    [securityScan],
  );

  const allDomainScans = useMemo(
    () => [securityScan].concat((securityScan?.subDomainScans || []).filter((s) => s.targetType === TargetType.DOMAIN)),
    [securityScan],
  );

  const scannedSubdomainsSocialEngineering = allDomainScans?.filter((s) => s.emailScannedAt)?.map((s) => s?.domain);

  const scannedSubdomainsEndpointSecurity = subDomainScans?.filter((s) => s?.networkScannedAt)?.map((s) => s?.domain);

  const scannedSubdomainsNetwork = subDomainIPAddressScans?.filter((s) => s.networkScannedAt)?.map((s) => s?.domain);

  const scannedSubdomainsIpReputation = subDomainIPAddressScans
    ?.filter((s) => s.ipReputationScannedAt)
    ?.map((s) => s?.domain);

  const scannedSubdomainsApplicationSecurity = subDomainScans?.filter((s) => s.networkScannedAt)?.map((s) => s?.domain);

  const scannedSubdomainsDnsHealth = allDomainScans?.filter((s) => s.dnsScannedAt)?.map((s) => s?.domain);

  const scannedSubdomainsPatchingCadence = subDomainIPAddressScans
    ?.filter((s) => s.patchingCadenceScannedAt)
    ?.map((s) => s?.domain);

  const isLoading = securityScan.status !== SecurityScanStatus.COMPLETED;
  const isAnySubdomainScanLoading = subDomainScans.some((s) => s.status !== SecurityScanStatus.COMPLETED);

  const shouldPullFindings = isLoading && isAnySubdomainScanLoading;

  const isLoadingScanEmail = securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.emailScannedAt;
  const isErrorScanEmail = securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.emailScannedAt;

  const isLoadingPatchingCadence =
    securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.patchingCadenceScannedAt;
  const isErrorPatchingCadence =
    securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.patchingCadenceScannedAt;

  const isLoadingScanDns = securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.dnsScannedAt;
  const isErrorScanDns = securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.dnsScannedAt;

  const isLoadingScanNetwork = securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.networkScannedAt;
  const isErrorScanNetwork = securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.networkScannedAt;

  const isLoadingScanIpReputation =
    securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.ipReputationScannedAt;
  const isErrorScanIpReputation =
    securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.ipReputationScannedAt;

  return {
    allSubdomains,
    allSubdomainsIPAddresses,
    allDomains,
    isAnySubdomainScanLoading,
    isErrorPatchingCadence,
    isErrorScanDns,
    isErrorScanEmail,
    isErrorScanIpReputation,
    isErrorScanNetwork,
    isLoadingPatchingCadence,
    isLoadingScanDns,
    isLoadingScanEmail,
    isLoadingScanIpReputation,
    isLoadingScanNetwork,

    scannedSubdomainsSocialEngineering,
    scannedSubdomainsEndpointSecurity,
    scannedSubdomainsIpReputation,
    scannedSubdomainsNetwork,
    scannedSubdomainsApplicationSecurity,
    scannedSubdomainsDnsHealth,
    scannedSubdomainsPatchingCadence,

    subDomainIPAddressScans,
    subDomainScans,
    shouldPullFindings,
    isLoading,
  };
};
