import { Button } from '@material-ui/core';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { Modal } from 'components/Modal';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useScheduleReportsGeneration } from 'queries/useDeepScan';
import React, { useCallback } from 'react';

interface Props {
  application: ApplicationWithAgentDTO;
  className?: string;
}

export const GenerateReportsButton: React.FC<Props> = ({ application, className }) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster } = useToasters();
  const { mutate } = useScheduleReportsGeneration(application?.deepScanId || '', {
    onSuccess: () => {
      showToaster(
        'Scheduled reports generation successfully. We will notify you via email once the reports are ready.',
      );
      setOpen(true);
    },
  });

  const handleClick = useCallback(async () => {
    mutate();
  }, [mutate]);

  return (
    <>
      <Button variant='outlined' className={className} onClick={handleClick} startIcon={<CloudDownloadRoundedIcon />}>
        Schedule All Reports Generation
      </Button>
      <Modal
        handleClose={() => setOpen(false)}
        isOpen={open}
        title={`Scheduled Reports Generation`}
        actions={
          <>
            <Button variant='contained' onClick={() => setOpen(false)}>
              Ok
            </Button>
          </>
        }
      >
        <p>
          Reports are being generated in the background. We will notify you via email once the reports are successfully
          created.
        </p>
      </Modal>
    </>
  );
};
