import { RiskLine, RiskLineDataPoint } from 'components/charts/RiskLine';
import { MonitoringSnapshotDTO } from 'dtos/application';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';

export const useMonitoringIdentityAccessChart = (
  enableCharts: boolean,
  data?: MonitoringSnapshotDTO[],
  selectedAssets?: string[],
  onlyActive?: boolean,
  classname?: string,
) => {
  const weakPasswords = useMemo(() => {
    const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
      const finished = Object.keys(snapshot.scanStatus || {}).every(
        (h) => snapshot.scanStatus[h].telivyAgentInstalledAt || snapshot.scanStatus[h].browserPasswordExecutedAt,
      );
      const message = finished ? 'Latest Scan' : 'Scan In Progress';
      const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
      acc[date] = acc[date] || snapshot;

      return acc;
    }, {});

    const snapshotsDates = sortBy(
      Object.keys(snapshotsByDate).map((date) => {
        return { date, createdAt: snapshotsByDate[date].createdAt };
      }),
      (snapshot) => snapshot.createdAt,
    ).map((d) => d.date);

    let selectedAssetsFinal: string[] | undefined = undefined;
    if (onlyActive && snapshotsDates.length > 1) {
      const latestSnapshot = snapshotsDates[snapshotsDates.length - 2];
      selectedAssetsFinal = Object.keys(snapshotsByDate[latestSnapshot].passwordScanStats).filter((s) => {
        return (
          !snapshotsByDate[latestSnapshot].scanStatus ||
          !snapshotsByDate[latestSnapshot].scanStatus[s]?.system?.rescan_scheduled
        );
      });
    } else {
      selectedAssetsFinal = selectedAssets;
    }

    const summedByDate = sortBy(
      snapshotsDates.map((date, index) => {
        const prevDate = snapshotsDates[index - 1];

        const filteredAssets =
          (selectedAssetsFinal || []).length === 0
            ? Object.keys(snapshotsByDate[date].passwordScanStats)
            : selectedAssetsFinal || [];

        const assetsData = filteredAssets
          .filter((s) => {
            return !snapshotsByDate[date].scanStatus || !snapshotsByDate[date].scanStatus[s]?.system?.rescan_scheduled;
          })
          .map((s) => {
            const completed =
              snapshotsByDate[date].scanStatus && snapshotsByDate[date].scanStatus[s]?.browserPasswordExecutedAt;

            return {
              name: completed ? s : `${s} *`,
              value: snapshotsByDate[date].passwordScanStats[s]?.passwordStrength?.weak || 0,
              previous: (prevDate && snapshotsByDate[prevDate].passwordScanStats[s]?.passwordStrength?.weak) || 0,
            };
          });

        const weakPasswords = assetsData.reduce((a, b) => a + b.value, 0);
        const weakPasswordsPrev =
          (prevDate &&
            Object.values(snapshotsByDate[prevDate].passwordScanStats).reduce(
              (a, b) => a + b.passwordStrength?.weak || 0,
              0,
            )) ||
          0;

        return {
          date,
          weakPasswords,
          weakPasswordsPrev,
          assetsData,
          createdAt: snapshotsByDate[date].createdAt,
        };
      }),
      (snapshot) => snapshot.createdAt,
    );

    return summedByDate
      .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
        acc.push({
          name: snapshot.date,
          score: snapshot.weakPasswords,
          distribution: ([] as any).concat(snapshot.assetsData, [
            { name: 'Total', value: snapshot.weakPasswords, previous: snapshot.weakPasswordsPrev },
          ]),
        });

        return acc;
      }, [])
      .slice(0, 365);
  }, [data, selectedAssets, onlyActive]);

  const chart = useMemo(() => {
    return (
      <RiskLine
        locked={!enableCharts}
        id='monitoring-identity-access'
        className={classname}
        data={weakPasswords}
        showTooltip
        // title='Critical Vulnerabilities'
      />
    );
  }, [classname, enableCharts, weakPasswords]);

  return {
    chart,
    weakPasswords,
  };
};
