import { makeStyles, Theme } from '@material-ui/core';
import BackgroundImage from 'images/proposals-bg-flipped.svg';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

type Variant = 'sign' | 'thankyou' | 'billing';

interface StyleProps {
  variant: Variant;
}
export const useSectionStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    ...(props.variant !== 'thankyou' && {
      paddingBottom: theme.spacing(10),
    }),

    [theme.breakpoints.up('md')]: {
      background: `url(${BackgroundImage}) no-repeat center ${theme.spacing(7.5)}px`,
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      minHeight: `calc(100vh - ${theme.spacing(7.5)}px)`,
      boxSizing: 'border-box',

      ...(props.variant === 'thankyou' && {
        paddingTop: theme.spacing(7.5),
        minHeight: '100vh',
      }),
    },

    [theme.breakpoints.down('sm')]: {
      background: COLORS.WHITE,
      ...(props.variant !== 'thankyou' && {
        paddingBottom: theme.spacing(12),
      }),
    },
  }),

  section: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },

  heading: {
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: (props) => ({
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },

    ...(props.variant === 'billing' && {
      marginBottom: theme.spacing(6.375),
    }),
  }),
  description: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.GREY_2,
    margin: 0,
    marginBottom: theme.spacing(4),
  },

  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      alignItems: 'stretch',
      padding: `0 ${theme.spacing(2)}px`,
    },
  },

  summaryCard: {
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      background: COLORS.GREY_6,
      border: `1px solid ${COLORS.GREY_5}`,
    },
  },
  outlinedCard: {
    [theme.breakpoints.down('sm')]: {
      border: `1px solid ${COLORS.GREY_5}`,
    },
  },
  cardInnerWrapper: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    ...(props.variant === 'sign' && {
      padding: theme.spacing(4),
    }),

    [theme.breakpoints.up('md')]: {
      minWidth: 650,
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      ...(props.variant === 'billing' && {
        padding: `0 ${theme.spacing(2)}px`,
      }),
    },
  }),

  cardDocsInnerWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },

  cardTitle: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      color: COLORS.GREY_2,
      textAlign: 'center',
      marginBottom: theme.spacing(),
    },
  },
  cardTitleIcon: {
    width: 13,
    height: 'auto',
    marginBottom: -3,
    marginRight: 4,
  },
  cardDescription: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    maxWidth: 330,
    textAlign: 'center',
    padding: `${theme.spacing(4)}px 0`,
  },

  billingHeaderImage: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  viewWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  viewSection: {
    width: '100%',
    maxWidth: 550,
    marginTop: theme.spacing(3.5),
  },
  viewItem: (props) => ({
    display: 'flex',

    ...(props.variant === 'sign' && {
      width: '100%',
      maxWidth: 550,
      borderBottom: `1px solid ${COLORS.GREY_5}`,

      '& + &': {
        marginTop: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(3.75),
        },
      },

      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none',
        flexDirection: 'column',
      },
    }),

    ...(props.variant === 'thankyou' && {
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
  }),

  viewIcon: {
    width: 20,
    height: 'auto',
    marginBottom: -3,
    marginRight: 6,
  },
  viewItemKey: (props) => ({
    marginBottom: theme.spacing(0.5),

    ...(props.variant === 'thankyou' && {
      ...TYPOGRAPHY.REGULAR_REGULAR,
    }),

    ...(props.variant === 'sign' && {
      ...TYPOGRAPHY.SMALL_MEDIUM,
      marginTop: 0,

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  }),
  viewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...TYPOGRAPHY.SMALL_BOLD,
    paddingBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${COLORS.GREY_5}`,
  },

  viewButtonWrapper: (props) => ({
    [theme.breakpoints.up('md')]: {
      ...(props.variant !== 'billing' && {
        marginTop: theme.spacing(4),
      }),
    },
  }),
}));
