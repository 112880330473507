import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { useToasters } from 'hooks/useToasters';
import { useCurrentAgencyUsers } from 'queries/useAgency';
import { useChangeApplicationUserBulk } from 'queries/useApplications';
import React, { useState } from 'react';
// import { useHistory } from 'react-router';
// import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  form: {
    minWidth: '400px',
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  applicationIds: string[];
  className?: string;
  onBulkAction?: () => void;
}

export const BulkReassignButton: React.FC<Props> = ({ applicationIds, className, onBulkAction }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { data: agents, isLoading: isLoadingAgents } = useCurrentAgencyUsers();
  // const history = useHistory();

  const { mutate, isLoading } = useChangeApplicationUserBulk(applicationIds, {
    onSuccess: () => {
      showToaster(`Successfully changed applications user`);
      setIsModalOpen(false);
      if (onBulkAction) onBulkAction();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const { handleSubmit, control } = useForm<{ agentId: string }>({
    defaultValues: {
      agentId: '',
    },
  });

  const onSubmit = (data: { agentId: string }) => {
    mutate(data.agentId);
  };

  const MODAL_TITLE = 'Confirm reassigning assessments user';

  if (applicationIds.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        className={className}
        onClick={handleModalOpen}
        variant='outlined'
        color='primary'
        startIcon={<AccountCircleRoundedIcon />}
      >
        Reassign
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography variant='h3' className={classes.title}>
              {MODAL_TITLE}
            </Typography>

            <Controller
              name='agentId'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth className={classes.form}>
                  <InputLabel id='select-user-label'>Assign assessments to</InputLabel>
                  <Select
                    disabled={isLoadingAgents}
                    labelId='select-account-user'
                    value={field.value}
                    onChange={(el) => {
                      field.onChange(el.target.value as string);
                    }}
                    label='Select user to assign assessments to'
                    required
                  >
                    {(agents || []).map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstName} {user.lastName} ({user.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={isLoading}
              startIcon={<AccountCircleRoundedIcon />}
            >
              {isLoading ? 'Saving...' : 'Confirm'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
