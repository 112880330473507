import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import cx from 'classnames';
import { DnbSelect } from 'components/DnbSelect';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDTO } from 'dtos/application';
import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { isSecurityAgency, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ApplicationCreated } from './ApplicationCreated';
import { CreateApplicationForm } from './CreateApplicationForm';

const useStyles = makeStyles((theme) => ({
  rootButton: {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    ...TYPOGRAPHY.SMALL_MEDIUM,
    cursor: 'pointer',
    padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1),
    backgroundColor: COLORS.GREY_5,

    '&:hover': {
      color: COLORS.BLUE_1,
    },
  },

  buttonIcon: {
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  },
}));

export enum State {
  INITIAL = 'INITIAL',
  DNB = 'DNB',
  CREATED = 'CREATED',
}

interface Props {
  className?: string;
  hideButton?: boolean;
  initialState?: State;
  initialApplication?: ApplicationDTO;
  agent: AgentDTO;
}

export const CreateApplicationButton: React.FC<Props> = ({
  className,
  initialState,
  initialApplication,
  hideButton,
  agent,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = useState<State | null>(initialState || null);
  const [createdApplication, setCreatedApplication] = useState<ApplicationDTO | null>(initialApplication || null);

  const handleClose = useCallback(() => {
    setState(null);
    setCreatedApplication(null);
  }, []);

  const handleInitialApplicationCreation = useCallback(
    (application: ApplicationDTO) => {
      setCreatedApplication(application);

      // If there are any results returned from a D&B service, show the selection modal
      if (application.dnbData && application.dnbData.matchResponse && application.dnbData.matchResponse.length > 0) {
        setState(State.DNB);
      } else {
        // If there are no results returned from D&B show final created summary section
        // setState(State.CREATED);
        handleClose();
        if (isSecurityApplication(application)) {
          history.push(insertIdToRoute(ROUTES.agent.application.ROOT, application.id));
        }
      }
    },
    [history, handleClose],
  );

  const handleSelectDunes = useCallback((application: ApplicationDTO) => {
    setCreatedApplication(application);
    setState(State.CREATED);
  }, []);

  const content = useMemo(() => {
    switch (state) {
      case State.INITIAL:
        return (
          <CreateApplicationForm
            agent={agent}
            onApplicationCreated={handleInitialApplicationCreation}
            onClose={handleClose}
          />
        );
      case State.DNB:
        if (createdApplication) {
          return (
            <DnbSelect
              agent={agent}
              application={createdApplication}
              onSkip={() => setState(State.CREATED)}
              onDunsSelect={handleSelectDunes}
            />
          );
        }

        // This should never happen
        return null;
      case State.CREATED:
        if (createdApplication) {
          return <ApplicationCreated agent={agent} application={createdApplication} onClose={handleClose} />;
        }

        // This should never happen
        return null;
      default:
        return null;
    }
  }, [agent, createdApplication, handleClose, handleInitialApplicationCreation, handleSelectDunes, state]);

  return (
    <>
      {!hideButton && (
        <button className={cx(className, classes.rootButton)} onClick={() => setState(State.INITIAL)}>
          <AddRoundedIcon className={classes.buttonIcon} />
          {isSecurityAgency(agent) ? 'Create Assessment' : 'Create Application'}
        </button>
      )}

      <Dialog fullWidth={true} open={Boolean(state)} onClose={handleClose} maxWidth='md'>
        {content}
      </Dialog>
    </>
  );
};
