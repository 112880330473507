import isObject from 'lodash/isObject';

// Envs definitions
export const API_URL = process.env.REACT_APP_API_URL as string;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const GOOGLE_ANALYTICS_TAG_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID as string;
export const AUTH0_ACCESS_TOKEN_KEY = 'AUTH0_ACCESS_TOKEN_KEY';
export const ADMIN_ACCESS_TOKEN_KEY = 'ADMIN_ACCESS_TOKEN_KEY';
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const GO_SQUARED_TOKEN = process.env.REACT_APP_TELIVY_GO_SQUARED_TOKEN as string;
export const WESTERN_INSURANCE_ANALYTICS_TOKEN = process.env.REACT_APP_WESTERN_INSURANCE_ANALYTICS_TOKEN as string;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

export const INSTALL_AGENTS_EXE_URL = process.env.REACT_APP_INSTALL_AGENTS_EXE as string;
export const INSTALL_AGENTS_ONE_LINER = process.env.REACT_APP_INSTALL_AGENTS_ONE_LINER as string;
export const INSTALL_AGENTS_MAC_ONE_LINER = process.env.REACT_APP_INSTALL_AGENTS_MAC_ONE_LINER as string;

export const SCAN_BROWSER_PASSWORDS_EXE_URL = process.env.REACT_APP_SCAN_BROWSER_PASSWORDS_EXE as string;
export const SCAN_BROWSER_PASSWORDS_DMG_M1_URL = process.env.REACT_APP_SCAN_BROWSER_PASSWORDS_DMG_M1 as string;
export const SCAN_BROWSER_PASSWORDS_DMG_INTEL_URL = process.env.REACT_APP_SCAN_BROWSER_PASSWORDS_DMG_INTEL as string;

export const SCAN_BROWSER_PASSWORDS_ONE_LINER = process.env.REACT_APP_SCAN_BROWSER_PASSWORDS_ONE_LINER as string;
export const SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER = process.env
  .REACT_APP_SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER as string;

export const UNINSTALL_AGENTS_ONE_LINER = process.env.REACT_APP_UNINSTALL_AGENTS_ONE_LINER as string;

export const UNINSTALL_AGENTS_MAC_ONE_LINER: string = `sudo /bin/bash -c "$(curl -fsSL 'https://install.telivy.com/uninstall_agents.sh')"`;
export const UNINSTALL_SCAN_ONE_LINER: string = `Invoke-Command -ScriptBlock ([scriptblock]::Create([System.Text.Encoding]::UTF8.GetString((New-Object Net.WebClient).DownloadData('https://install.telivy.com/uninstall-scan.ps1'))))`;

export const GO_SQUARED_SNIPPET = GO_SQUARED_TOKEN
  ? `!(function (g, s, q, r, d) {
    r = g[r] =
      g[r] ||
      function () {
        (r.q = r.q || []).push(arguments);
      };
    d = s.createElement(q);
    d.src = '//d1l6p2sc9645hc.cloudfront.net/gosquared.js';
    q = s.getElementsByTagName(q)[0];
    q.parentNode.insertBefore(d, q);
  })(window, document, 'script', '_gs');

  _gs('${GO_SQUARED_TOKEN}');
  _gs('set', 'anonymizeIP', true);
`
  : undefined;

export const WESTERN_INSURANCE_ANALYTICS_SNIPPET = WESTERN_INSURANCE_ANALYTICS_TOKEN
  ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${WESTERN_INSURANCE_ANALYTICS_TOKEN}');
`
  : undefined;

export const WESTERN_INSURANCE_ANALYTICS_NOSCRIPT_IFRAME_URL = WESTERN_INSURANCE_ANALYTICS_TOKEN
  ? `https://www.googletagmanager.com/ns.html?id=${WESTERN_INSURANCE_ANALYTICS_TOKEN}`
  : undefined;

export const SECURITY_ROUTES = {
  OVERVIEW: '/overview',
  TARGETS: '/targets',
  EXTERNAL_SCAN: '/external-scan',
  FINDINGS: '/findings',
  DARK_WEB: '/dark-web',
  FINDING: '/finding/:slug',
  BROWSER_PASSWORDS: '/browser-passwords',
  TECHSTACK: '/techstack',
  PASSWORDS: '/passwords',
  VULNERABILITIES: '/vulnerabilities',
  DATA_LIABILITY: '/data-liability',
  RISK_ANALYSIS: '/risk-analysis',
  COMPANY_INFO: '/company-info',
  DOWNLOAD: '/download',
  deepScan: withParentPrefix('/deep-scan', {
    NETWORK_SECURITY: '/network-security',
    APPLICATION_SECURITY: '/application-security',
    DATA_SECURITY: '/data-security',
    DATA_SECURITY_PII_TOOLS: '/pii-tools',
    IDENTITY_ACCESS_MANAGEMENT: '/identity-access-management',
    DARK_WEB_PRESENCE: '/dark-web-presence',
    M365_SECURITY: '/m365-security',
    GWS_SECURITY: '/google-ws-security',
  }),
};

export const SUPPORT_URL = 'https://support.telivy.com';

// All routes used in the application are defined here
export const ROUTES = {
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  ADMIN: '/admin',
  ADMIN_APPLICATION_CHARTS: '/admin-application-charts/:id',
  agent: withParentPrefix('/agent', {
    SUMMARY: '/summary',
    DASHBOARD: '/dashboard',
    SUPPORT: '/support',
    account: withParentPrefix('/account', {
      MANAGE: '/manage',
      USERS: '/users',
      LEAD_MAGNET: '/lead-magnet',
      BILLING: '/billing',
    }),
    admin: withParentPrefix('/admin', {
      MANAGE: '/manage',
      USERS: '/users',
      ACCOUNTS: '/accounts',
      LEAD_MAGNET: '/lead-magnet',
    }),
    application: withParentPrefix('/application/:id', {
      security: withParentPrefix('/security/:securityScanId', SECURITY_ROUTES),
      HISTORY: '/history',
      CONFIGURATION: '/configuration',
      assets: withParentPrefix('/assets', {
        DOMAINS: '/domains',
        DEVICES: '/devices',
        INVENTORY: '/inventory',
        INTEGRATIONS: '/integrations',
      }),
      MONITORING: '/monitoring',
      FORM: '/form',
      QUOTES: '/quotes',
      PROPOSAL: '/proposal',
      DOCUMENTS: '/documents',
      REPORTS: '/reports',
      SERVICES: '/services',
      POLICIES: '/policies',
    }),
  }),

  START: '/start/:id', // agentId
  VERIFY: '/verify/:id', // applicationId

  application: withParentPrefix('/application/:id', {
    FORM: '/form',
    QUOTING: '/quoting',
    PROPOSAL: '/proposal/:proposalId?', // proposalId is optional
    security: withParentPrefix('/security', SECURITY_ROUTES),
    POLICY: '/policy',
  }),

  M365_THANK_YOU: '/thank-you/m365',
  GWS_THANK_YOU: '/thank-you/google-workspace',
  GWS_ERROR: '/error/google-workspace',

  OLD_WEB_PROPOSAL: '/web-proposal/:id', // proposalId
  OLD_APPLICATION_POLICY: '/application-policy/:id', // applicationId
  OLD_QUOTING: '/quoting/:id', // applicationId
  OLD_CLIENT_APPLICATION: '/client/:id', // applicationId
};

export const insertIdToRoute = (route: string, id: string) => route.replace(':id', id);

export const createSecurityRoute = (
  route: string,
  id: string,
  securityScanId: string = ':securityScanId',
  findingSlug: string = ':slug',
) => route.replace(':id', id).replace(':securityScanId', securityScanId).replace(':slug', findingSlug);

export const createRedirectUrl = (route: string, id: string, search: string) =>
  `${insertIdToRoute(route, id)}${search}`;

interface DefaultRoute {
  ROOT: string;
}

function withParentPrefix<M extends Record<string, any>>(prefix: string, routes: M): M & DefaultRoute {
  const initial: M & DefaultRoute = Object.assign(
    {
      ROOT: prefix,
    },
    routes,
  );

  return Object.keys(routes).reduce(
    (accumulator: M & DefaultRoute, key: string) =>
      Object.assign(accumulator, {
        [key]: isObject(routes[key]) ? withParentPrefix(prefix, routes[key]) : `${prefix}${routes[key]}`,
      }),
    initial,
  );
}

export enum AssessmentType {
  EXTERNAL = 'external',
  RISK = 'risk',
  RISK_PLUS = 'risk_plus',
}

export const ASSESSMENT_NAMES: { [key in AssessmentType]: string } = {
  [AssessmentType.EXTERNAL]: 'External Scan',
  [AssessmentType.RISK]: 'Risk Assessment',
  [AssessmentType.RISK_PLUS]: 'Risk Assessment Plus',
};

// Randomizes the interval by 20% to avoid all clients hitting the server at the same time
export const generateRefetchIntervalMs = (minimumSeconds: number = 60) => {
  const randomPercentage = Math.random() * 0.2;
  const randomSeconds = minimumSeconds * randomPercentage;
  return minimumSeconds + randomSeconds * 1000;
};
