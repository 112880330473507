import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch as SwitchComponent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { getDeepScanPassPhrase, setDeepScanPassPhrase } from 'dtos/deep-scan';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  deepScanId: string;
  className?: string;
  onChange?: (el: string) => void;
}

interface Form {
  passPhrase: string | null;
}

export const PassPhraseSwitch: React.FC<Props> = ({ deepScanId, onChange }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const passPhraseStored = useMemo(() => getDeepScanPassPhrase(deepScanId), [deepScanId]);
  const [selected, setSelected] = useState(Boolean(passPhraseStored));

  const updatePassPhrase = useCallback(
    (passPhrase) => {
      setDeepScanPassPhrase(deepScanId, passPhrase);
      if (onChange) {
        onChange(passPhrase);
      }
    },
    [deepScanId, onChange],
  );

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      passPhrase: passPhraseStored,
    },
  });

  const onSubmit = (data: Form) => {
    if (selected) {
      updatePassPhrase(null);
      setSelected(false);
    } else {
      updatePassPhrase(data.passPhrase);
      setSelected(true);
    }

    setIsOpen(false);
  };

  const FORM_ID = 'passphrase_form';

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<SwitchComponent checked={selected} onChange={() => setIsOpen(true)} color='primary' />}
          label={selected ? 'Enabled' : 'Enable'}
        />
      </FormGroup>

      <Dialog fullWidth={true} open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{`${selected ? 'Disable' : 'Enable'} Full Password Capture`}</DialogTitle>
        <DialogContent key='content'>
          <IconButton className={classes.closeButton} onClick={() => setIsOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
          <Typography variant='body1' className={classes.sectionTitle}>
            {selected ? 'Current Passphrase' : 'Enter Passphrase'}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              <Controller
                name='passPhrase'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <CustomTextFieldShrink {...field} label='Secret Passphrase' required disabled={selected} />
                )}
              />
            </section>
          </form>
          <div>
            <br />
            {selected
              ? `Disabling this feature will prevent you from being able to decrypt already captured full passwords, unless you remember this passphrase and enable this feature back.`
              : `By enabling this feature, you agree that to keep this passphrase a secret. If you forget this passphrase, there is no way to decrypt the captured password. Already captured full passwords can be decrypted only if this passphrase is same as the one used to capture them previously. This passphrase will be active only after you run the install script on devices.`}
          </div>
        </DialogContent>
        <DialogActions key='actions'>
          <div>
            <Button type='submit' color='primary' variant='contained' form={FORM_ID}>
              {selected ? 'Confirm and Disable' : 'Confirm and Enable'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
