import { AxiosError } from 'axios';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import {
  ApplicationBreachDataDTO,
  ApplicationDocumentDTO,
  ApplicationDocumentsGroupDTO,
  ApplicationDocumentType,
  ApplicationDTO,
  ApplicationQuotesCount,
  ApplicationRawDnbDTO,
  ApplicationsAdminResponse,
  ApplicationsQuery,
  ApplicationsResponse,
  ApplicationStatus,
  ApplicationVersionDTO,
  ApplicationWithAgentDTO,
  AssignDocumentDTO,
  CreateApplicationDocumentDTO,
  CreateApplicationDTO,
  CreateTargetDTO,
  GenerateApplicationDocumentDTO,
  MonitoringSnapshotDTO,
  SecurityScanDTO,
  SecurityScanHistoryDTO,
  SecurityScanStatus,
  TargetDTO,
  UpdateApplicationSettingDTO,
} from 'dtos/application';
import { DeclinationDTO } from 'dtos/declinations';
import { PolicyDTO } from 'dtos/policy';
import { ProposalDTO } from 'dtos/proposal';
import { QuoteDTO } from 'dtos/quotes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { ApplicationService } from 'services/Application';

export const useNaicsCodes = () =>
  useQuery<string[]>('naics-codes', () => ApplicationService.getNaicsCodes(), {
    staleTime: 1000 * 60 * 5,
    refetchInterval: false,
  });

export const useClientApplicationActivePolicy = (id: string, options?: UseQueryOptions<PolicyDTO, AxiosError>) =>
  useQuery<PolicyDTO, AxiosError>(
    ['active-application-id', id],
    () => ApplicationService.clientApplicationActivePolicy(id),
    options,
  );

export const useApplicationQuotesCount = (applicationId: string) =>
  useQuery<ApplicationQuotesCount>(['application-quotes-count', applicationId], () =>
    ApplicationService.quotesCount(applicationId),
  );

export const useApplications = (query: ApplicationsQuery) => {
  const queryClient = useQueryClient();

  return useQuery<ApplicationsResponse>(['applications', query], async () => {
    const response = await ApplicationService.getAllApplications(query);

    if (response.applications.filter((a) => a.securityScan?.status === SecurityScanStatus.SCANNING).length > 0) {
      // update scanning status after 5s
      setTimeout(() => queryClient.invalidateQueries(['applications']), 5000);
    }

    return response;
  });
};

// deprecated
// export const useApplicationSecurityStats = (id: string, options?: UseQueryOptions<ApplicationSecurityStats>) =>
//   useQuery<ApplicationSecurityStats>(
//     ['application-security-stats', id],
//     () => ApplicationService.getApplicationSecurityStats(id),
//     options,
//   );

// export const useApplicationIndication = (id: string, options?: UseQueryOptions<number, AxiosError>) =>
//   useQuery<number, AxiosError>(['application-indication', id], () => ApplicationService.getApplicationIndication(id), {
//     staleTime: 5 * 60 * 1000, // 5 min
//     retry: false,
//     ...options,
//   });

// export const useApplicationScanEmail = (id: string, options?: UseQueryOptions<ScanEmailResponseDTO>) =>
//   useQuery<ScanEmailResponseDTO>(['application-scan-email', id], () => ApplicationService.getApplicationScanEmail(id), {
//     staleTime: 5 * 60 * 1000, // 5 min
//     ...options,
//   });

// export const useApplicationScanIpReputation = (id: string, options?: UseQueryOptions<ScanIpReputationResponseDTO>) =>
//   useQuery<ScanIpReputationResponseDTO>(
//     ['application-scan-ip-reputation', id],
//     () => ApplicationService.getApplicationScanIpReputation(id),
//     {
//       staleTime: 5 * 60 * 1000, // 5 min
//       ...options,
//     },
//   );

// export const useApplicationScanDns = (id: string, options?: UseQueryOptions<ScanDnsResponseDTO>) =>
//   useQuery<ScanDnsResponseDTO>(['application-scan-dns', id], () => ApplicationService.getApplicationScanDns(id), {
//     staleTime: 5 * 60 * 1000, // 5 min
//     ...options,
//   });

// export const useApplicationScanNetwork = (id: string, options?: UseQueryOptions<ScanNetworkResponseDTO>) =>
//   useQuery<ScanNetworkResponseDTO>(
//     ['application-scan-network', id],
//     () => ApplicationService.getApplicationScanNetwork(id),
//     {
//       staleTime: 5 * 60 * 1000, // 5 min
//       ...options,
//     },
//   );

export const useApplicationSecurityScan = (
  id: string,
  securityScanId: string,
  options?: UseQueryOptions<SecurityScanDTO>,
) =>
  useQuery<SecurityScanDTO>(
    ['application-security-scan', id, securityScanId],
    () => ApplicationService.getApplicationSecurityScan(id, securityScanId),
    {
      staleTime: 5 * 60 * 1000, // 5 min
      ...options,
    },
  );

export const useApplicationSecurityScanForceRescan = (
  id: string,
  securityScanId: string,
  options?: UseMutationOptions<SecurityScanDTO, AxiosError, { notifyViaEmail?: boolean }>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'force-security-scan',
    (params) => ApplicationService.getApplicationSecurityScanForceRescan(id, securityScanId, params.notifyViaEmail),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', id]);
        await queryClient.refetchQueries(['application-security-scan', id, securityScanId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useApplicationScanHistory = (id: string, options?: UseQueryOptions<SecurityScanHistoryDTO>) =>
  useQuery<SecurityScanHistoryDTO>(
    ['application-scan-history', id],
    () => ApplicationService.getApplicationScanHistory(id),
    {
      staleTime: 5 * 60 * 1000, // 5 min
      ...options,
    },
  );

export const useApplicationMonitoringSnapshots = (
  id: string,
  limit: number,
  options?: UseQueryOptions<MonitoringSnapshotDTO[]>,
) =>
  useQuery<MonitoringSnapshotDTO[]>(
    ['application-monitoring-snapshots', id, limit],
    () => ApplicationService.getApplicationMonitoringSnapshots(id, limit),
    options,
  );

export const useAdminApplications = (query: ApplicationsQuery) =>
  useQuery<ApplicationsAdminResponse>(['applications-admin', query], () =>
    ApplicationService.getAllAdminApplications(query),
  );

export const useApplication = (id: string, options?: UseQueryOptions<ApplicationWithAgentDTO>) =>
  useQuery<ApplicationWithAgentDTO>(['application', id], () => ApplicationService.getApplicationById(id), options);

export const useLatestApplication = (options?: UseQueryOptions<ApplicationWithAgentDTO>) =>
  useQuery<ApplicationWithAgentDTO>(['latest-application'], () => ApplicationService.getLatestApplication(), options);

export const useLatestSecurityApplication = (options?: UseQueryOptions<ApplicationWithAgentDTO>) =>
  useQuery<ApplicationWithAgentDTO>(
    ['latest-security-application'],
    () => ApplicationService.getLatestSecurityApplication(),
    options,
  );

export const useApplicationProposals = (id: string, options?: UseQueryOptions<ProposalDTO[]>) =>
  useQuery<ProposalDTO[]>(['application-proposals', id], () => ApplicationService.getApplicationProposals(id), options);

export const useApplicationDocuments = (id: string, options?: UseQueryOptions<ApplicationDocumentDTO[]>) =>
  useQuery<ApplicationDocumentDTO[]>(
    ['application-documents', id],
    () => ApplicationService.getApplicationDocuments(id),
    {
      ...options,
      staleTime: 1000 * 10,
    },
  );

export const useApplicationDocumentsGroups = (id: string, options?: UseQueryOptions<ApplicationDocumentsGroupDTO[]>) =>
  useQuery<ApplicationDocumentsGroupDTO[]>(
    ['application-documents-groups', id],
    () => ApplicationService.getApplicationDocumentsGroups(id),
    {
      ...options,
      staleTime: 1000 * 10,
    },
  );

export const useDeleteApplicationDocumentsGroup = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['delete-application-documents-groups', applicationId],
    (groupId: string) => ApplicationService.deleteApplicationDocumentsGroup(applicationId, groupId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application-documents', applicationId]);
        await queryClient.refetchQueries(['application-documents-groups', applicationId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useGenerateDocumentsGroupZip = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, { groupId: string; fileName: string }>,
) =>
  useMutation(
    ['application-documents-group-zip', applicationId],
    (data) => ApplicationService.generateDocumentsGroupZip(applicationId, data.groupId, data.fileName),
    options,
  );

export const useGenerateExternalScanReport = (
  applicationId: string,
  options?: UseMutationOptions<
    void,
    AxiosError,
    { type: 'executive' | 'detailed'; orgName: string; format: 'pdf' | 'docx' }
  >,
) =>
  useMutation(
    ['application-external-scan-report', applicationId],
    (data) => ApplicationService.generateExternalScanReport(applicationId, data.orgName, data.type, data.format),
    options,
  );

export const useAssignDocumentToGroup = (
  applicationId: string,
  documentId: string,
  options?: UseMutationOptions<ApplicationDocumentDTO, AxiosError, AssignDocumentDTO>,
) =>
  useMutation(
    ['assign-document', applicationId],
    (data) => ApplicationService.assignDocumentToGroup(applicationId, documentId, data),
    options,
  );

export const useDownloadApplicationDocument = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, ApplicationDocumentDTO>,
) =>
  useMutation(
    'application-document-download',
    (document: ApplicationDocumentDTO) => ApplicationService.downloadApplicationDocument(applicationId, document),
    options,
  );

export const useUploadApplicationDocument = (
  applicationId: string,
  options?: UseMutationOptions<
    GeneratedUploadUrlResponseDTO,
    AxiosError,
    { type: ApplicationDocumentType; file: File }
  >,
) =>
  useMutation(
    'application-document-upload',
    (data: { type: ApplicationDocumentType; file: File }) =>
      ApplicationService.uploadApplicationDocument(applicationId, data.type, data.file),
    options,
  );

export const useCreateApplicationDocument = (
  applicationId: string,
  options?: UseMutationOptions<ApplicationDocumentDTO, AxiosError, CreateApplicationDocumentDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'create-application-document',
    (data: CreateApplicationDocumentDTO) => ApplicationService.createApplicationDocument(applicationId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application-documents', applicationId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useGenerateApplicationDocument = (
  applicationId: string,
  options?: UseMutationOptions<ApplicationDocumentDTO, AxiosError, GenerateApplicationDocumentDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'generate-application-document',
    (data: GenerateApplicationDocumentDTO) => ApplicationService.generateApplicationDocument(applicationId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application-documents', applicationId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeleteApplicationDocument = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'create-application-document',
    (documentId: string) => ApplicationService.deleteApplicationDocument(applicationId, documentId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application-documents', applicationId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useCreateApplicationProposal = (
  applicationId: string,
  options?: UseMutationOptions<ProposalDTO, AxiosError, string[]>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'application-proposal',
    (quoteIds: string[]) => ApplicationService.createApplicationProposal(applicationId, quoteIds),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application-proposals', applicationId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDownloadProposalPdf = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, { proposal: ProposalDTO; filename?: string; businessName: string }>,
) =>
  useMutation(
    'application-download-proposal-pdf',
    (data: { proposal: ProposalDTO; filename?: string; businessName: string }) =>
      ApplicationService.downloadProposalPdf(applicationId, data.businessName, data.proposal, data.filename),
    options,
  );

export const useDownloadProposalDocx = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, { proposal: ProposalDTO; filename?: string; businessName: string }>,
) =>
  useMutation(
    'application-download-proposal-docx',
    (data: { proposal: ProposalDTO; filename?: string; businessName: string }) =>
      ApplicationService.downloadProposalDocx(applicationId, data.businessName, data.proposal, data.filename),
    options,
  );

export const useApplicationQuotes = (id: string, options?: UseQueryOptions<QuoteDTO[]>) =>
  useQuery<QuoteDTO[]>(['application-quotes', id], async () => ApplicationService.getApplicationQuotes(id), options);

export const useApplicationDeclinations = (id: string, options?: UseQueryOptions<DeclinationDTO[]>) =>
  useQuery<DeclinationDTO[]>(
    ['application-declinations', id],
    async () => ApplicationService.getApplicationDeclinations(id),
    options,
  );

export const useApplicationPolicies = (id: string, options?: UseQueryOptions<PolicyDTO[]>) =>
  useQuery<PolicyDTO[]>(
    ['application-policies', id],
    async () => ApplicationService.getApplicationPolicies(id),
    options,
  );

export const useDownloadApplication = (id: string, options?: UseMutationOptions<void>) =>
  useMutation(
    ['application-pdf', id],
    async () => {
      const application = await ApplicationService.getApplicationById(id);
      const organizationName =
        application && application.applicationResponse && application.applicationResponse.organization_name;
      await ApplicationService.downloadApplication(id, organizationName);
    },
    options,
  );

export const useDuplicateApplication = (id: string, options?: UseMutationOptions<ApplicationDTO, AxiosError>) =>
  useMutation<ApplicationDTO, AxiosError>(
    ['application-duplicate', id],
    () => ApplicationService.duplicateApplication(id),
    options,
  );

export const useSubmitApplication = (
  id: string,
  options?: UseMutationOptions<ApplicationWithAgentDTO, AxiosError, boolean>,
) => {
  const queryClient = useQueryClient();

  return useMutation<ApplicationWithAgentDTO, AxiosError, boolean>(
    ['application-submit', id],
    (isValid: boolean) => ApplicationService.submitApplication(id, isValid),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);
        await queryClient.refetchQueries(['application-security-scan', data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }

        return data;
      },
    },
  );
};

export const useMarkApplicationSubmitted = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(['application-submitted', id], () => ApplicationService.markApplicationSubmitted(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);
      await queryClient.refetchQueries(['application-security-scan', data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useMarkApplicationNotSubmitted = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(['application-not-submitted', id], () => ApplicationService.markApplicationNotSubmitted(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);
      await queryClient.refetchQueries(['application-security-scan', data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useMarkApplicationArchivedBulk = (ids: string[], options?: UseMutationOptions<void, AxiosError, void>) => {
  const queryClient = useQueryClient();

  return useMutation(['applications-bulk-archived', ids], () => ApplicationService.markApplicationArchivedBulk(ids), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['applications']);
      await queryClient.refetchQueries(['applications-admin']);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useChangeApplicationUserBulk = (ids: string[], options?: UseMutationOptions<void, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['applications-bulk-reassigned', ids],
    (agentId: string) => ApplicationService.changeApplicationUserBulk(ids, agentId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['applications']);
        await queryClient.refetchQueries(['applications-admin']);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useMarkApplicationArchived = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(['application-archived', id], () => ApplicationService.markApplicationArchived(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);
      await queryClient.refetchQueries(['application-security-scan', data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useMarkApplicationNotArchived = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(['application-not-archived', id], () => ApplicationService.markApplicationNotArchived(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);
      await queryClient.refetchQueries(['application-security-scan', data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useMarkApplicationRequestForQuote = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['application-request-for-quote', id],
    () => ApplicationService.markApplicationRequestForQuote(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);
        await queryClient.refetchQueries(['application-security-scan', data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useMarkApplicationRequestForReport = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['application-request-for-report', id],
    () => ApplicationService.markApplicationRequestForReport(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useConvertToRiskAssessment = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['application-convert-risk-assessment', id],
    () => ApplicationService.convertToRiskAssessment(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useMarkApplicationRequestForConfiguration = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['application-request-for-configuration', id],
    () => ApplicationService.markApplicationRequestForConfiguration(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useMarkApplicationRescanAll = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(['application-rescan-all', id], () => ApplicationService.markApplicationRescanAll(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useMarkApplicationUninstallAll = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(['application-uninstall-all', id], () => ApplicationService.markApplicationUninstallAll(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useCreateApplications = (
  options?: UseMutationOptions<ApplicationDTO, AxiosError, CreateApplicationDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation('createApplication', (data) => ApplicationService.createApplication(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries('applications');
      await queryClient.refetchQueries('applications-admin');

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useEditApplication = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, Partial<ApplicationDTO>>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'edit-application',
    (data: Partial<ApplicationDTO>) => ApplicationService.editApplication(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);
        queryClient.refetchQueries(['application-security-scan', data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }

        return data;
      },
    },
  );
};

export const useClientEditApplication = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, Partial<ApplicationDTO>>,
) => {
  return useMutation(
    'client-edit-Application',
    (data: Partial<ApplicationDTO>) => ApplicationService.clientEditApplication(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }

        return data;
      },
    },
  );
};

export const useChangeApplicationStatus = (
  id: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, ApplicationStatus>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'change-application-status',
    (status: ApplicationStatus) => ApplicationService.forceChangeApplicationStatus(id, status),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const usePrefillApplication = (
  applicationId: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'prefillApplication',
    (dunes: string) => ApplicationService.prefillApplicationUsingDunes(applicationId, dunes),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useApplicationSkipDuns = (
  applicationId: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError>,
) => {
  const queryClient = useQueryClient();

  return useMutation('skip-application-duns', () => ApplicationService.skipDuns(applicationId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.id]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useUpdateApplicationSettings = (
  applicationId: string,
  options?: UseMutationOptions<ApplicationDTO, AxiosError, UpdateApplicationSettingDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation<ApplicationDTO, AxiosError, UpdateApplicationSettingDTO>(
    'update-application-settings',
    (data: UpdateApplicationSettingDTO) => ApplicationService.updateApplicationSettings(applicationId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['application', data.id]);
        await queryClient.refetchQueries(['application-breach-data', data.id]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useLatestActiveProposal = (id: string, options?: UseQueryOptions<ProposalDTO, AxiosError>) =>
  useQuery<ProposalDTO, AxiosError>(
    ['application-latest-active-proposal', id],
    async () => ApplicationService.getLatestActiveProposal(id),
    options,
  );

export const useChooseApplicationQuote = (id: string, options?: UseMutationOptions<PolicyDTO, AxiosError, string>) => {
  return useMutation((quoteId: string) => ApplicationService.chooseApplicationQuote(id, quoteId), options);
};

export const useCreateTarget = (
  id: string,
  securityScanId: string,
  options?: UseMutationOptions<TargetDTO, AxiosError, CreateTargetDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation('create-target', (data: CreateTargetDTO) => ApplicationService.createTarget(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', id]);
      await queryClient.refetchQueries(['application-security-scan', id, securityScanId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useSecurityApplicationVersion = (options?: UseQueryOptions<ApplicationVersionDTO>) =>
  useQuery<ApplicationVersionDTO>(
    'security-application-version',
    () => ApplicationService.getSecurityApplicationVersion(),
    options,
  );

export const useApplicationDnbResults = (id: string, options?: UseQueryOptions<ApplicationRawDnbDTO>) =>
  useQuery<ApplicationRawDnbDTO>(
    ['application-dnb-results', id],
    () => ApplicationService.getApplicationDnbResults(id),
    options,
  );

export const useApplicationBreachData = (id: string, options?: UseQueryOptions<ApplicationBreachDataDTO[]>) =>
  useQuery<ApplicationBreachDataDTO[]>(
    ['application-breach-data', id],
    () => ApplicationService.getApplicationBreachData(id),
    options,
  );

export const useFinancialRiskCalculatorApplicationVersion = (options?: UseQueryOptions<ApplicationVersionDTO>) =>
  useQuery<ApplicationVersionDTO>(
    'financial-risk-calculator-application-version',
    () => ApplicationService.getFinancialRiskCalculatorApplicationVersion(),
    {
      staleTime: 1000 * 5,
      ...options,
    },
  );

export const useUserSignupApplicationVersion = (options?: UseQueryOptions<ApplicationVersionDTO>) =>
  useQuery<ApplicationVersionDTO>(
    'user-signup-application-version',
    () => ApplicationService.getUserSignupApplicationVersion(),
    {
      staleTime: 1000 * 5,
      ...options,
    },
  );
