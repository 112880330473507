import { RiskLine, RiskLineDataPoint } from 'components/charts/RiskLine';
import { MonitoringSnapshotDTO } from 'dtos/application';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';

export const useMonitoringDarkWebPresenceChart = (
  enableCharts: boolean,
  data?: MonitoringSnapshotDTO[],
  selectedAccounts?: string[],
  classname?: string,
) => {
  const darkWebFindings = useMemo(() => {
    const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
      const message = snapshot.reportData.securityAwarenessTraining?.score ? 'Latest Scan' : 'Scan In Progress';
      const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
      acc[date] = acc[date] || snapshot;

      return acc;
    }, {});

    const snapshotsDates = sortBy(
      Object.keys(snapshotsByDate).map((date) => {
        return { date, createdAt: snapshotsByDate[date].createdAt };
      }),
      (snapshot) => snapshot.createdAt,
    ).map((d) => d.date);

    const summedByDate = sortBy(
      snapshotsDates.map((date, index) => {
        const prevDate = snapshotsDates[index - 1];

        const filteredAccounts =
          (selectedAccounts || []).length === 0
            ? Object.keys(snapshotsByDate[date].darkWebScanFindings)
            : selectedAccounts || [];

        const assetsData = filteredAccounts.map((s) => {
          return {
            name: s,
            value: snapshotsByDate[date].darkWebScanFindings[s] || 0,
            previous: (prevDate && snapshotsByDate[prevDate].darkWebScanFindings[s]) || 0,
          };
        });

        const darkWebFindings = assetsData.reduce((a, b) => a + b.value, 0);
        const darkWebFindingsPrev = prevDate
          ? Object.values(snapshotsByDate[prevDate].darkWebScanFindings).reduce((a, b) => a + b || 0, 0)
          : 0;

        return {
          date,
          darkWebFindings,
          darkWebFindingsPrev,
          assetsData,
          createdAt: snapshotsByDate[date].createdAt,
        };
      }),
      (snapshot) => snapshot.createdAt,
    );

    return summedByDate
      .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
        acc.push({
          name: snapshot.date,
          score: snapshot.darkWebFindings,
          distribution: ([] as any).concat(snapshot.assetsData, [
            { name: 'Total', value: snapshot.darkWebFindings, previous: snapshot.darkWebFindingsPrev },
          ]),
        });

        return acc;
      }, [])
      .slice(0, 365);
  }, [data, selectedAccounts]);

  const chart = useMemo(() => {
    return (
      <RiskLine
        locked={!enableCharts}
        id='monitoring-dark-web'
        className={classname}
        data={darkWebFindings}
        showTooltip
        // title='Critical Vulnerabilities'
      />
    );
  }, [classname, enableCharts, darkWebFindings]);

  return {
    chart,
    darkWebFindings,
  };
};
