import { CircularProgress, makeStyles, Theme, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';
import { Badge, BadgeVariant } from 'components/Badge';
import { SecurityGrade } from 'dtos/application';
import { SecurityFindingBySlugDTO, SecurityScanType, SecuritySeverity } from 'dtos/security-finding';
import moment from 'moment';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SingleScanInfo } from 'templates/SecurityAssessments/views/SecurityExternalScan';

import { ScanAccordion } from '../ScanAccordion';
import { ScannedSubdomainsModal } from '../ScannedSubdomainsModal';
import { SecurityFindings } from '../SecurityFindings';

interface StyleProps {
  tile?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    // '&:not(:last-child)': {
    borderBottom: `1px solid ${COLORS.GREY_5}`,
    // },

    ['@media print']: {
      pageBreakInside: 'avoid',
      display: 'block',
    },
  },

  rootNoBottomBorder: {
    ['@media print']: {
      pageBreakInside: 'avoid',
      display: 'block',
    },
  },

  error: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },

  bigGrade: {
    ...TYPOGRAPHY.TITLE_3,
    height: theme.spacing(6),
    minWidth: theme.spacing(6),
    width: theme.spacing(6),
    boxSizing: 'border-box',
  },

  bigGradeScanning: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: theme.spacing(0.75),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  tileExternalScan: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(0)}px 0 ${theme.spacing(1.5)}px`,
  },
  tileExternalScanNoBottomPadding: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(0)}px 0 ${theme.spacing(0)}px`,
  },
  findings: {
    color: COLORS.GREY_3,

    ['@media print']: {
      color: COLORS.GREY_1,
    },
  },
  findingsActive: {
    color: COLORS.BLUE_1,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },

    ['@media print']: {
      color: COLORS.GREY_1,
    },
  },
  infoIcon: {
    color: COLORS.GREY_4,
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  warningIcon: {
    color: COLORS.YELLOW_1,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
    verticalAlign: 'bottom',
  },

  itemTitleWrapper: {
    ['@media print']: {
      width: '100%',
    },
  },
  title: {
    ...TYPOGRAPHY.SMALL_BOLD,

    ['@media print']: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  description: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },
}));

export const getGradeBadgeVariant = (grade?: SecurityGrade | 'N/A'): BadgeVariant => {
  switch (grade) {
    case SecurityGrade.A:
      return 'green';
    case SecurityGrade.B:
      return 'yellow';
    case SecurityGrade.C:
      return 'red';
    case SecurityGrade.D:
      return 'darkRed';
    case SecurityGrade.E:
      return 'black';
    default:
      return 'grey';
  }
};

const IMPACT_MAP = [SecuritySeverity.LOW, SecuritySeverity.MEDIUM, SecuritySeverity.HIGH];

export const renderScanResults = (
  findings: SecurityFindingBySlugDTO[],
  getFindingUrl: (finding: SecurityFindingBySlugDTO) => string,
  showAll?: boolean,
  expanded?: boolean,
  previousFindings?: SecurityFindingBySlugDTO[],
) => {
  const groupedFindings = findings.reduce<{ [key in SecuritySeverity]: SecurityFindingBySlugDTO[] }>(
    (acc, finding) => {
      acc[finding.severity].push(finding);
      return acc;
    },
    {
      [SecuritySeverity.HIGH]: [],
      [SecuritySeverity.MEDIUM]: [],
      [SecuritySeverity.LOW]: [],
      [SecuritySeverity.INFO]: [],
    },
  );

  const groupedPreviousFindings = previousFindings?.reduce<{ [key in SecuritySeverity]: SecurityFindingBySlugDTO[] }>(
    (acc, finding) => {
      const severity = finding.severity || SecuritySeverity.LOW;

      acc[severity].push(finding);
      return acc;
    },
    {
      [SecuritySeverity.HIGH]: [],
      [SecuritySeverity.MEDIUM]: [],
      [SecuritySeverity.LOW]: [],
      [SecuritySeverity.INFO]: [],
    },
  );

  return IMPACT_MAP.map((severity, idx) => {
    const findingsInSeverity = groupedFindings[severity];
    const previousFindingsInSeverity = groupedPreviousFindings ? groupedPreviousFindings[severity] : [];
    const infoFindings = severity === SecuritySeverity.LOW ? groupedFindings[SecuritySeverity.INFO] : [];
    const prevInfoFindings = severity === SecuritySeverity.LOW ? groupedFindings[SecuritySeverity.INFO] : [];

    return (
      (findings.filter((f) => f.count > 0).length > 0 || showAll) && (
        <div key={idx}>
          <SecurityFindings
            impact={severity}
            findings={findingsInSeverity.filter((f) => f.count > 0 || showAll)}
            previousFindings={previousFindingsInSeverity?.filter((f) => f.count > 0 || showAll)}
            getFindingUrl={getFindingUrl}
          />
          {infoFindings.length > 0 && (
            <SecurityFindings
              impact={SecuritySeverity.INFO}
              findings={infoFindings.filter((f) => f.count > 0 || showAll)}
              previousFindings={prevInfoFindings?.filter((f) => f.count > 0 || showAll)}
              getFindingUrl={getFindingUrl}
            />
          )}
        </div>
      )
    );
  });
};

const renderScanGrade = (grade?: SecurityGrade, className?: string) => {
  return (
    <Badge typography={TYPOGRAPHY.LARGE} variant={getGradeBadgeVariant(grade)} className={className}>
      {grade || '-'}
    </Badge>
  );
};

export interface Props {
  tile?: boolean;
  grade?: SecurityGrade;
  findings?: SecurityFindingBySlugDTO[];
  isError: boolean;
  scanInfo: SingleScanInfo;
  onClick: (section: SecurityScanType) => void;
  getFindingUrl: (finding: SecurityFindingBySlugDTO) => string;
  isDefaultExpanded?: boolean;
  isLoading?: boolean;
  noBottomBorder?: boolean;
  scannedSubdomains?: string[];
  allSubdomains?: string[];
  previousScanAt?: Date;
  previousFindings?: SecurityFindingBySlugDTO[];
}

export const ExternalScanItem = ({
  tile,
  grade,
  findings,
  isError,
  scanInfo: { title, description, errorMessage, section },
  onClick,
  isDefaultExpanded,
  getFindingUrl,
  isLoading,
  noBottomBorder,
  scannedSubdomains,
  allSubdomains,
  previousScanAt,
  previousFindings,
}: Props) => {
  const classes = useStyles({ tile });
  const numberOfFindings = findings?.filter((f) => f.count > 0).length || 0;
  const prevNumberOfFindings = previousFindings?.length || 0;

  const tooltipError = `There was an error scanning ${title}. Showing results from previous scan on ${moment(
    previousScanAt,
  ).format('Do MMMM YYYY hh:mm:ss A')}`;

  const isSubDomainsLoading = allSubdomains && scannedSubdomains && scannedSubdomains.length < allSubdomains.length;

  if (isError) {
    return <p className={classes.error}>{errorMessage}</p>;
  }

  return (
    <div className={noBottomBorder ? classes.rootNoBottomBorder : classes.root}>
      {tile ? (
        <div className={noBottomBorder ? classes.tileExternalScanNoBottomPadding : classes.tileExternalScan}>
          {isLoading || isSubDomainsLoading ? (
            <CircularProgress thickness={4} size={32} className={classes.bigGradeScanning} />
          ) : (
            renderScanGrade(grade, classes.bigGrade)
          )}
          <div className={classes.itemTitleWrapper}>
            <div className={classes.title}>
              {title}{' '}
              {isLoading || (allSubdomains && allSubdomains.length > 0 && isSubDomainsLoading) ? (
                <span style={{ color: COLORS.GREY_2 }}>
                  (<ScannedSubdomainsModal scannedSubdomains={scannedSubdomains} allSubdomains={allSubdomains || []} />)
                </span>
              ) : (
                <span
                  className={cx(classes.findings, numberOfFindings > 0 && classes.findingsActive)}
                  onClick={numberOfFindings > 0 ? () => onClick(section) : undefined}
                  role='button'
                  tabIndex={0}
                >
                  ({numberOfFindings} {numberOfFindings === 1 ? 'finding' : 'findings'})
                </span>
              )}
              {isError && (
                <Tooltip title={tooltipError} aria-label={tooltipError || 'tooltip'}>
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              )}
            </div>
            <div className={classes.description}>{description}</div>
          </div>
        </div>
      ) : (
        <ScanAccordion
          title={
            <>
              {title}{' '}
              {isError && (
                <Tooltip title={tooltipError} aria-label={tooltipError || 'tooltip'}>
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              )}
            </>
          }
          description={description}
          isLoading={isLoading}
          scannedSubdomains={scannedSubdomains}
          allSubdomains={allSubdomains}
          grade={
            isLoading || isSubDomainsLoading ? (
              <CircularProgress thickness={4} size={32} className={classes.bigGradeScanning} />
            ) : (
              renderScanGrade(grade, classes.bigGrade)
            )
          }
          numberOfFindings={numberOfFindings}
          prevNumberOfFindings={prevNumberOfFindings}
          isDefaultExpanded={isDefaultExpanded}
          onClick={() => onClick(section)}
        >
          <div className={cx(!tile && classes.scanResultsWrapper)}>
            {renderScanResults(findings || [], getFindingUrl, !tile, !tile, previousFindings)}
          </div>
        </ScanAccordion>
      )}
    </div>
  );
};
