import { makeStyles, useTheme } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Modal } from 'components/Modal';
import { SecurityGrade } from 'dtos/application';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';

import { SecurityCard } from '../../components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  root: {},
  securityCardContent: {
    position: 'relative',
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    flex: 1,
    position: 'relative',
  },
  score: {
    ...TYPOGRAPHY.TITLE,
    color: COLORS.GREY_2,
  },
  title: {
    ...TYPOGRAPHY.LARGE,
    fontWeight: 500,
    lineHeight: '1.1',
    color: COLORS.GREY_2,
    marginTop: theme.spacing(0.75),
  },

  modalButtonIcon: {
    fontSize: 14,
    color: COLORS.GREY_2,
  },
}));

interface Props {
  score: number;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  infoTitle?: string;
  infoContent?: string | React.ReactNode;
  skipGrading?: boolean;
  className?: string;
}

export const ScoreSecurityCard = ({
  score,
  title,
  description,
  infoTitle,
  infoContent,
  skipGrading,
  className,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <SecurityCard.Container className={className}>
      <SecurityCard.Content
        gap={theme.spacing()}
        padding={`${theme.spacing()}px ${theme.spacing(3)}px`}
        className={classes.securityCardContent}
      >
        <div className={classes.innerWrapper}>
          <div
            className={classes.score}
            style={{ color: skipGrading ? COLORS.GREY_2 : mapGradeToColor(parseScoreToGrade(score)) }}
          >
            {skipGrading ? score : parseScoreToGrade(score)}
          </div>
          <div className={classes.title}>{title}</div>
        </div>
        <div>{description}</div>
        <span style={{ position: 'absolute', top: theme.spacing(0.5), right: theme.spacing() }}>
          {infoContent && infoTitle ? (
            <Modal
              removePaddingTop
              title={infoTitle}
              openButton={
                <span>
                  <InfoRoundedIcon className={classes.modalButtonIcon} />
                </span>
              }
            >
              {infoContent}
            </Modal>
          ) : null}
        </span>
      </SecurityCard.Content>
    </SecurityCard.Container>
  );
};

const mapGradeToColor = (grade: SecurityGrade | 'N/A') => {
  switch (grade) {
    case SecurityGrade.A:
      return COLORS.GREEN_1;
    case SecurityGrade.B:
      return COLORS.BLUE_1;
    case SecurityGrade.C:
      return COLORS.YELLOW_1;
    case SecurityGrade.D:
      return COLORS.RED_1;
    case SecurityGrade.E:
      return `${COLORS.BLUE_3}99`;
    default:
      return COLORS.GREY_2;
  }
};
