import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentAgent } from 'queries/useAgent';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { clearAuthTokens, setAdminAuthToken } from 'services/auth0-tokens';
import { ROUTES } from 'telivy-constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100vh',
  },
  box: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  button: {
    maxWidth: 220,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
  },
}));

export const AdminView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [secret, setSecret] = useState('');
  const [error, setError] = useState('');
  const [userSub, setUserSub] = useState('');
  const { refetch } = useCurrentAgent({
    enabled: false,
    retry: false,
    onSuccess: () => {
      setError('');
      history.push(ROUTES.agent.ROOT);
    },
    onError: () => {
      setError('Incorrect data.');
      clearAuthTokens(true);
    },
  });

  const handleSubmit = useCallback(() => {
    setError('');
    setAdminAuthToken(secret, userSub);
    refetch();
  }, [refetch, secret, userSub]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Box m={1} className={classes.box}>
        <Typography variant='h5'>Admin login</Typography>
        <TextField
          label='Secret'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSecret(e.target.value);
          }}
        />
        <TextField
          label='User sub'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUserSub(e.target.value);
          }}
        />
        <Button className={classes.button} color='primary' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
        {error && (
          <Typography variant='caption' color='error' style={{ marginTop: 8 }}>
            {error}
          </Typography>
        )}
      </Box>
    </div>
  );
};
