import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { useWindowSize } from 'hooks/useWindowSize';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { COLORS } from 'telivy-theme';

import { QUOTE_CARD_WIDTH } from '../QuoteCard';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: COLORS.WHITE,

    '& .swiper-wrapper': {
      padding: `${theme.spacing(2)}px 0`,
    },

    '& .swiper-slide': {
      width: 'auto',
      height: 'auto',
      position: 'initial',
      flexShrink: 'initial',
      cursor: 'grab',
    },
  },

  quotesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

interface Props {
  quotesLength: number;
  children: Array<React.ReactElement> | React.ReactElement;
  className?: string;
}

export const QuotesSwiper = ({ quotesLength, children, className }: Props) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const isSwiperEnabled: boolean = quotesLength * QUOTE_CARD_WIDTH >= width - 48;

  if (isSwiperEnabled) {
    return (
      <Swiper
        slideToClickedSlide={true}
        initialSlide={1}
        slidesPerView={'auto'}
        centeredSlides
        className={cx(classes.root, className)}
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide>{React.cloneElement(child)}</SwiperSlide>
        ))}
      </Swiper>
    );
  }

  return (
    <div className={cx(classes.quotesContainer, className)} key='quotes'>
      {children}
    </div>
  );
};
