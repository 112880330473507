import { Hidden, HiddenProps } from '@material-ui/core';
import React from 'react';

export const HiddenBr = (props: HiddenProps) => {
  return (
    <Hidden {...props}>
      <br />
    </Hidden>
  );
};
