import { Money } from 'components/Money';
import { QuoteDTO } from 'dtos/quotes';
import React from 'react';
import { QUOTE_SUBLIMITS_MAP } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ProposalInfoModal } from 'views/web-proposal/ProposalInfoModal';

interface QuoteSublimit {
  key: keyof typeof QUOTE_SUBLIMITS_MAP;
  title: string;
  label: React.ReactNode;
  value: React.ReactNode;
}

interface Options {
  iconRight?: boolean;
  iconColor?: ValueOf<typeof COLORS>;
  moneyTypography?: ValueOf<typeof TYPOGRAPHY>;
}

export const useQuoteSublimits = (quote: QuoteDTO, options?: Options) => {
  const lists: QuoteSublimit[] = Object.keys(QUOTE_SUBLIMITS_MAP).map((sublimit) => {
    const { sublimits } = quote;

    return {
      key: sublimit as keyof typeof QUOTE_SUBLIMITS_MAP,
      title: QUOTE_SUBLIMITS_MAP[sublimit as keyof typeof QUOTE_SUBLIMITS_MAP]?.title || '',
      label: (
        <ProposalInfoModal
          iconRight={options?.iconRight}
          iconColor={options?.iconColor}
          name={sublimit as keyof typeof QUOTE_SUBLIMITS_MAP}
        />
      ),
      value: (
        <Money
          value={sublimits ? sublimits[sublimit as keyof typeof QUOTE_SUBLIMITS_MAP] || 0 : 0}
          typography={options?.moneyTypography}
        />
      ),
    };
  });

  return {
    lists,
  };
};
