import { Hidden, makeStyles } from '@material-ui/core';
import { AgentLogo } from 'components/AgentLogo';
import { AttestationBreadcrumbs } from 'components/AttestationBreadcrumbs';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { useClientApplicationActivePolicy } from 'queries/useApplications';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { COLORS } from 'telivy-theme';

import { BillingSection } from './sections/BillingSection';
import { SignDocumentsSection } from './sections/SignDocumentsSection';
import { ThankYouSection } from './sections/ThankYouSection';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: COLORS.WHITE,
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: 0,
    padding: `0 ${theme.spacing(7.5)}px`,
    height: 60,
  },
  agentLogo: {
    position: 'absolute',
  },
}));

interface ParamsType {
  id: string;
}

export const ApplicationActivePolicyView = () => {
  const classes = useStyles();
  const [activeItemIdx, setActiveItemIdx] = useState(3);
  const { id } = useParams<ParamsType>();

  const { data, isLoading, isError } = useClientApplicationActivePolicy(id, {
    onSuccess: (policy) => {
      if (policy && policy.attestationDone) {
        if (policy.application.billingEnabled && !policy.billingDone) {
          setActiveItemIdx(4);
        } else if (policy.attestationDone && policy.billingDone) {
          setActiveItemIdx(5);
        } else {
          setActiveItemIdx(4);
        }
      } else {
        setActiveItemIdx(3);
      }
    },
  });

  if (isLoading) {
    return <LoadingContainer fullScreen />;
  }

  if (isError || !data) {
    return <NotFoundContainer fullScreen />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Application Policy</title>
      </Helmet>

      <Hidden smDown xsUp={data && data.attestationDone && data.application.billingEnabled && data.billingDone}>
        <nav className={classes.nav}>
          <AgentLogo applicationId={id} logoSize={30} className={classes.agentLogo} />
          <AttestationBreadcrumbs activeItemIdx={activeItemIdx} applicationId={id} />
        </nav>
      </Hidden>

      {data &&
        (data.attestationDone ? (
          data.application.billingEnabled && !data.billingDone ? (
            <BillingSection policy={data} />
          ) : (
            <ThankYouSection policy={data} />
          )
        ) : (
          <SignDocumentsSection policy={data} />
        ))}
    </div>
  );
};
