import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosError } from 'axios';
import { Modal } from 'components/Modal';
import { PolicyDTO } from 'dtos/policy';
import { ProposalWithApplicationDTO } from 'dtos/proposal';
import { QuoteDTO } from 'dtos/quotes';
import { useChooseApplicationQuote } from 'queries/useApplications';
import { useChooseProposalQuote } from 'queries/useProposal';
import React, { useCallback, useState } from 'react';
import { UseMutationOptions, UseMutationResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(50),
    },
  },
}));

interface Props {
  applicationId: string;
  proposal?: ProposalWithApplicationDTO;
  selectedQuote: QuoteDTO;
  className?: string;
}

export const EnrollButton = ({ applicationId, proposal, selectedQuote, className }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isDeclined, setIsDeclined] = useState(false);

  const fnHook: (
    id: string,
    options: UseMutationOptions<PolicyDTO, AxiosError, string>,
  ) => UseMutationResult<PolicyDTO, AxiosError, string> = proposal?.id
    ? useChooseProposalQuote
    : useChooseApplicationQuote;

  const { mutate, isLoading: isChoosing } = fnHook(proposal?.id || applicationId, {
    onSuccess: (policy) => {
      policy && history.push(insertIdToRoute(ROUTES.application.POLICY, policy.applicationId));
    },
  });

  const handleChooseQuote = useCallback(
    (quote: QuoteDTO) => {
      mutate(quote.id);
    },
    [mutate],
  );

  return (
    <Modal
      openButton={
        <Button variant='contained' color='primary' className={className} data-cy='review-enroll-button'>
          Enroll
        </Button>
      }
      title='Confirm effective date'
      removePaddingRight
      padding={4}
      onClose={() => setIsDeclined(false)}
      actions={
        !isDeclined ? (
          <>
            <Button onClick={() => setIsDeclined(true)} disabled={isChoosing}>
              Decline
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={isChoosing}
              onClick={selectedQuote ? () => handleChooseQuote(selectedQuote) : undefined}
              data-cy='review-confirm-enroll-button'
            >
              {isChoosing ? 'Confirming' : 'Confirm'}
            </Button>
          </>
        ) : null
      }
    >
      <div className={classes.contentWrapper}>
        {!isDeclined ? (
          <>
            Please confirm your effective date ({selectedQuote?.effectiveDate}). If it is incorrect, please reach out to
            your agent for the next steps
          </>
        ) : (
          <>Reach out to the agent, if you want to change the effective date.</>
        )}
      </div>
    </Modal>
  );
};
