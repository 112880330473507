import { Button, CircularProgress } from '@material-ui/core';
import { useToasters } from 'hooks/useToasters';
import { useDuplicateApplication } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

interface Props {
  applicationId: string;
  className?: string;
}

export const DuplicateApplicationButton: React.FC<Props> = ({ applicationId, className }) => {
  const history = useHistory();
  const { showToaster } = useToasters();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useDuplicateApplication(applicationId, {
    onSuccess: (data) => {
      setIsLoading(false);
      showToaster(`Application duplicated for renewal quoting.`);
      history.push(insertIdToRoute(ROUTES.agent.application.FORM, data.id));
    },
  });

  const handleDuplicate = useCallback(() => {
    setIsLoading(true);
    mutate();
  }, [mutate]);

  return (
    <>
      <Button className={className} onClick={handleDuplicate}>
        {isLoading ? <CircularProgress size={20} /> : 'Renew'}
      </Button>
    </>
  );
};
