import axios, { AxiosError } from 'axios';
import {
  ApplicationsRiskReport,
  BrowserPasswordDTO,
  BrowserPasswordStatsDTO,
  DataRiskDistributionDTO,
  DeepScanDTO,
  // DeepScanM365SecurityData,
  // DeepScanM365SecurityDataValue,
  DeepScanNetworkHostDTO,
  DeepScanNetworkHostNmapOutputDTO,
  DeepScanTargetDTO,
  DeepScanTargetLinkedUserDTO,
  DeepScanTargetPiiSummaryDTO,
  DetailedNodewareAsset,
  NmapVulnerabilityData,
  NodewareAsset,
  ParsedReports,
  PiiFileDTO,
  PiiQueryDTO,
  ReportData,
  RiskAnalysisItem,
  TopNodewareVulnerability,
  UpdateDeepScanDTO,
} from 'dtos/deep-scan';
import fileDownload from 'js-file-download';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { DeepScanService } from 'services/DeepScan';
import { INSTALL_AGENTS_EXE_URL, SCAN_BROWSER_PASSWORDS_EXE_URL } from 'telivy-constants';

export const useDeepScan = (id: string, options?: UseQueryOptions<DeepScanDTO, AxiosError>) =>
  useQuery<DeepScanDTO, AxiosError>(['deep-scan', id], () => DeepScanService.getDeepScan(id), options);

export const useDeepScanNodewareTop5Vulnerability = (
  id: string,
  options?: UseQueryOptions<TopNodewareVulnerability[]>,
) =>
  useQuery<TopNodewareVulnerability[]>(
    ['deep-scan-top', id],
    () => DeepScanService.getDeepScanNodewareTop5Vulnerabilities(id),
    options,
  );

export const useDeepScanParsedReports = (id: string, options?: UseQueryOptions<ParsedReports>) =>
  useQuery<ParsedReports>(
    ['deep-scan-parsed-reports', id],
    () => DeepScanService.getDeepScanParsedReports(id),
    options,
  );

export const useDeepScanApplicationRiskUser = (id: string, options?: UseQueryOptions<ApplicationsRiskReport[]>) =>
  useQuery<ApplicationsRiskReport[]>(
    ['deep-scan-application-risk-report', id],
    () => DeepScanService.getDeepScanApplicationRiskUser(id),
    options,
  );

export const useDeepScanDataRiskDistribution = (id: string, options?: UseQueryOptions<DataRiskDistributionDTO[]>) =>
  useQuery<DataRiskDistributionDTO[]>(
    ['deep-scan-data-risk-distribution', id],
    () => DeepScanService.getDeepScanDataRiskDistribution(id),
    options,
  );

export const useDeepScanReportData = (id: string, options?: UseQueryOptions<ReportData>) =>
  useQuery<ReportData>(['deep-scan-report-data', id], () => DeepScanService.getDeepScanReportData(id), options);

export const useDeepScanRiskAnalysis = (id: string, options?: UseQueryOptions<RiskAnalysisItem[]>) =>
  useQuery<RiskAnalysisItem[]>(
    ['deep-scan-risk-analysis', id],
    () => DeepScanService.getDeepScanRiskAnalysis(id),
    options,
  );

export const useDeepScanNodeware = (id: string, options?: UseQueryOptions<NodewareAsset[]>) =>
  useQuery<NodewareAsset[]>(['deep-scan-nodeware', id], () => DeepScanService.getDeepScanNodewareAssets(id), options);

export const useDeepScanNodewareAssetDetails = (
  deepScanId: string,
  nodewareAssetId: string,
  options?: UseQueryOptions<DetailedNodewareAsset>,
) =>
  useQuery<DetailedNodewareAsset>(
    ['deep-scan-nodeware-asset', deepScanId, nodewareAssetId],
    () => DeepScanService.getDeepScanNodewareAssetDetails(deepScanId, nodewareAssetId),
    options,
  );

export const useDeepScanTargetNmapData = (
  deepScanId: string,
  deepScanTargetId: string,
  options?: UseQueryOptions<NmapVulnerabilityData[]>,
) =>
  useQuery<NmapVulnerabilityData[]>(
    ['deep-scan-target-nmap-data', deepScanId, deepScanTargetId],
    () => DeepScanService.getDeepScanTargetNmapData(deepScanId, deepScanTargetId),
    options,
  );

export const useDeepScanDataRiskMatches = (
  deepScanId: string,
  data: PiiQueryDTO,
  options?: UseQueryOptions<{ data: PiiFileDTO[]; count: number }>,
) =>
  useQuery<{ data: PiiFileDTO[]; count: number }>(
    ['deep-scan-pii-file', deepScanId, data],
    () => DeepScanService.getDeepScanDataRiskMatches(deepScanId, data),
    options,
  );

export const useDeepScanNetworkHostDetails = (
  deepScanId: string,
  networkHostId: string,
  options?: UseQueryOptions<DeepScanNetworkHostNmapOutputDTO>,
) =>
  useQuery<DeepScanNetworkHostNmapOutputDTO>(
    ['deep-scan-network-host-nmap-output', deepScanId, networkHostId],
    () => DeepScanService.getDeepScanNetworkHostDetails(deepScanId, networkHostId),
    options,
  );

export const useDeepScanTargets = (id: string, options?: UseQueryOptions<DeepScanTargetDTO[]>) =>
  useQuery<DeepScanTargetDTO[]>(['deep-scan-targets', id], () => DeepScanService.getDeepScanTargets(id), options);

export const useDeepScanTargetsNmap = (id: string, options?: UseQueryOptions<DeepScanTargetDTO[]>) =>
  useQuery<DeepScanTargetDTO[]>(
    ['deep-scan-targets-nmap', id],
    () => DeepScanService.getDeepScanTargetsNmap(id),
    options,
  );

export const useDeepScanNetworkHosts = (id: string, options?: UseQueryOptions<DeepScanNetworkHostDTO[]>) =>
  useQuery<DeepScanNetworkHostDTO[]>(
    ['deep-scan-network-hosts', id],
    () => DeepScanService.getDeepScanNetworkHosts(id),
    options,
  );

export const useDeepScanTargetsPiiSummary = (id: string, options?: UseQueryOptions<DeepScanTargetPiiSummaryDTO[]>) =>
  useQuery<DeepScanTargetPiiSummaryDTO[]>(
    ['deep-scan-targets-pii-summary', id],
    () => DeepScanService.getDeepScanTargetsPiiSummary(id),
    options,
  );

export const useDeepScanM365SecurityData = <T>(id: string, dataKey: string, options?: UseQueryOptions<T>) =>
  useQuery<T>(
    [`deep-scan-m365-security-data-${dataKey}`, id],
    () => DeepScanService.getDeepScanM365SecurityData<T>(id, dataKey),
    options,
  );

export const useDeepScanGoogleWsSecurityData = <T>(id: string, dataKey: string, options?: UseQueryOptions<T>) =>
  useQuery<T>(
    [`deep-scan-gws-security-data-${dataKey}`, id],
    () => DeepScanService.getDeepScanGoogleWsSecurityData<T>(id, dataKey),
    options,
  );

export const useDeepScanBrowserPasswords = (id: string, options?: UseQueryOptions<BrowserPasswordDTO[]>) =>
  useQuery<BrowserPasswordDTO[]>(
    ['deep-scan-browser-passwords', id],
    () => DeepScanService.getBrowserPasswords(id),
    options,
  );

export const useDeepScanCommonBrowserPasswords = (id: string, options?: UseQueryOptions<BrowserPasswordStatsDTO[]>) =>
  useQuery<BrowserPasswordStatsDTO[]>(
    ['deep-scan-common-browser-passwords', id],
    () => DeepScanService.getCommonBrowserPasswords(id),
    options,
  );

export const useDeepScanDarkWebBrowserPasswords = (id: string, options?: UseQueryOptions<BrowserPasswordStatsDTO[]>) =>
  useQuery<BrowserPasswordStatsDTO[]>(
    ['deep-scan-dark-web-browser-passwords', id],
    () => DeepScanService.getDarkWebBrowserPasswords(id),
    options,
  );

export const useDeepScanAzureAuthLink = (id: string, options?: UseQueryOptions<string>) =>
  useQuery<string>(['deep-scan-azure-auth-link', id], () => DeepScanService.getDeepScanAzureAuthLink(id), options);

export const useDeepScanGoogleWsAuthLink = (id: string, options?: UseQueryOptions<string>) =>
  useQuery<string>(
    ['deep-scan-google-ws-auth-link', id],
    () => DeepScanService.getDeepScanGoogleWsAuthLink(id),
    options,
  );

export const useUpdateDeepScan = (
  id: string,
  applicationId?: string,
  options?: UseMutationOptions<DeepScanDTO, AxiosError, UpdateDeepScanDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['deep-scan-update', id, applicationId],
    (data: UpdateDeepScanDTO) => DeepScanService.updateDeepScan(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['deep-scan', id]);
        await queryClient.refetchQueries(['deep-scan-parsed-reports', id]);
        await queryClient.refetchQueries(['deep-scan-data-risk-distribution', id]);
        if (applicationId) await queryClient.refetchQueries(['application-security-charts-urls', applicationId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useTogglePiiFileFalsePositive = (
  id: string,
  options?: UseMutationOptions<void, AxiosError, PiiFileDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['deep-scan-pii-file-toggle-false-positive', id],
    (piiFile: PiiFileDTO) => DeepScanService.markPiiFileFalsePositive(id, piiFile.id, !piiFile.falsePositive),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['deep-scan-pii-file', id]);
        await queryClient.refetchQueries(['deep-scan-targets-pii-summary', id]);
        await queryClient.refetchQueries(['deep-scan-parsed-reports', id]);
        await queryClient.refetchQueries(['deep-scan-data-risk-distribution', id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useWindowsRmmScriptDownload = (options?: UseMutationOptions<string, AxiosError, string>) => {
  return useMutation(
    ['download-rmm-script'],
    (nijaOneLink: string) =>
      axios.get('/telivy-download-install-msi.ps1').then((res) => res.data.replace('DOWNLOAD_URL', nijaOneLink)),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await fileDownload(data, 'telivy-download-install-msi.ps1');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

interface Keys {
  deepScanId: string;
}

export const useGenerateDocxReport = (
  deepScanId: string,
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation('application-generate-docx-report', () => DeepScanService.generateDocxReport(deepScanId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application-documents', applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useGeneratePiiScanReport = (
  deepScanId: string,
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation('application-generate-pii-scan-report', () => DeepScanService.generatePiiScanReport(deepScanId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application-documents', applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useGeneratePiiExcelBundle = (
  deepScanId: string,
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation('application-generate-docx-report', () => DeepScanService.generatePiiExcelBundle(deepScanId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application-documents', applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useUpdateDeepScanTargetLinkedUsers = (
  deepScanId: string,
  deepScanTargetId: string,
  options?: UseMutationOptions<DeepScanTargetDTO, AxiosError, DeepScanTargetLinkedUserDTO[]>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'update-deep-scan-target-linked-users',
    (linkedUsers: DeepScanTargetLinkedUserDTO[]) =>
      DeepScanService.updateDeepScanTargetLinkedUsers(deepScanId, deepScanTargetId, linkedUsers),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['deep-scan-risk-analysis', deepScanId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useUninstallDeepScanTarget = (
  deepScanId: string,
  deepScanTargetId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'uninstall-deep-scan-target',
    () => DeepScanService.uninstallDeepScanTarget(deepScanId, deepScanTargetId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['deep-scan-targets', deepScanId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeepScanRefetchM365 = (deepScanId: string, options?: UseMutationOptions<void, AxiosError, void>) => {
  const queryClient = useQueryClient();

  return useMutation('refetch-m365-deep-scan', () => DeepScanService.refetchM365DeepScan(deepScanId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['deep-scan', deepScanId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useDeepScanRefetchGws = (deepScanId: string, options?: UseMutationOptions<void, AxiosError, void>) => {
  const queryClient = useQueryClient();

  return useMutation('refetch-gws-deep-scan', () => DeepScanService.refetchGwsDeepScan(deepScanId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['deep-scan', deepScanId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useRescanDeepScanTarget = (
  deepScanId: string,
  deepScanTargetId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'rescan-deep-scan-target',
    () => DeepScanService.rescanDeepScanTarget(deepScanId, deepScanTargetId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['deep-scan-targets', deepScanId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useRemoveDeepScanTarget = (
  deepScanId: string,
  deepScanTargetId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'remove-deep-scan-target',
    () => DeepScanService.removeDeepScanTarget(deepScanId, deepScanTargetId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['deep-scan-targets', deepScanId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useGenerateBrowsersPasswordReport = (
  deepScanId: string,
  options?: UseMutationOptions<void, AxiosError, string>,
) =>
  useMutation(
    'application-browsers-passwords-report',
    (fileName) => DeepScanService.generateBrowserPasswordsReport(deepScanId, fileName),
    options,
  );

export const useScheduleReportsGeneration = (
  deepScanId: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => useMutation('schedule-reports-generation', () => DeepScanService.scheduleReportsGeneration(deepScanId), options);

export const useGenerateApplicationSecurityReport = (
  deepScanId: string,
  options?: UseMutationOptions<void, AxiosError, string>,
) =>
  useMutation(
    'generate-application-security-report',
    (fileName) => DeepScanService.generateApplicationSecurityReport(deepScanId, fileName),
    options,
  );

export const useDownloadInstallAgentsScript = (options?: UseMutationOptions<string, AxiosError, Keys>) => {
  return useMutation(
    ['download-agents-installs-script'],
    (keys: Keys) =>
      axios
        .get('/install-agents-rmm.ps1')
        .then((res) =>
          res.data.replace('DEEP_SCAN_ID', keys.deepScanId).replace('INSTALL_AGENTS_EXE_URL', INSTALL_AGENTS_EXE_URL),
        ),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await fileDownload(data, 'install-agents-rmm.ps1');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDownloadScanBrowserPasswordsScript = (options?: UseMutationOptions<string, AxiosError, Keys>) => {
  return useMutation(
    ['download-scan-browser-passwords-script'],
    (keys: Keys) =>
      axios
        .get('/scan-browser-passwords-rmm.ps1')
        .then((res) =>
          res.data
            .replace('DEEP_SCAN_ID', keys.deepScanId)
            .replace('SCAN_BROWSER_PASSWORDS_EXE_URL', SCAN_BROWSER_PASSWORDS_EXE_URL),
        ),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await fileDownload(data, 'scan-browser-passwords-rmm.ps1');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const usePhishingCsv = (id: string, options?: UseMutationOptions<string, AxiosError, File>) => {
  const queryClient = useQueryClient();

  return useMutation('upload-phishing-csv', (data: File) => DeepScanService.uploadDomainsCsv(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['deep-scan', id]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
