import { LinkButton } from 'components/LinkButton';
import { QuoteDocumentDTO, QuoteDTO } from 'dtos/quotes';
import { useDownloadQuoteDocument } from 'queries/useQuote';
import React, { useCallback } from 'react';
import { COLORS } from 'telivy-theme';

interface Props {
  quote: QuoteDTO;
  document: QuoteDocumentDTO;
  label: string;
  color?: ValueOf<typeof COLORS>;
  className?: string;
}

export const DownloadQuoteDocument = ({ document, label, quote, color = COLORS.WHITE, className }: Props) => {
  const { mutate, isLoading } = useDownloadQuoteDocument(quote.id);

  const handleClick = useCallback(() => {
    mutate(document);
  }, [document, mutate]);

  return (
    <LinkButton className={className} disabled={isLoading} onClick={handleClick} color={color}>
      {isLoading ? 'Downloading...' : label}
    </LinkButton>
  );
};
