import { RiskLine, RiskLineDataPoint } from 'components/charts/RiskLine';
import { MonitoringSnapshotDTO } from 'dtos/application';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';

export const useMonitoringApplicationSecurityChart = (
  enableCharts: boolean,
  data?: MonitoringSnapshotDTO[],
  classname?: string,
) => {
  const riskyApps = useMemo(() => {
    const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
      const finished = Object.keys(snapshot.scanStatus || {}).every(
        (h) =>
          snapshot.scanStatus[h].telivyAgentInstalledAt || snapshot.scanStatus[h].system.scan_applications_total_found,
      );
      const message = finished ? 'Latest Scan' : 'Scan In Progress';
      const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
      acc[date] = acc[date] || snapshot;

      return acc;
    }, {});

    const snapshotsDates = sortBy(
      Object.keys(snapshotsByDate).map((date) => {
        return { date, createdAt: snapshotsByDate[date].createdAt };
      }),
      (snapshot) => snapshot.createdAt,
    ).map((d) => d.date);

    const summedByDate = sortBy(
      snapshotsDates.map((date, index) => {
        const prevDate = snapshotsDates[index - 1];

        const riskyApps = snapshotsByDate[date].appsScanStats.riskyApps?.high || 0;
        const riskyAppsPrev = prevDate ? snapshotsByDate[prevDate].appsScanStats.riskyApps?.high || 0 : 0;

        return {
          date,
          riskyApps,
          riskyAppsPrev,
          createdAt: snapshotsByDate[date].createdAt,
        };
      }),
      (snapshot) => snapshot.createdAt,
    );

    return summedByDate
      .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
        acc.push({
          name: snapshot.date,
          score: snapshot.riskyApps,
          distribution: [{ name: 'Total', value: snapshot.riskyApps, previous: snapshot.riskyAppsPrev }],
        });

        return acc;
      }, [])
      .slice(0, 30);
  }, [data]);

  const chart = useMemo(() => {
    return (
      <RiskLine
        locked={!enableCharts}
        id='monitoring-application-security'
        className={classname}
        data={riskyApps}
        showTooltip
        // title='Critical Vulnerabilities'
      />
    );
  }, [classname, enableCharts, riskyApps]);

  return {
    chart,
    riskyApps,
  };
};
