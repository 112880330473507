import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { Placeholder } from 'components/Placeholder';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

export function formatNumberWithCommas(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatMillionsValue(value: number) {
  const floatMillions = value / 1000000;

  return `${parseFloat(floatMillions.toFixed(1))}M`;
}

interface StylesProps {
  typography?: ValueOf<typeof TYPOGRAPHY>;
  color?: ValueOf<typeof COLORS>;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: (props) => {
    return {
      ...(props.typography || TYPOGRAPHY.MONO_MEDIUM_SMALL),
      color: props.color || COLORS.GREY_1,
    };
  },
  bold: (props) => {
    return {
      ...(props.typography || TYPOGRAPHY.MONO_BOLD_SMALL),
    };
  },

  placeholder: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface Props extends StylesProps {
  value: number;
  bold?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const Money = ({ value, bold, className, typography, isLoading, color }: Props) => {
  const classes = useStyles({ typography, color });
  const parsedValue = value < 1000000 ? formatNumberWithCommas(value) : formatMillionsValue(value);
  const combinedClasses = cx(classes.root, className, bold && classes.bold);

  const loading = Boolean(isLoading);

  return (
    <span className={combinedClasses}>
      $
      <Placeholder isActive={loading} inline className={cx(loading && classes.placeholder)}>
        <>{parsedValue}</>
      </Placeholder>
    </span>
  );
};
