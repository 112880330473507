import { Hidden, makeStyles } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import cx from 'classnames';
import { LinkButton } from 'components/LinkButton';
import { Modal } from 'components/Modal';
import { ApplicationStatus } from 'dtos/application';
import { DeclinationDTO } from 'dtos/declinations';
import { ProposalWithApplicationDTO } from 'dtos/proposal';
import { QuoteDTO } from 'dtos/quotes';
import { ReactComponent as ArrowDropdownRounded } from 'images/arrow-drop-down-rounded.svg';
import { sortBy, uniq } from 'lodash';
import { useApplication, useClientApplicationActivePolicy } from 'queries/useApplications';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ChangeAggLimitSelect } from './ChangeAggLimitSelect';
import { DownloadProposal } from './DownloadProposal';
import { QuoteCard } from './QuoteCard';
import { QuotesSwiper } from './QuotesSwiper';
import { RejectedQuotes } from './RejectedQuotes';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(10),
    boxSizing: 'border-box',
  },
  heading: {
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
  },
  quoteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  moreDetails: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    marginTop: theme.spacing(1.5),
    color: COLORS.GREY_1,
    textDecoration: 'none',
  },

  dropdownArrow: {
    transition: 'all 0.3s',
  },
  arrowUp: {
    transform: 'rotate(180deg)',
  },

  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingTop: 0,
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  downloadButton: {
    padding: '7px 8px',
  },
}));

interface Props {
  proposal?: ProposalWithApplicationDTO;
  quotes?: QuoteDTO[];
  handleSelectQuote: (quote: QuoteDTO) => void;
  applicationId: string;
  declinations?: DeclinationDTO[];
  isLoadingDeclinations?: boolean;
}

export const ChooseQuoteSection = ({
  proposal,
  declinations,
  isLoadingDeclinations,
  quotes,
  handleSelectQuote,
  applicationId,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  // list of all agg limits
  const availableAggLimits = sortBy(uniq(quotes?.map((quote) => quote.aggregateLimit)));

  const { data: applicationData } = useApplication(applicationId);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedAggLimit, setSelectedAggLimit] = useState<number>(availableAggLimits[0]);

  // quotes manipulation
  const quotesList = (!proposal ? quotes?.filter((quote) => quote.aggregateLimit === selectedAggLimit) : quotes)?.sort(
    (a: QuoteDTO, b: QuoteDTO) => b.score - a.score,
  );

  useClientApplicationActivePolicy(applicationId, {
    enabled:
      applicationData &&
      [(ApplicationStatus.BIND_REQUESTED, ApplicationStatus.BOUND)].includes(applicationData?.applicationStatus),
    refetchInterval: false,
    retry: false,
    onSuccess: (policy) => {
      policy.attestationDone && history.push(insertIdToRoute(ROUTES.application.POLICY, applicationId));
    },
  });

  const headingText = (text: string) => {
    if (quotes?.length === 1) {
      return 'We found a quote!';
    }

    return text;
  };

  return (
    <div className={classes.root}>
      <section>
        <div className={classes.heading} key='heading'>
          <h1 className={classes.title}>
            {quotes && quotes?.length > 0 ? (
              <>
                <Hidden smDown>{headingText('We’ve found some great quotes for you!')}</Hidden>
                <Hidden mdUp>{headingText('Check out these quotes')}</Hidden>
              </>
            ) : (
              <>We weren’t able to find any quotes for you...</>
            )}
          </h1>
        </div>

        <div className={classes.actionsContainer}>
          {!proposal && quotes && quotes?.length > 0 && (
            <ChangeAggLimitSelect
              selectedLimit={selectedAggLimit}
              setSelectedLimit={(limit) => setSelectedAggLimit(limit)}
              availableLimits={availableAggLimits}
            />
          )}
          {proposal && (
            <DownloadProposal
              proposal={proposal}
              application={proposal.application}
              label='Download Full Comparisons'
              className={classes.downloadButton}
              color={COLORS.BLUE_1}
              icon={<CloudDownloadIcon />}
              typography={TYPOGRAPHY.SMALL_MEDIUM}
            />
          )}
          {declinations && declinations?.length > 0 && (
            <Modal
              openButton={
                <LinkButton icon={<WarningRoundedIcon />} typography={TYPOGRAPHY.SMALL_MEDIUM} color={COLORS.RED_1}>
                  {`${declinations?.length} ${
                    declinations?.length > 1 ? 'Quotes were' : 'Quote was'
                  } rejected. See why.`}
                </LinkButton>
              }
              title='Reasons for Declinations'
              removePaddingRight
              padding={4}
              autoWidth
            >
              <RejectedQuotes declinations={declinations} isLoadingDeclinations={isLoadingDeclinations} />
            </Modal>
          )}
        </div>

        {quotesList && quotesList.length > 0 && (
          <QuotesSwiper quotesLength={quotesList.length}>
            {quotesList.map((q, idx) => {
              return (
                <div className={classes.quoteWrapper} key={q.id}>
                  <QuoteCard
                    quote={q}
                    onChooseClick={(q) => handleSelectQuote(q)}
                    recommended={idx === 0}
                    showDetails={showDetails}
                  />
                  <LinkButton
                    className={classes.moreDetails}
                    icon={
                      <ArrowDropdownRounded className={cx(classes.dropdownArrow, showDetails && classes.arrowUp)} />
                    }
                    iconPosition='right'
                    onClick={() => setShowDetails((prev) => !prev)}
                  >
                    {showDetails ? 'Less Details' : 'More Details'}
                  </LinkButton>
                </div>
              );
            })}
          </QuotesSwiper>
        )}
      </section>
    </div>
  );
};
