import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { DeepScanDTO } from 'dtos/deep-scan';
import GsuiteImage from 'images/gsuite.png';
import OutlookImage from 'images/outlook.png';
import React from 'react';

import { CONFIGURATION_STEP } from './index';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
  },
  boxButton: {
    marginTop: theme.spacing(3),
  },

  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },

  or: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  mailboxBox: {
    textDecoration: 'none',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
  },
  outlookBox: {
    color: '#0078D4',
    fontWeight: 'bold',
    backgroundColor: '#F0F8FA',
  },
  gsuiteBox: {
    color: '#686969',
    fontWeight: 'bold',
    backgroundColor: '#FDFAF2',
  },
  image: {
    height: 50,
    width: 50,
    objectFit: 'contain',
    marginRight: theme.spacing(1),
    display: 'block',
  },
}));

interface Props {
  deepScan: DeepScanDTO;
  onVerify: () => void;
}

export const ConfigurePhishingSection = ({ deepScan, onVerify }: Props) => {
  const classes = useStyles();

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      type={CONFIGURATION_STEP.PHISHING}
      description={
        'Our phishing simulator platform requires whitelisting certain IP addresses and domains to allow emails to bypass spam filters. You can either do whitelisting yourself, or provide your admin credentials to us for M365 / GSuiteand we will whitelist for you.'
      }
      title='Configure Phishing'
    >
      <div className={classes.title}>Use either of the options below:</div>
      <div className={classes.box}>
        <div className={classes.boxTitle}>Send Admin Credentials:</div>
        <p className={classes.boxDescription}>
          <a href='https://www.sendsafely.com/u/naren@telivy.com' target='_blank' rel='noreferrer'>
            Please use this link
          </a>{' '}
          to send us either M365 or GSuite admin credentials. Also provide a working email ID that we can send an email
          for testing purposes
        </p>
      </div>

      <div className={classes.or}>OR</div>

      <div className={classes.box}>
        <h3 className={classes.boxTitle}>Manual Whitelist Instructions:</h3>
        <div className={classes.row}>
          <div className={classes.boxDescription}>
            <div className={classes.boxTitle}>IP Addresses:</div>
            54.209.51.230
            <br />
            18.209.119.19
            <br />
            34.231.173.178
            <br />
            168.245.68.173 *<br />
            168.245.34.162 *<br />
            157.230.65.76 *
          </div>

          <div className={classes.boxDescription}>
            <div className={classes.boxTitle}>Phishing Domains:</div>
            it-support.care
            <br />
            customer-portal.info
            <br />
            member-services.info
            <br />
            bankonlinesupport.com
            <br />
            secureaccess.biz
            <br />
            loginverification.com
            <br />
            logmein.com
            <br />
            mlcrosoft.live
            <br />
            cloud-service-care.com
            <br />
            packagetrackingportal.com
          </div>

          <div className={classes.boxDescription}>
            <a
              href='https://learn.microsoft.com/en-us/microsoft-365/security/office-365-security/skip-filtering-phishing-simulations-sec-ops-mailboxes?view=o365-worldwide#use-the-microsoft-365-defender-portal-to-configure-secops-mailboxes-in-the-advanced-delivery-policy'
              target='_blank'
              rel='noreferrer'
              className={cx(classes.mailboxBox, classes.outlookBox)}
            >
              <img src={OutlookImage} alt='outlook' className={classes.image} />
              <div>
                Instructions for
                <br /> Outlook
              </div>
            </a>
            <br />
            <a
              href='https://support.google.com/a/answer/2368132?hl=en'
              target='_blank'
              rel='noreferrer'
              className={cx(classes.mailboxBox, classes.gsuiteBox)}
            >
              <img src={GsuiteImage} alt='gsuite' className={classes.image} />
              <div>
                Instructions for
                <br /> GSuite
              </div>
            </a>
          </div>
        </div>
      </div>
    </SectionTemplate>
  );
};
