import { Button, Checkbox, CircularProgress, FormControlLabel, makeStyles } from '@material-ui/core';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import cx from 'classnames';
import { Modal } from 'components/Modal';
import { useApplicationSecurityScanForceRescan } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: 0,
    border: 'none',
  },
  scanning: {
    margin: theme.spacing(0.5),
  },
}));

interface Props {
  applicationId: string;
  securityScanId: string;
  className?: string;
  isScanning?: boolean;
}

export const RescanButton = ({ applicationId, securityScanId, className, isScanning }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [emailNotif, setEmailNotif] = useState(false);
  const classes = useStyles();
  const { mutate, isLoading } = useApplicationSecurityScanForceRescan(applicationId, securityScanId, {
    onSuccess: () => setIsOpen(false),
  });

  const forceRescan = useCallback(() => mutate({ notifyViaEmail: emailNotif }), [emailNotif, mutate]);

  if (isScanning) {
    return <CircularProgress size={15} thickness={5} className={classes.scanning} />;
  }

  return (
    <>
      <Button className={cx(classes.root, className)} onClick={() => setIsOpen(true)}>
        <RefreshRoundedIcon style={{ display: 'flex' }} />
      </Button>

      <Modal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        title='Force Rescan Assessment'
        padding={4}
        actions={
          <>
            <Button variant='contained' onClick={() => setIsOpen(false)} disabled={isLoading || isScanning}>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => forceRescan()}
              disabled={isLoading || isScanning}
            >
              Confirm
            </Button>
          </>
        }
      >
        <FormControlLabel
          label='Email me when the scan is complete'
          control={<Checkbox checked={emailNotif} color='primary' onChange={(e) => setEmailNotif(e.target.checked)} />}
        />
      </Modal>
    </>
  );
};
