import { Hidden } from '@material-ui/core';
import { BottomActionsNavigation } from 'components/BottomActionsNavigation';
import { Card } from 'components/Card';
import { HiddenBr } from 'components/HiddenBr';
import { PolicyDTO } from 'dtos/policy';
import { ReactComponent as BillingHeaderImage } from 'images/billing.svg';
import { useCheckBillingStatus } from 'queries/usePolicy';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSectionStyles } from 'views/application-policy/sections/useSectionStyles';

import { useQueryParams } from '../useQueryParams';
import { BillingButton } from './BillingButton';

interface DescriptionProps {
  isCheckingBillingStatus: boolean;
  className: string;
}

const SectionDescription = ({ isCheckingBillingStatus, className }: DescriptionProps) => {
  return (
    <p className={className}>
      {isCheckingBillingStatus ? (
        'We are checking the billing result, please wait a moment...'
      ) : (
        <>
          Click on continue to proceed <HiddenBr smUp /> with your payment. <br />
          If you need any help, please <HiddenBr smUp />
          reach out to us.
        </>
      )}
    </p>
  );
};

interface Props {
  policy: PolicyDTO;
}

export const BillingSection = ({ policy }: Props) => {
  const classes = useSectionStyles({ variant: 'billing' });

  // check billing status
  const { mutate, isLoading: isCheckingBillingStatus } = useCheckBillingStatus();
  const queryBilling = useQueryParams();

  useEffect(() => {
    if (queryBilling.get('checkout_success') === 'true' && policy && !policy.billingDone) {
      mutate(policy.id);
    }
  }, [mutate, queryBilling, policy]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Billing</title>
      </Helmet>

      <section className={classes.section}>
        <div className={classes.heading} key='heading'>
          <h1 className={classes.title}>Billing</h1>
          <Hidden mdUp>
            <SectionDescription isCheckingBillingStatus={isCheckingBillingStatus} className={classes.description} />
          </Hidden>
        </div>

        <div className={classes.cardWrapper}>
          <div>
            <Card>
              <div className={classes.cardInnerWrapper}>
                <BillingHeaderImage className={classes.billingHeaderImage} />
                <Hidden smDown>
                  <SectionDescription
                    isCheckingBillingStatus={isCheckingBillingStatus}
                    className={classes.cardDescription}
                  />
                </Hidden>
                <BottomActionsNavigation className={classes.viewButtonWrapper}>
                  <BillingButton policy={policy} isChecking={isCheckingBillingStatus} />
                </BottomActionsNavigation>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
};
