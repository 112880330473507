import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useApplicationDocuments } from 'queries/useApplications';
import React from 'react';
import { Helmet } from 'react-helmet';
import { isAgentAnAdmin } from 'telivy-selectors';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
// import { DocumentsGenerators } from './DocumentsGenerators';
import { DocumentsGroupsTable } from './DocumentsGroupsTable';
import { ApplicationDocumentsTable } from './DocumentsTable';
import { UploadDocument } from './UploadDocument';

const useStyles = makeStyles((theme) => ({
  root: {},
  documentsListContainer: {
    margin: '0 auto',
    maxWidth: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
  },
  unassignedDocuments: {
    paddingTop: theme.spacing(2),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  agent: AgentDTO;
  reports?: boolean;
}

export const ApplicationDocumentsView = ({ application, agent, reports }: Props) => {
  const classes = useStyles();
  const { data } = useApplicationDocuments(application.id);
  const unassignedDocuments = (data || []).filter((d) => !d.groupId);
  const assignedDocuments = (data || []).filter((d) => !!d.groupId);

  return (
    <ApplicationViewContainer>
      <div className={classes.root}>
        <Helmet>
          <title>Reports - {application?.applicationResponse?.organization_name}</title>
        </Helmet>
        <div className={classes.documentsListContainer}>
          {/*<DocumentsGenerators application={application} agent={agent} />*/}

          {isAgentAnAdmin(agent) && <UploadDocument application={application} reports={reports} />}
          {assignedDocuments.length > 0 && <DocumentsGroupsTable application={application} agent={agent} />}

          {unassignedDocuments.length > 0 && (
            <div className={classes.unassignedDocuments}>
              <ApplicationDocumentsTable documents={unassignedDocuments} application={application} agent={agent} />
            </div>
          )}
        </div>
      </div>
    </ApplicationViewContainer>
  );
};
