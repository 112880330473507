import {
  ApplicationsRiskReport,
  BrowserPasswordDTO,
  BrowserPasswordStatsDTO,
  DataRiskDistributionDTO,
  DeepScanDTO,
  // DeepScanM365SecurityData,
  // DeepScanM365SecurityDataValue,
  DeepScanNetworkHostDTO,
  DeepScanNetworkHostNmapOutputDTO,
  DeepScanTargetDTO,
  DeepScanTargetLinkedUserDTO,
  DeepScanTargetPiiSummaryDTO,
  DetailedNodewareAsset,
  NmapVulnerabilityData,
  NodewareAsset,
  ParsedReports,
  PiiFileDTO,
  PiiQueryDTO,
  ReportData,
  RiskAnalysisItem,
  TopNodewareVulnerability,
  UpdateDeepScanDTO,
} from 'dtos/deep-scan';
import fileDownload from 'js-file-download';

import RequestService from './Request';

class _DeepScanService {
  getDeepScan(id: string): Promise<DeepScanDTO> {
    return RequestService.client.get<DeepScanDTO>(`/deep-scan/${id}`).then((response) => response.data);
  }

  getDeepScanNodewareAssets(id: string): Promise<NodewareAsset[]> {
    return RequestService.client.get<NodewareAsset[]>(`/deep-scan/${id}/nodeware`).then((response) => response.data);
  }

  getDeepScanNodewareAssetDetails(deepScanId: string, assetId: string): Promise<DetailedNodewareAsset> {
    return RequestService.client
      .get<DetailedNodewareAsset>(`/deep-scan/${deepScanId}/nodeware/${assetId}`)
      .then((response) => response.data);
  }

  getDeepScanTargetNmapData(deepScanId: string, deepScanTargetId: string): Promise<NmapVulnerabilityData[]> {
    return RequestService.client
      .get<NmapVulnerabilityData[]>(`/deep-scan/${deepScanId}/nmap/${deepScanTargetId}`)
      .then((response) => response.data);
  }

  getDeepScanDataRiskMatches(deepScanId: string, data: PiiQueryDTO): Promise<{ data: PiiFileDTO[]; count: number }> {
    return RequestService.client
      .post<{ data: PiiFileDTO[]; count: number }>(`/deep-scan/${deepScanId}/data-risk-matches`, data)
      .then((response) => response.data);
  }

  getDeepScanNetworkHostDetails(deepScanId: string, networkHostId: string): Promise<DeepScanNetworkHostNmapOutputDTO> {
    return RequestService.client
      .get<DeepScanNetworkHostNmapOutputDTO>(`/deep-scan/${deepScanId}/network-host/${networkHostId}`)
      .then((response) => response.data);
  }

  getDeepScanNodewareTop5Vulnerabilities(id: string): Promise<TopNodewareVulnerability[]> {
    return RequestService.client
      .get<TopNodewareVulnerability[]>(`/deep-scan/${id}/nodeware-top-5`)
      .then((response) => response.data);
  }

  getDeepScanParsedReports(id: string): Promise<ParsedReports> {
    return RequestService.client.get<ParsedReports>(`/deep-scan/${id}/reports`).then((response) => response.data);
  }

  getDeepScanApplicationRiskUser(id: string): Promise<ApplicationsRiskReport[]> {
    return RequestService.client
      .get<ApplicationsRiskReport[]>(`/deep-scan/${id}/application-risk-user`)
      .then((response) => response.data);
  }

  getDeepScanDataRiskDistribution(id: string): Promise<DataRiskDistributionDTO[]> {
    return RequestService.client
      .get<DataRiskDistributionDTO[]>(`/deep-scan/${id}/data-risk-distribution`)
      .then((response) => response.data);
  }

  getDeepScanAzureAuthLink(id: string): Promise<string> {
    return RequestService.client.get<string>(`/deep-scan/${id}/azure-auth-link`).then((response) => response.data);
  }

  getDeepScanGoogleWsAuthLink(id: string): Promise<string> {
    return RequestService.client.get<string>(`/deep-scan/${id}/google-ws-auth-link`).then((response) => response.data);
  }

  getDeepScanReportData(id: string): Promise<ReportData> {
    return RequestService.client.get<ReportData>(`/deep-scan/${id}/report-data`).then((response) => response.data);
  }

  getDeepScanRiskAnalysis(id: string): Promise<RiskAnalysisItem[]> {
    return RequestService.client
      .get<RiskAnalysisItem[]>(`/deep-scan/${id}/risk-analysis`)
      .then((response) => response.data);
  }

  getDeepScanTargets(id: string): Promise<DeepScanTargetDTO[]> {
    return RequestService.client.get<DeepScanTargetDTO[]>(`/deep-scan/${id}/targets`).then((response) => response.data);
  }

  getDeepScanTargetsNmap(id: string): Promise<DeepScanTargetDTO[]> {
    return RequestService.client
      .get<DeepScanTargetDTO[]>(`/deep-scan/${id}/targets-nmap`)
      .then((response) => response.data);
  }

  getDeepScanNetworkHosts(id: string): Promise<DeepScanNetworkHostDTO[]> {
    return RequestService.client
      .get<DeepScanNetworkHostDTO[]>(`/deep-scan/${id}/network-hosts`)
      .then((response) => response.data);
  }

  getDeepScanTargetsPiiSummary(id: string): Promise<DeepScanTargetPiiSummaryDTO[]> {
    return RequestService.client
      .get<DeepScanTargetPiiSummaryDTO[]>(`/deep-scan/${id}/targets-pii-summary`)
      .then((response) => response.data);
  }

  getDeepScanM365SecurityData<T>(id: string, dataKey: string): Promise<T> {
    return RequestService.client
      .get<T>(`/deep-scan/${id}/m365-security-data/${dataKey}`)
      .then((response) => response.data);
  }

  getDeepScanGoogleWsSecurityData<T>(id: string, dataKey: string): Promise<T> {
    return RequestService.client
      .get<T>(`/deep-scan/${id}/gws-security-data/${dataKey}`)
      .then((response) => response.data);
  }

  getBrowserPasswords(id: string): Promise<BrowserPasswordDTO[]> {
    return RequestService.client
      .get<BrowserPasswordDTO[]>(`/deep-scan/${id}/browser-passwords`)
      .then((response) => response.data);
  }

  getCommonBrowserPasswords(id: string): Promise<BrowserPasswordStatsDTO[]> {
    return RequestService.client
      .get<BrowserPasswordStatsDTO[]>(`/deep-scan/${id}/common-browser-passwords`)
      .then((response) => response.data);
  }

  getDarkWebBrowserPasswords(id: string): Promise<BrowserPasswordStatsDTO[]> {
    return RequestService.client
      .get<BrowserPasswordStatsDTO[]>(`/deep-scan/${id}/dark-web-browser-passwords`)
      .then((response) => response.data);
  }

  updateDeepScan(id: string, data: UpdateDeepScanDTO): Promise<DeepScanDTO> {
    return RequestService.client.patch<DeepScanDTO>(`/deep-scan/${id}`, data).then((response) => response.data);
  }

  updateDeepScanTargetLinkedUsers(id: string, deepScanTargetId: string, linkedUsers: DeepScanTargetLinkedUserDTO[]) {
    return RequestService.client
      .patch<DeepScanTargetDTO>(`/deep-scan/${id}/update-linked-users/${deepScanTargetId}`, { linkedUsers })
      .then((response) => response.data);
  }

  uninstallDeepScanTarget(id: string, deepScanTargetId: string) {
    return RequestService.client
      .get<void>(`/deep-scan/${id}/uninstall-target/${deepScanTargetId}`)
      .then((response) => response.data);
  }

  refetchM365DeepScan(id: string) {
    return RequestService.client.get<void>(`/deep-scan/${id}/refetch-m365`).then((response) => response.data);
  }

  refetchGwsDeepScan(id: string) {
    return RequestService.client.get<void>(`/deep-scan/${id}/refetch-gws`).then((response) => response.data);
  }

  rescanDeepScanTarget(id: string, deepScanTargetId: string) {
    return RequestService.client
      .get<void>(`/deep-scan/${id}/rescan-target/${deepScanTargetId}`)
      .then((response) => response.data);
  }

  removeDeepScanTarget(id: string, deepScanTargetId: string) {
    return RequestService.client
      .get<void>(`/deep-scan/${id}/remove-target/${deepScanTargetId}`)
      .then((response) => response.data);
  }

  async markPiiFileFalsePositive(deepScanId: string, piiFileId: string, falsePositive: boolean) {
    return RequestService.client
      .patch<void>(`/deep-scan/${deepScanId}/pii-files/${piiFileId}`, { falsePositive })
      .then((response) => response.data);
  }

  async uploadDomainsCsv(id: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await RequestService.client
      .post<string>(`/deep-scan/${id}/upload-employee-list`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);
  }

  async generateDocxReport(deepScanId: string) {
    return RequestService.client
      .get<void>(`/deep-scan/${deepScanId}/generate-docx-report`)
      .then((response) => response.data);
  }

  async generatePiiScanReport(deepScanId: string) {
    return RequestService.client
      .get<void>(`/deep-scan/${deepScanId}/generate-pii-scan-report`)
      .then((response) => response.data);
  }

  async generatePiiExcelBundle(deepScanId: string) {
    return RequestService.client
      .get<void>(`/deep-scan/${deepScanId}/generate-pii-report`)
      .then((response) => response.data);
  }

  async scheduleReportsGeneration(deepScanId: string) {
    return RequestService.client
      .post<void>(`/deep-scan/${deepScanId}/schedule-reports-generation`, { format: 'pdf' })
      .then((response) => response.data);
  }

  async generateBrowserPasswordsReport(deepScanId: string, fileName: string) {
    const response = await RequestService.client.get<Blob>(`/deep-scan/${deepScanId}/browser-passwords-report`, {
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }

  async generateApplicationSecurityReport(deepScanId: string, fileName: string) {
    const response = await RequestService.client.get<Blob>(
      `/deep-scan/${deepScanId}/generate-application-security-report`,
      {
        responseType: 'blob',
      },
    );

    fileDownload(response.data, fileName);
  }
}

const DeepScanService = new _DeepScanService();

export { DeepScanService };
