import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

export const verifyApplicationToken = (applicationId: string, setIsChecking: (isChecking: boolean) => void) => {
  const returnUrl = `${window.location.pathname}${window.location.search}`;

  if (applicationId) {
    const token = localStorage.getItem(`authApp-${applicationId}`);

    if (token) {
      setIsChecking(false);
    } else {
      window.location.replace(`${insertIdToRoute(ROUTES.VERIFY, applicationId)}?returnUrl=${returnUrl}`);
    }
  }
};

interface Props extends RouteProps {
  computedMatch?: {
    params?: {
      id?: string;
    };
  };
}

const ProtectedApplicationRoute = (props: Props) => {
  const returnUrl = `${window.location.pathname}${window.location.search}`;
  const applicationId = props?.computedMatch?.params?.id;
  const [isAuthorized, setIsAuthorized] = useState(Boolean(localStorage.getItem(`authApp-${applicationId}`)));

  useEffect(() => {
    setIsAuthorized(Boolean(localStorage.getItem(`authApp-${applicationId}`)));
  }, [applicationId, isAuthorized]);

  if (applicationId) {
    return isAuthorized ? (
      <Route {...props} />
    ) : (
      <Redirect to={`${insertIdToRoute(ROUTES.VERIFY, applicationId)}?returnUrl=${returnUrl}`} />
    );
  }

  return <Redirect to={ROUTES.agent.ROOT} />;
};

export default ProtectedApplicationRoute;
