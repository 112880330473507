import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
// import { useDeepScanNetworkHostDetails } from 'queries/useDeepScan';
import React, { useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

interface Props {
  deepScanId: string;
  deepScanTarget?: DeepScanTargetDTO;
  networkHost: DeepScanNetworkHostDTO;
  className?: string;
  label: string;
}

const BLOCK_TYPES: Record<string, string> = {
  'MA-L': 'MAC Address Block Large (previously named OUI). Number of address 2^24 (~16 Million)',
  'MA-M': 'MAC Address Block Medium. Number of address 2^20 (~1 Million)',
  'MA-S':
    'MAC Address Block Small (previously named OUI-36, encompasses IAB Assignments). Number of address 2^12 (4096)',
  CID: 'Company Identifier. This prefix will not be used for globally unique applications. Number of address 2^24 (~16 Million)',
};

export const HostDetailsModal: React.FC<Props> = ({ deepScanTarget, networkHost, className, label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const isLoading = false;
  // const { data, isLoading } = useDeepScanNetworkHostDetails(deepScanId, networkHost.id, {
  //   enabled: Boolean(deepScanId && isModalOpen),
  // });

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button className={className} disabled={isLoading} onClick={() => setIsModalOpen(true)}>
        {isLoading ? <CircularProgress size={16} /> : label}
      </Button>

      <Dialog fullWidth={true} open={isModalOpen} onClose={handleClose}>
        {networkHost && (
          <DialogContent>
            <div className={classes.title}>Scan Results for Host {networkHost.ipAddress}</div>

            <strong>
              <pre>Device Info:</pre>
            </strong>
            <ul>
              <li>
                <pre>Host Name: {networkHost.hostName}</pre>
              </li>
              <li>
                <pre>MAC Address: {networkHost.macAddress}</pre>
              </li>
              <li>
                <pre>
                  Status: Vendor name {networkHost.macAddressData && !networkHost.macAddressData.private ? `` : `not `}
                  displayed on public listing
                </pre>
              </li>
              <li>
                <pre>Vendor Name: {networkHost.macAddressData?.vendorName}</pre>
              </li>
              <li>
                <pre>Block Type: {BLOCK_TYPES[networkHost.macAddressData?.blockType || '']}</pre>
              </li>
            </ul>

            {deepScanTarget && (
              <>
                <strong>
                  <pre>OS Detected:</pre>
                </strong>
                <ul>
                  <li>{deepScanTarget.osVersion}</li>
                  {/*data.os.map((o) => (
                    <li key={o}>
                      <pre>{o}</pre>
                    </li>
                  ))*/}
                </ul>
              </>
            )}

            {/*data?.services && (
              <>
                <strong>
                  <pre>Services Detected:</pre>
                </strong>
                <ul>
                  {data.services.map((o) => (
                    <li key={o.port}>
                      <pre>
                        {o.service} on port {o.port}
                      </pre>
                    </li>
                  ))}
                </ul>
              </>
            )*/}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
