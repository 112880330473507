import { Button } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import { useToasters } from 'hooks/useToasters';
import { useDeepScanAzureAuthLink } from 'queries/useDeepScan';
import React, { useCallback } from 'react';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { CONFIGURATION_STEP } from './index';
import { SectionTemplate } from './SectionTemplate';

interface Props {
  deepScan: DeepScanDTO;
  onVerify: () => void;
  application: ApplicationWithAgentDTO;
}

export const ConfigureMicrosoft365 = ({ deepScan, onVerify, application }: Props) => {
  const { data: m365AuthLink } = useDeepScanAzureAuthLink(deepScan.id);
  const chartsEnabled = useChartsEnabled(deepScan, application);
  const { showToaster } = useToasters();

  const handleCopyLink = useCallback(() => {
    if (m365AuthLink) {
      navigator.clipboard.writeText(m365AuthLink);
      showToaster('M365 connect link is copied to clipboard');
    }
  }, [m365AuthLink, showToaster]);

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      hideVerify={true}
      type={CONFIGURATION_STEP.CLOUD_SOFTWARES}
      title='Configure Microsoft 365'
      description={
        deepScan.m365Connected
          ? 'You have already connected M365 Account'
          : 'Complete the following steps to configure M365.'
      }
    >
      {!chartsEnabled && (
        <SecurityCard.Badge variant='locked'>
          Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
        </SecurityCard.Badge>
      )}
      {chartsEnabled && m365AuthLink && (
        <>
          <Button variant='contained' color='primary' onClick={() => window.open(m365AuthLink, '_blank')?.focus()}>
            Click here to {deepScan.m365Connected ? 'reconnect' : 'connect'} Microsoft 365 account
          </Button>
          <Button
            variant='text'
            style={{ marginLeft: '10px' }}
            onClick={handleCopyLink}
            startIcon={<FileCopyRoundedIcon />}
          >
            Copy link
          </Button>
        </>
      )}
    </SectionTemplate>
  );
};
