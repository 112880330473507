import { makeStyles } from '@material-ui/core/styles';
// import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import cx from 'classnames';
import { Badge, BadgeVariant } from 'components/Badge';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationWithAgentDTO } from 'dtos/application';
import {
  DeepScanM365AuditLogSignIn,
  DeepScanM365AuditLogSignIns,
  DeepScanM365ControlProfile,
  DeepScanM365ControlScore,
  DeepScanM365SecurityScoreControlProfiles,
  DeepScanM365SecurityScores,
  DeepScanM365User,
  DeepScanM365Users,
} from 'dtos/deep-scan';
import { useChartImage } from 'hooks/useChartImage';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanM365SecurityData } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ApplicationViewContainer } from 'views/agent/views/application-details/views/ApplicationViewContainer';

import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';
import { SecurityCard } from '../../components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  mapContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
  },

  mapContainerItem: {
    flex: 1,
    flexDirection: 'column',
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '&:last-child': {
      paddingTop: 0,
    },
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },
  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },
  red: {
    color: COLORS.RED_1,
  },
  green: {
    color: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.BLUE_1,
  },

  chart: {
    // maxHeight: 600,
  },
  chartContainer: {
    marginBottom: theme.spacing(2),
  },
  disclaimer: {
    width: 700,
    margin: '0 auto',
  },
  errorBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
  },

  legend: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },

  noBorder: {
    border: 'none',
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  isAdmin: boolean;
}

const STRONG_THRESHOLD = 80;
const MODERATE_THRESHOLD = 40;
const PER_PAGE = 8;
const PER_PAGE_LOCATIONS = 15;
const AUTH_METHODS: Record<string, string> = {
  '#microsoft.graph.emailAuthenticationMethod': 'Email',
  '#microsoft.graph.fido2AuthenticationMethod': 'FIDO2 Security Key',
  '#microsoft.graph.microsoftAuthenticatorAuthenticationMethod': 'MS Authenticator',
  '#microsoft.graph.passwordAuthenticationMethod': 'Password',
  '#microsoft.graph.phoneAuthenticationMethod': 'Phone',
  '#microsoft.graph.softwareOathAuthenticationMethod': 'TOTP',
  '#microsoft.graph.temporaryAccessPassAuthenticationMethod': 'Temporary Access Pass',
  '#microsoft.graph.windowsHelloForBusinessAuthenticationMethod': 'Windows Hello for Business',
};

const getScoreBadgeVariant = (scoreInPercentage: number | null): BadgeVariant => {
  if (scoreInPercentage && scoreInPercentage > STRONG_THRESHOLD) {
    return 'green';
  } else if (scoreInPercentage && scoreInPercentage > MODERATE_THRESHOLD) {
    return 'yellow';
  } else {
    return 'red';
  }
};

const getMFAScoreRanking = (mfaStatus: string): ScoreRanking => {
  switch (mfaStatus) {
    case 'Enabled':
      return 'Low';
    case 'Not enabled':
      return 'High';
    default:
      return 'None';
  }
};

export const DeepScanM365Security = ({ application, isAdmin }: Props) => {
  const classes = useStyles();
  const deepScanId = application.deepScanId || '';
  const { data: securityScore, isLoading: isLoadingSecurityScore } =
    useDeepScanM365SecurityData<DeepScanM365SecurityScores>(deepScanId, 'securityScore', {
      enabled: Boolean(deepScanId),
    });
  const { data: usersRaw, isLoading: isLoadingUsers } = useDeepScanM365SecurityData<DeepScanM365Users>(
    deepScanId,
    'users',
    { enabled: Boolean(deepScanId) },
  );
  const { data: auditLogsSignIns, isLoading: isLoadingAuditLogsSignIns } =
    useDeepScanM365SecurityData<DeepScanM365AuditLogSignIns>(deepScanId, 'auditLogsSignIns', {
      enabled: Boolean(deepScanId),
    });
  const { data: securityScoreControlProfiles, isLoading: isLoadingSecurityScoreControlProfiles } =
    useDeepScanM365SecurityData<DeepScanM365SecurityScoreControlProfiles>(deepScanId, 'securityScoreControlProfiles', {
      enabled: Boolean(deepScanId),
    });

  const [userPage, setUserPage] = useState(0);
  const [userSorter, setUserSorter] = useState<Sorter<keyof DeepScanM365User> | undefined>({
    key: 'displayName',
    order: SortOrder.ASC,
  });

  const [actionPage, setActionPage] = useState(0);
  const [actionSorter, setActionSorter] = useState<Sorter<keyof DeepScanM365ControlScore> | undefined>({
    key: 'scoreImpact',
    order: SortOrder.DESC,
  });

  const [locationPage, setLocationPage] = useState(0);
  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);

  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  const mapChart = useChartImage(
    { chartContainerClassName: classes.noBorder },
    charts?.m365LoginStatusMap,
    deepScan,
    application,
  );
  const securityScoreChart = useChartImage(
    { className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.m365SecurityScoreHistory,
    deepScan,
    application,
  );
  const failedLoginsChart = useChartImage(
    { title: 'Failed logins', className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.m365FailedLogins,
    deepScan,
    application,
  );
  const loginActivityChart = useChartImage(
    { title: 'Login Activity', className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.m365LoginActivity,
    deepScan,
    application,
  );

  const securityScoreData = useMemo(
    () => (securityScore?.value || []).filter((s) => s.averageComparativeScores.length > 0)[0],
    [securityScore],
  );
  const users = useMemo(() => usersRaw?.value || [], [usersRaw]);
  const loginAudits = useMemo(() => auditLogsSignIns?.value || [], [auditLogsSignIns]);

  const loginByLocation = useMemo(() => {
    const res: Record<string, DeepScanM365AuditLogSignIn[]> = {};
    if (auditLogsSignIns?.value) {
      for (const al of auditLogsSignIns?.value || []) {
        const location = [al.location.city, al.location.state, al.location.countryOrRegion].join(', ');
        res[location] = res[location] || [];
        res[location].push(al);
      }
    }
    return res;
  }, [auditLogsSignIns]);

  const actionsDetails = useMemo(() => {
    const res: Record<string, DeepScanM365ControlProfile> = {};
    if (securityScoreControlProfiles?.value) {
      for (const cp of securityScoreControlProfiles?.value || []) {
        res[cp.id] = cp;
      }
    }
    return res;
  }, [securityScoreControlProfiles]);

  const actions: DeepScanM365ControlScore[] = useMemo(() => {
    return (securityScoreData?.controlScores || []).map((scoreData) => {
      return {
        ...scoreData,
        title: actionsDetails[scoreData.controlName]?.title,
        maxScore: actionsDetails[scoreData.controlName]?.maxScore,
        scoreImpact:
          (((actionsDetails[scoreData.controlName]?.maxScore || 0) - scoreData.score) * 100) /
          (securityScoreData?.maxScore || 1),
      };
    });
  }, [securityScoreData, actionsDetails]);

  const securityScorePercent = useMemo(() => {
    if (securityScoreData) {
      return (((securityScoreData.currentScore || 0) * 100) / (securityScoreData.maxScore || 1)).toFixed(2);
    }
    return 'N/A';
  }, [securityScoreData]);

  const benchmarkPercent = useMemo(() => {
    if (securityScoreData) {
      return securityScoreData.averageComparativeScores.filter((d) => d.basis === 'TotalSeats')[0]?.averageScore;
    }
    return 'N/A';
  }, [securityScoreData]);

  const actionColumns = useMemo((): Column<DeepScanM365ControlScore, keyof DeepScanM365ControlScore>[] => {
    return [
      {
        title: 'Recommended Action',
        sortKey: 'controlName',
        render: (row) => row.title || row.controlName,
      },
      {
        title: 'Score Impact',
        sortKey: 'score',
        render: (row) => ((row.scoreImpact || 0) > 0 ? `+${(row.scoreImpact || 0).toFixed(2)}%` : ''),
      },
      {
        title: 'Score',
        sortKey: 'scoreInPercentage',
        render: (row) => (
          <Badge bordered variant={getScoreBadgeVariant(row.scoreInPercentage)} style={{ minWidth: 80, width: 80 }}>
            {row.scoreInPercentage} %
          </Badge>
        ),
      },
      {
        title: 'Points achieved',
        sortKey: 'score',
        render: (row) => `${row.score} / ${row.maxScore || 'N/A'}`,
      },
    ];
  }, []);

  const sortedActions = useMemo(() => {
    if (actionSorter) {
      const data = sortBy(actions, actionSorter.key);

      if (actionSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(actions, (action) => [action.scoreInPercentage, action.scoreImpact || 0]);
  }, [actions, actionSorter]);

  const actionsData = useMemo(() => {
    return (sortedActions || []).slice(actionPage * PER_PAGE, actionPage * PER_PAGE + PER_PAGE);
  }, [actionPage, sortedActions]);

  const userColumns = useMemo((): Column<DeepScanM365User, keyof DeepScanM365User>[] => {
    const accountTypeColumn = {
      title: 'Account Type',
      render: (row: DeepScanM365User) => row.mailboxSettings?.userPurpose || 'n/a',
    };

    const signInStatusColumn = {
      title: 'Sign-in Status',
      render: (row: DeepScanM365User) => (
        <ScoreBox
          ranking={getMFAScoreRanking(
            row.accountEnabled !== undefined ? (row.accountEnabled ? 'Enabled' : 'Not enabled') : 'n/a',
          )}
          label={row.accountEnabled !== undefined ? (row.accountEnabled ? 'Enabled' : 'Disabled') : 'n/a'}
        />
      ),
    };

    const lastLoginColumn = {
      title: 'Last Login',
      render: (row: DeepScanM365User) => {
        const date = row.signInActivity?.lastNonInteractiveSignInDateTime || row.signInActivity?.lastSignInDateTime;
        return date ? dayjs(date).format('M/D/YYYY h:mm A') : 'N/A';
      },
    };

    return [
      {
        title: 'Name',
        sortKey: 'displayName',
        render: (row) => row.displayName,
      },
      {
        title: 'Email',
        sortKey: 'mail',
        render: (row) => row.mail,
      },
      ...(auditLogsSignIns?.error ? [] : [accountTypeColumn, signInStatusColumn]),
      {
        title: 'MFA Status',
        render: (row) => {
          let mfaStatus = 'N/A';
          if (!row.mailboxSettings?.userPurpose || row.mailboxSettings?.userPurpose != 'shared') {
            mfaStatus = (row.authenticationMethods || []).length > 1 ? 'Enabled' : 'Not enabled';
          }

          return <ScoreBox ranking={getMFAScoreRanking(mfaStatus)} label={mfaStatus} />;
        },
      },
      {
        title: 'Authentication Methods',
        render: (row) =>
          (row.authenticationMethods || []).map((m) => AUTH_METHODS[m['@odata.type']] || 'N/A').join(', '),
      },
      ...(auditLogsSignIns?.error ? [] : [lastLoginColumn]),
      {
        title: 'Successful logins',
        render: (row) => loginAudits.filter((l) => l.userId === row.id && l.status.errorCode === 0).length,
      },
      {
        title: 'Login Failures',
        render: (row) => loginAudits.filter((l) => l.userId === row.id && l.status.errorCode !== 0).length,
      },
    ];
  }, [loginAudits, auditLogsSignIns?.error]);

  const sortedUsers = useMemo(() => {
    const filteredUsers = users.filter((u) => u.mail);

    if (userSorter) {
      const data = sortBy(filteredUsers, userSorter.key);

      if (userSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(filteredUsers, (user) => user.displayName);
  }, [users, userSorter]);

  const usersData = useMemo(() => {
    return (sortedUsers || []).slice(userPage * PER_PAGE, userPage * PER_PAGE + PER_PAGE);
  }, [userPage, sortedUsers]);

  const locationColumns = useMemo((): Column<{ location: string }, keyof { location: string }>[] => {
    return [
      {
        title: 'Location',
        render: (row) => row.location,
      },
      {
        title: 'Successful logins',
        render: (row) => (loginByLocation[row.location] || []).filter((l) => l.status.errorCode === 0).length,
      },
      {
        title: 'Login Failures',
        render: (row) => (loginByLocation[row.location] || []).filter((l) => l.status.errorCode !== 0).length,
      },
    ];
  }, [loginByLocation]);

  const sortedLocations = useMemo(() => {
    const allLocations = Object.keys(loginByLocation).map((l) => {
      return { location: l };
    });
    return sortBy(allLocations, (l) => l.location);
  }, [loginByLocation]);

  const locationsData = useMemo(() => {
    return (sortedLocations || []).slice(
      locationPage * PER_PAGE_LOCATIONS,
      locationPage * PER_PAGE_LOCATIONS + PER_PAGE_LOCATIONS,
    );
  }, [locationPage, sortedLocations]);

  if (isLoadingSecurityScore) {
    return <LoadingContainer />;
  }

  if (!enableCharts && !isAdmin) {
    return (
      <ApplicationViewContainer>
        <div className={classes.disclaimer}>
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        </div>
      </ApplicationViewContainer>
    );
  }

  if (!securityScore) {
    return (
      <ApplicationViewContainer>
        <EmptyState title='Fetching data from Microsoft 365 in progress. Please check back after 15 minutes.' />
      </ApplicationViewContainer>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Microsoft 365 Security</div>

      <div className={classes.section}>
        <div className={classes.summary}>
          <div className={classes.item}>
            <div className={cx(classes.number, securityScorePercent > benchmarkPercent ? classes.green : classes.red)}>
              {securityScorePercent}%
            </div>
            <div className={classes.description}>Security Score</div>
          </div>
          <div className={classes.item}>
            <div className={classes.number}>{benchmarkPercent}%</div>
            <div className={classes.description}>Benchmark Score</div>
          </div>

          <div className={classes.item}>
            <div className={classes.number}>
              {securityScoreData?.currentScore || 'N/A'} / {securityScoreData?.maxScore || 'N/A'}
            </div>
            <div className={classes.description}>Points Achieved</div>
          </div>
        </div>

        {!auditLogsSignIns?.error && (
          <div className={classes.summary}>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.blue)}>{users?.length}</div>
              <div className={classes.description}>Total Accounts</div>
            </div>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.red)}>
                {
                  (users || []).filter((u) => (u.authenticationMethods || []).length <= 1 && u.accountEnabled !== false)
                    .length
                }
              </div>
              <div className={classes.description}>Accounts without MFA</div>
            </div>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.red)}>
                {(loginAudits || []).filter((l) => l.status.errorCode !== 0).length}
              </div>
              <div className={classes.description}>Total Failed Logins</div>
            </div>
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>Security Score History</div>

        {securityScoreChart}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Recommended Actions
          <span className={classes.findings}>
            {' '}
            ({actions.filter((a) => (a.scoreImpact || 0) > 0).length} to address)
          </span>
        </div>
        <div className={classes.boxBorder}>
          <Table<DeepScanM365ControlScore, keyof DeepScanM365ControlScore>
            columns={actionColumns}
            pagination={
              isLoadingSecurityScore || isLoadingSecurityScoreControlProfiles
                ? undefined
                : { page: actionPage, perPage: PER_PAGE, total: sortedActions?.length || 0 }
            }
            sorter={actionSorter}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setActionPage(pagination?.page);
              }

              const isSorterChanging = actionSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setActionSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setActionPage(0);
              }
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.controlName}`}
            data={actionsData}
            loading={isLoadingSecurityScore || isLoadingSecurityScoreControlProfiles}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Accounts Status
          <span className={classes.findings}> ({users.length} users)</span>
        </div>
        <div className={classes.boxBorder}>
          <Table<DeepScanM365User, keyof DeepScanM365User>
            columns={userColumns}
            pagination={
              isLoadingUsers ? undefined : { page: userPage, perPage: PER_PAGE, total: sortedUsers?.length || 0 }
            }
            sorter={userSorter}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setUserPage(pagination?.page);
              }

              const isSorterChanging = userSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setUserSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setUserPage(0);
              }
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.id}`}
            data={usersData}
            loading={isLoadingUsers}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Login Status
          {/*<span className={classes.findings}> ({loginAudits.length} audit logs)</span>*/}
        </div>
        {auditLogsSignIns?.error && auditLogsSignIns?.error.indexOf('premium license') > -1 && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            To get login and security data from M365, an Azure Microsoft Entra ID P1 (NCE) license and Global admin is
            required. This license allows us to retrieve monitor login statistics like location and MFA status from
            Microsoft.
            <br />
            You can check out the plans, costs and features{' '}
            <a
              href='https://www.microsoft.com/en-us/security/business/microsoft-entra-pricing'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
            .
          </SecurityCard.Badge>
        )}
        {auditLogsSignIns?.error && isAdmin && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            [Admin] Error: {auditLogsSignIns?.error}
          </SecurityCard.Badge>
        )}
        {loginAudits.length > 0 && (
          <>
            <div className={classes.summary}>
              <div className={classes.item}>
                <div className={classes.number}>{loginAudits.length}</div>
                <div className={classes.description}>Events</div>
              </div>

              <div className={classes.item}>
                <div className={cx(classes.number, classes.green)}>
                  {loginAudits.filter((l) => l.status.errorCode === 0).length}
                </div>
                <div className={classes.description}>Successful Logins</div>
              </div>

              <div className={classes.item}>
                <div className={cx(classes.number, classes.red)}>
                  {loginAudits.filter((l) => l.status.errorCode !== 0).length}
                </div>
                <div className={classes.description}>Failed Logins</div>
              </div>

              <div className={classes.item}>
                <div className={classes.number}>{sortedLocations.length}</div>
                <div className={classes.description}>Login Locations</div>
              </div>
            </div>

            <div className={cx(classes.mapContainer, classes.section)}>
              <div className={classes.mapContainerItem}>
                {mapChart}
                <div className={classes.legend}>
                  <strong>Legend: </strong>
                  <Badge variant='green'>Low Severity</Badge>
                  <Badge variant='yellow'>Medium Severity</Badge>
                  <Badge variant='red'>High Severity</Badge>
                </div>
              </div>
              <div className={classes.mapContainerItem}>
                <Table<{ location: string }, keyof { location: string }>
                  columns={locationColumns}
                  pagination={
                    isLoadingAuditLogsSignIns
                      ? undefined
                      : { page: locationPage, perPage: PER_PAGE_LOCATIONS, total: sortedLocations?.length || 0 }
                  }
                  onChange={(pagination) => {
                    if (pagination?.page !== undefined) {
                      setLocationPage(pagination?.page);
                    }
                  }}
                  rowContentCentered
                  className={classes.table}
                  rowKey={(row) => `${row.location}`}
                  data={locationsData}
                  loading={isLoadingAuditLogsSignIns}
                />
              </div>
            </div>
            <div className={classes.section}>{failedLoginsChart}</div>
            <div className={classes.section}>{loginActivityChart}</div>
          </>
        )}
      </div>
    </div>
  );
};
