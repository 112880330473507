import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import cx from 'classnames';
import { ApplicationStatusLabel } from 'components/ApplicationStatusLabel';
import { EffectiveDate } from 'components/EffectiveDate';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { VerifyEmail } from 'components/VerifyEmail';
import { AgentDTO, AgentRole } from 'dtos/agent';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
import { useRiskMonitoringEnabled } from 'hooks/useChartsEnabled';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplication, useLatestApplication, useLatestSecurityApplication } from 'queries/useApplications';
import React, { useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { createSecurityRoute, insertIdToRoute, ROUTES } from 'telivy-constants';
import { isAgentAnAdmin, isScanApplication, isSecurityAgency, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { OverallScoreBadge } from 'templates/SecurityAssessments/components/OverallScoreBadge';
import { ChangeStatusModal } from 'views/agent/views/application-details/ChangeStatusModal';
// import { MonitoringSwitch } from 'views/agent/views/application-details/views/form/MonitoringSwitch';
import { ConvertButton } from 'views/agent/views/application-details/views/security/ConvertButton';
import { ConvertMonitoringButton } from 'views/agent/views/application-details/views/security/ConvertMonitoringButton';
import { EditAssessmentButton } from 'views/agent/views/application-details/views/security/EditAssessmentButton';
import { MarkArchivedButton } from 'views/agent/views/application-details/views/security/MarkArchivedButton';
import { RequestForReportButton } from 'views/agent/views/application-details/views/security/RequestForReportButton';

import { Menu } from './Menu';
import { ApplicationAssetsView } from './views/assets';
import { ApplicationConfigurationView } from './views/configuration';
import { ApplicationDocumentsView } from './views/documents';
import { ApplicationFormView } from './views/form';
import { ShareApplicationButton } from './views/form/ShareApplicationButton';
import { ApplicationHistoryView } from './views/history';
import { ApplicationMonitoringView } from './views/monitoring';
import { ApplicationPoliciesView } from './views/policies';
import { ApplicationProposalView } from './views/proposals';
import { ApplicationQuotesView } from './views/quotes';
import { ApplicationSecurityView } from './views/security';
import { ApplicationServicesView } from './views/services';

const {
  ROOT,
  HISTORY,
  FORM,
  PROPOSAL,
  REPORTS,
  SERVICES,
  QUOTES,
  CONFIGURATION,
  DOCUMENTS,
  POLICIES,
  MONITORING,
  security,
  assets,
} = ROUTES.agent.application;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  // Header elements
  header: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${COLORS.GREY_5}`,
    padding: `0 ${theme.spacing(5)}px`,
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(),
  },
  title: {
    ...TYPOGRAPHY.LARGE,
    color: COLORS.GREY_1,
    margin: 0,
  },
  subtitle: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
    margin: 0,
  },
  effectiveDate: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
    color: COLORS.GREY_2,
    marginBottom: 0,
  },

  actionsMainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
  },
  infoWrapper: {
    display: 'flex',
    gap: 16,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  status: {
    height: 'fit-content',
  },
  actionButton: {
    border: 'none',
    // backgroundColor: COLORS.GREY_6,
    color: COLORS.GREY_3,
    padding: `2px`,
    minWidth: 0,

    '& svg': {
      fontSize: theme.spacing(2.5),
    },

    '&:hover': {
      color: COLORS.GREY_1,
    },
  },

  menu: {
    marginBottom: theme.spacing(3),
  },

  monitoringFormContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  monitoringForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },

  // Content
  main: {},
  center: {
    padding: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface ParamsType {
  id: string;
}

interface Props {
  agent: AgentDTO;
}

export const ApplicationDetailsView = ({ agent }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const monitoringEnabled = useRiskMonitoringEnabled();

  const { data: currentAgent, isLoading: isLoadingAgent } = useCurrentAgent();
  const externalScanDisabled = useExternalScanDisabled();

  const [securityOverallScore, setSecurityOverallScore] = useState<number | null>(null);
  const { id } = useParams<ParamsType>();
  const { data: dataApplicationById, isLoading: isLoadingApplicationById } = useApplication(id, {
    enabled: id !== 'latest',
  });

  // get latest application based on flow type, just for safety/future use
  const fnHook: (options?: UseQueryOptions<ApplicationWithAgentDTO>) => UseQueryResult<ApplicationWithAgentDTO> =
    isSecurityAgency(agent) ? useLatestApplication : useLatestSecurityApplication;

  const { data: dataLatestApplication, isLoading: isLoadingLatestApplication } = fnHook({
    enabled: id === 'latest',
    retry: 1,
    onError: () => {
      // if latest application is not found, redirect applications list
      if (id === 'latest') {
        history.push(ROUTES.agent.DASHBOARD);
      }
    },
  });
  const isLoading = isLoadingAgent || isLoadingApplicationById ? isLoadingApplicationById : isLoadingLatestApplication;
  const data = dataApplicationById ? dataApplicationById : dataLatestApplication;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const applicationStatusButtonRef = useRef<HTMLButtonElement>(null);

  const rootRoute: string = React.useMemo(() => {
    if (isScanApplication(data)) {
      return insertIdToRoute(CONFIGURATION, id);
    } else if (isSecurityApplication(data)) {
      return createSecurityRoute(security.ROOT, id, 'latest');
    } else {
      return insertIdToRoute(FORM, id);
    }
  }, [data, id]);

  const canSeeContent = useMemo(() => {
    const { role, agencyId, id, agency } = currentAgent || {};

    if (role === AgentRole.TELIVY_ADMIN) return true;

    if (role === AgentRole.AGENCY_ADMIN && agency?.isMasterAgency) {
      return data?.agent?.agency?.masterAgencyId === agencyId || data?.agent.agencyId === agencyId;
    }

    if (role === AgentRole.AGENCY_ADMIN) {
      return data?.agent.agencyId === agencyId;
    }

    return data?.agent.id === id;
  }, [currentAgent, data?.agent.agencyId, data?.agent?.agency?.masterAgencyId, data?.agent.id]);

  if (currentAgent && !currentAgent.isEmailVerified) {
    return <VerifyEmail />;
  }

  if (data && currentAgent && !canSeeContent) {
    return (
      <EmptyState title='Forbidden' text={<>You do not have access to view this page.</>} icon={<LockOutlinedIcon />} />
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {`${data ? (data.deepScanId ? 'Risk' : 'External') : ''} Assessment - ${
            data?.applicationResponse?.organization_name
          }`}
        </title>
      </Helmet>

      <div className={classes.header}>
        {data && !isLoading && (
          <>
            <div className={classes.details}>
              <div className={classes.actionsMainContainer}>
                <div className={classes.infoWrapper}>
                  {isSecurityApplication(data) && !isScanApplication(data) && (
                    <OverallScoreBadge variant='big' securityOverallScore={securityOverallScore} />
                  )}
                  <div>
                    <div className={classes.actionsContainer}>
                      <h1 className={classes.title}>{data.applicationResponse.organization_name}</h1>
                      <EditAssessmentButton
                        application={data}
                        className={classes.actionButton}
                        locked={externalScanDisabled}
                      />
                      <ApplicationStatusLabel
                        className={classes.status}
                        status={data.applicationStatus}
                        onClick={isAgentAnAdmin(agent) ? () => setIsModalOpen(true) : undefined}
                        buttonRef={applicationStatusButtonRef}
                        isAdmin={isAgentAnAdmin(agent)}
                      />
                      <ChangeStatusModal
                        isOpen={isModalOpen}
                        application={data}
                        onClose={() => setIsModalOpen(false)}
                        buttonRef={applicationStatusButtonRef}
                        isAdmin={isAgentAnAdmin(agent)}
                      />
                    </div>
                    {isSecurityApplication(data) && (
                      <h2 className={classes.subtitle}>{data.applicationResponse.domain_prim}</h2>
                    )}
                  </div>
                </div>
                {isSecurityApplication(data) && !isScanApplication(data) && (
                  <div className={classes.monitoringFormContainer}>
                    {/*<MonitoringSwitch
                      applicationId={data.id}
                      monitoringEnabled={data.monitoringEnabled}
                      className={classes.monitoringForm}
                    />*/}
                    <ShareApplicationButton
                      applicationId={data.id}
                      applicationStatus={data.applicationStatus}
                      billingEnabled={data.billingEnabled}
                      className={classes.monitoringForm}
                      skipBillingSettings={isSecurityApplication(data)}
                      isAdmin={isAgentAnAdmin(agent)}
                      emailEnabled={data.emailEnabled}
                    />
                    <MarkArchivedButton
                      applicationId={data.id}
                      isArchived={data.applicationStatus == ApplicationStatus.ARCHIVED}
                      className={classes.monitoringForm}
                    />
                    <ConvertButton
                      applicationId={data.id}
                      conversionPermitted={true}
                      className={classes.monitoringForm}
                    />
                  </div>
                )}
                {isScanApplication(data) && (
                  <div>
                    {data.isLightScan && (
                      <RequestForReportButton
                        applicationId={data.id}
                        isRequestedForReport={data.applicationStatus == ApplicationStatus.REPORT_REQUESTED}
                        requestPermitted={true}
                        className={classes.monitoringForm}
                      />
                    )}
                    <ShareApplicationButton
                      applicationId={data.id}
                      applicationStatus={data.applicationStatus}
                      billingEnabled={data.billingEnabled}
                      className={classes.monitoringForm}
                      skipBillingSettings={isSecurityApplication(data)}
                      isAdmin={isAgentAnAdmin(agent)}
                      emailEnabled={data.emailEnabled}
                    />
                    <MarkArchivedButton
                      applicationId={data.id}
                      isArchived={data.applicationStatus == ApplicationStatus.ARCHIVED}
                      className={classes.monitoringForm}
                    />
                    {!data.monitoringEnabled && monitoringEnabled && (
                      <ConvertMonitoringButton
                        applicationId={data.id}
                        monitoringFrequency={data.monitoringFrequency}
                        conversionPermitted={true}
                        className={classes.monitoringForm}
                      />
                    )}
                  </div>
                )}
              </div>
              {!isSecurityApplication(data) && (
                <p className={classes.effectiveDate}>
                  Assessment Type: {data.insuranceLine} &middot; Target Effective: <EffectiveDate application={data} />
                </p>
              )}
            </div>
            <Menu application={data} className={classes.menu} agent={agent} />
          </>
        )}
      </div>

      <main className={cx(classes.main, isLoading && classes.center)}>
        {data && !isLoading ? (
          <Switch>
            <Route exact path={ROOT} render={() => <Redirect to={rootRoute} />} />
            <Route path={FORM} render={() => <ApplicationFormView application={data} />} />
            <Route
              path={security.ROOT}
              render={() => (
                <ApplicationSecurityView application={data} setSecurityOverallScore={setSecurityOverallScore} />
              )}
            />
            <Route path={HISTORY} render={() => <ApplicationHistoryView application={data} />} />
            <Route
              path={MONITORING}
              render={() => <ApplicationMonitoringView application={data} isAdmin={isAgentAnAdmin(agent)} />}
            />
            <Route path={QUOTES} render={() => <ApplicationQuotesView application={data} agent={agent} />} />
            <Route path={PROPOSAL} render={() => <ApplicationProposalView application={data} />} />
            <Route path={DOCUMENTS} render={() => <ApplicationDocumentsView application={data} agent={agent} />} />
            <Route
              path={REPORTS}
              render={() => <ApplicationDocumentsView application={data} agent={agent} reports />}
            />
            <Route path={SERVICES} render={() => <ApplicationServicesView application={data} />} />
            <Route path={POLICIES} render={() => <ApplicationPoliciesView application={data} />} />
            <Route
              path={CONFIGURATION}
              render={() => (
                <ApplicationConfigurationView application={data} setSecurityOverallScore={setSecurityOverallScore} />
              )}
            />
            <Route
              path={assets.ROOT}
              render={() => <ApplicationAssetsView application={data} sourceRoute={ROUTES.agent.application} />}
            />
          </Switch>
        ) : (
          <LoadingContainer />
        )}
      </main>
    </div>
  );
};
