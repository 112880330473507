import { makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Modal } from 'components/Modal';
import React from 'react';
import { QUOTE_INFO_MAP, QuoteInfoMap } from 'telivy-maps';
import { COLORS } from 'telivy-theme';

interface StylesProps {
  iconRight?: boolean;
  iconColor?: ValueOf<typeof COLORS>;
}

const useStyles = makeStyles((theme) => ({
  modalWrapper: ({ iconRight }: StylesProps) => ({
    display: 'flex',
    flexDirection: 'row',

    ...(iconRight && {
      justifyContent: 'space-between',
    }),
  }),
  infoIcon: ({ iconRight, iconColor }: StylesProps) => ({
    marginTop: theme.spacing(0.125),
    fontSize: theme.spacing(2),
    color: iconColor ? iconColor : COLORS.GREY_3,
    ...(iconRight && {
      marginLeft: theme.spacing(0.5),
    }),
    ...(!iconRight && {
      marginBottom: theme.spacing(-0.25),
      marginRight: theme.spacing(0.5),
    }),
  }),
}));

interface Props extends StylesProps {
  name: keyof QuoteInfoMap;
}

export const ProposalInfoModal = ({ name, iconColor, iconRight }: Props) => {
  const classes = useStyles({ iconColor, iconRight });
  const element = QUOTE_INFO_MAP[name];

  return (
    <div className={classes.modalWrapper}>
      {iconRight && element?.title}
      <Modal title={element?.title || ''} openButton={<InfoOutlinedIcon className={classes.infoIcon} />}>
        <p>{element?.description}</p>
      </Modal>
      {!iconRight && element?.title}
    </div>
  );
};
