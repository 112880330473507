import { Button, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { PolicyDTO } from 'dtos/policy';
import { useToasters } from 'hooks/useToasters';
import { useSignPolicy } from 'queries/usePolicy';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  signButton: {
    [theme.breakpoints.up('md')]: {
      ...TYPOGRAPHY.SMALL_MEDIUM,
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  },
  completeButton: {
    borderColor: COLORS.GREEN_1,
    color: COLORS.GREEN_1,

    '&:disabled': {
      borderColor: COLORS.GREEN_1,
      color: COLORS.GREEN_1,
    },
  },
}));

interface Props {
  policy: PolicyDTO;
  isChecking: boolean;
}

export const SignButton = ({ policy, isChecking }: Props) => {
  const classes = useStyles();
  const { toasterErrorHandler } = useToasters();
  const { mutate, isLoading, isSuccess } = useSignPolicy(policy.id, {
    onError: (e) => toasterErrorHandler(e),
  });

  const text = useMemo(() => {
    if (policy.attestationDone) {
      return 'Signed';
    }

    return 'Sign';
  }, [policy.attestationDone]);

  const handleClick = () => {
    mutate();
  };

  return (
    <Button
      variant='contained'
      color='primary'
      className={cx(classes.signButton, policy.attestationDone && classes.completeButton)}
      disabled={policy.attestationDone || isChecking || isLoading || isSuccess}
      onClick={handleClick}
      data-cy='signing-sign-button'
    >
      {isChecking ? 'Checking...' : isLoading || isSuccess ? 'Redirecting...' : text}
    </Button>
  );
};
