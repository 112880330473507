import { SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '50vh',
    padding: theme.spacing(5),
  },

  // Content Header styles
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
  },
  title: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },
  content: {
    position: 'relative',
  },

  // Main
  container: {
    flex: 1,
    position: 'relative',
  },

  // Sidebar
  sidebar: {
    borderLeft: `1px solid ${COLORS.GREY_5}`,
    minWidth: 370,
    maxWidth: '40vw',
    position: 'relative',
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    border: 0,
    padding: 0,
    background: 0,
    cursor: 'pointer',
    right: theme.spacing(2),
    top: theme.spacing(2),

    '& svg': {
      display: 'block',
    },
  },
}));

interface Props {
  title?: string;
  actions?: React.ReactNode | React.ReactNode[];
  sidebar?: React.ReactNode;
  drawerOpen?: boolean;
  setDrawerOpen?: (state: boolean) => void;
  onSidebarCloseClick?: () => void;
}

export const ApplicationViewContainer: React.FC<Props> = ({
  onSidebarCloseClick,
  title,
  actions,
  sidebar,
  children,
  drawerOpen,
  setDrawerOpen,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          {title && <h1 className={classes.title}>{title}</h1>}

          {actions && <div className={classes.actionsContainer}>{actions}</div>}
        </div>
        <div className={classes.content}>{children}</div>
      </div>
      {sidebar && (
        <SwipeableDrawer
          anchor='right'
          open={!!drawerOpen}
          onOpen={() => (setDrawerOpen ? setDrawerOpen(true) : undefined)}
          onClose={() => (onSidebarCloseClick ? onSidebarCloseClick() : undefined)}
          disableDiscovery={true}
          disableSwipeToOpen={true}
        >
          <div className={classes.sidebar}>
            <button className={classes.closeButton} onClick={onSidebarCloseClick}>
              <CloseIcon />
            </button>
            {sidebar}
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
};
