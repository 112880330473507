/* eslint-disable indent */
import { Button, CircularProgress, IconButton, makeStyles, useTheme } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import cx from 'classnames';
import { Badge, BadgeVariant } from 'components/Badge';
import { LinkButton } from 'components/LinkButton';
import { Modal } from 'components/Modal';
import { formatNumberWithCommas, Money } from 'components/Money';
import {
  ApplicationStatus,
  ApplicationWithAgentDTO,
  Finding,
  SecurityScanDTO,
  SecurityScanStatus,
} from 'dtos/application';
import { uniq } from 'lodash';
import moment from 'moment';
import { useCurrentAgent } from 'queries/useAgent';
import { useDeepScanReportData } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { createSecurityRoute, insertIdToRoute, ROUTES } from 'telivy-constants';
import { isAgentAnAdmin, isScanApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { SourceRoute } from '..';
import { ActionItem, ActionItems } from '../components/ActionItems';
import { ScoreSecurityCard } from '../components/common/ScoreSecurityCard';
import { DarkWebPresenceChart } from '../components/DarkWebPresenceChart';
import { DomainInfo } from '../components/DomainInfo';
import { ExecutiveSummary } from '../components/ExecutiveSummary';
import { ExternalScanRadarChart } from '../components/ExternalScanRadarChart';
import { FinancialRiskCalculator } from '../components/FinancialRiskCalculator';
import { GaugeChart, mapSecurityScoreLabel, MarkerType } from '../components/GaugeChart';
import { InsurabilityScoreCircularProgress } from '../components/insurability/InsurabilityScoreCircularProgress';
import { InsurabilityScoreComponentsTable } from '../components/insurability/InsurabilityScoreComponentsTable';
import { RescanButton } from '../components/RescanButton';
import { SecurityCard } from '../components/SecurityCard';
// import { TechstackTools } from '../components/techstack/TechstackTools';
import { VerticalStepper } from '../components/VerticalStepper';
import { SecurityExternalScan } from './SecurityExternalScan';

export const useStyles = makeStyles((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  singleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),

    ['@media print']: {
      display: 'block',
    },
  },
  doubleColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  quadrubleColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  pentaColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },

  gaugeColumn: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },
  gaugeColumnOneItem: {
    gridTemplateColumns: '1fr',
  },

  securityCard: {
    flex: 1,
  },

  securityScoreTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.BLUE_1,
    textTransform: 'uppercase',
  },
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.25),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },

  centerButton: {
    margin: '0 auto',

    '& span': {
      ...TYPOGRAPHY.SMALL_BOLD,
    },
  },

  greyText: {
    color: COLORS.GREY_2,

    '& b': {
      color: COLORS.GREY_1,
    },
  },
  redText: {
    color: COLORS.RED_1,
  },
  centerText: {
    textAlign: 'center',
  },

  stepperItem: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },

  modalButton: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.BLUE_1,
    textDecoration: 'underline',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },

  modalButtonIcon: {
    fontSize: 14,
    marginRight: theme.spacing(0.25),
  },

  editButton: {
    padding: theme.spacing(0.5),
    color: COLORS.GREY_4,
  },
  redLinkButton: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: `${COLORS.RED_1} !important`,
  },
  blueLinkButton: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: `${COLORS.BLUE_1} !important`,
  },

  gaugeSecurityCardContent: {
    position: 'relative',
  },
  gaugeWrapper: {
    width: '100%',
    height: GAUGE_GRAPH_HEIGHT,
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
  },
  gaugeSecurityCardContentInnerContent: {
    marginTop: GAUGE_GRAPH_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(),
  },

  radarSecurityCardContent: {
    position: 'relative',
    minHeight: RADAR_GRAPH_HEIGHT + 35,
  },
  radarWrapper: {
    width: '100%',
    height: RADAR_GRAPH_HEIGHT,
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
  },
  radarSecurityCardContentInnerContent: {
    marginTop: RADAR_GRAPH_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(),
  },

  darkWebWrapper: {
    // [theme.breakpoints.up('lg')]: {
    //   maxWidth: 'calc(50vw - 80px)',
    // },
  },

  insurabilityMarketsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
  },

  lastScanned: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    // color: COLORS.GREY_2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    fontSize: '10px !important',
    color: 'grey',
  },
  scanning: {
    marginRight: theme.spacing(1),
  },
  viewLatest: {
    marginLeft: theme.spacing(1),
  },
}));

const GAUGE_GRAPH_HEIGHT = 200;
const RADAR_GRAPH_HEIGHT = 300;

interface Props {
  securityScan: SecurityScanDTO;
  isNotSubmitted?: boolean;
  onViewQuestionnaireClick: () => void;
  applicationId: string;
  handleOpenExternalScan: () => void;
  sourceRoute: SourceRoute;
  securityScanId: string;
  application: ApplicationWithAgentDTO;
}

export const SecurityOverview = ({
  securityScan,
  onViewQuestionnaireClick,
  applicationId,
  handleOpenExternalScan,
  sourceRoute,
  isNotSubmitted,
  securityScanId,
  application,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { data: user } = useCurrentAgent();
  const isExternalScan = !application.isDeepScan && !application.isLightScan;
  const isAdmin = isAgentAnAdmin(user);

  const [isSavingFinancialCalculator, setIsSavingFinancialCalculator] = useState(false);

  const dataBreachCost = securityScan.securityStats.dataBreachItems.reduce((prev, curr) => prev + curr.cost, 0);

  const dataBreachItems = securityScan.securityStats.dataBreachItems.map((item) => ({
    title: <span className={classes.stepperItem}>${formatNumberWithCommas(Math.trunc(item.cost))}</span>,
    description: item.name,
  }));

  const securityGraphMarkers = [
    { label: 'Your score', value: securityScan.securityStats?.securityScore?.businessScore.total },
    {
      label: 'Industry Average',
      value: securityScan.securityStats?.securityScore?.industryAverage,
      type: MarkerType.BLACK,
      showLabel: true,
    },
  ];

  const insurabilityGraphMarkers = [
    { label: 'Your score', value: securityScan.securityStats?.insurabilityScore?.businessScore.total },
    {
      label: 'Industry Average',
      value: securityScan.securityStats?.insurabilityScore?.industryAverage,
      type: MarkerType.BLACK,
      showLabel: true,
    },
  ];

  const handleGoToForm = () => history.push(createSecurityRoute(sourceRoute.FORM, applicationId));

  const handleGoToFinding = (findingId?: string) =>
    history.push(
      createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScanId, findingId ? findingId : ''),
    );

  const actionItems: ActionItem[] = useMemo(() => {
    if (!securityScan.insurabilityScan) return [];

    return ([] as Finding[])
      .concat(
        securityScan.insurabilityScan.insurability.highSeverity ?? [],
        securityScan.insurabilityScan.insurability.mediumSeverity ?? [],
        securityScan.insurabilityScan.insurability.lowSeverity ?? [],
      )
      .filter((f) => f.values.length);
  }, [securityScan]);

  const { data: reportData, isLoading } = useDeepScanReportData(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const isDataVisible = useMemo(() => {
    return (
      isAdmin ||
      (securityScan?.securityOverallScore &&
        ![
          ApplicationStatus.CONFIGURATION_PENDING,
          ApplicationStatus.CONFIGURATION_DONE,
          ApplicationStatus.DATA_PENDING,
        ].includes(application.applicationStatus))
    );
  }, [isAdmin, securityScan?.securityOverallScore, application.applicationStatus]);

  const overviewCards = useMemo(() => {
    if (isScanApplication(application)) {
      if (!isDataVisible) return;

      if (isLoading || !reportData) return <CircularProgress thickness={5} className={classes.scanning} />;

      return (
        <>
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Network
                <br />
                Security
              </>
            }
            score={(reportData.networkSecurity.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Application
                <br />
                Security
              </>
            }
            score={(reportData.applicationSecurity.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Data
                <br />
                Security
              </>
            }
            score={(reportData.dataSecurity.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Identity and
                <br />
                Access Management
              </>
            }
            score={(reportData.identityAccessManagement.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Dark Web
                <br />
                Presence
              </>
            }
            score={(reportData.securityAwarenessTraining.score || 0) * 100}
          />
        </>
      );
    } else {
      return (
        <>
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Overall
                <br />
                Score
              </>
            }
            score={securityScan?.securityOverallScore || 0}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                External
                <br />
                Score
              </>
            }
            score={((securityScan?.securityScore || 0) * 100) / 5}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Assessment
                <br />
                Score
              </>
            }
            score={securityScan?.securityStats?.securityScore?.businessScore?.total || 0}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Domains
                <br />
                IP Addresses
              </>
            }
            score={
              uniq(
                [securityScan?.domain]
                  .concat(
                    securityScan?.subDomains || [],
                    securityScan?.ipAddresses || [],
                    securityScan?.otherDomains || [],
                  )
                  .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
              ).length
            }
            skipGrading
          />
        </>
      );
    }
  }, [securityScan, application, classes, isLoading, reportData, isDataVisible]);

  return (
    <div className={classes.root}>
      {/* SCORES */}
      <div className={isScanApplication(application) ? classes.pentaColumn : classes.quadrubleColumn}>
        {overviewCards}
      </div>
      <div className={classes.doubleColumn} style={{ display: isScanApplication(application) ? 'none' : 'block' }}>
        {/* INSURABILITY & SECURITY SCORE */}
        <div
          className={cx(
            classes.gaugeColumn,
            (!securityScan.securityStats?.insurabilityScore || !securityScan.securityStats?.insurabilityScore) &&
              classes.gaugeColumnOneItem,
          )}
        >
          {/* INSURABILITY SCORE */}
          {securityScan.securityStats?.insurabilityScore && (
            <div className={classes.column}>
              <SecurityCard.Container className={classes.securityCard}>
                <SecurityCard.Header
                  title={
                    <span>
                      Insurability Score
                      {!isNotSubmitted && (
                        <>
                          :{' '}
                          <span className={classes.securityScoreTitle}>
                            {mapSecurityScoreLabel(securityScan.securityStats?.insurabilityScore?.businessScore.total)}
                          </span>
                        </>
                      )}
                      <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Insurability Score'>
                        <p className={classes.greyText}>This Score is based on the answers in your questionaire.</p>
                      </Modal>
                    </span>
                  }
                />
                <SecurityCard.Content
                  gap={theme.spacing()}
                  isNotSubmitted={isNotSubmitted}
                  handleGoToForm={handleGoToForm}
                  className={classes.gaugeSecurityCardContent}
                >
                  <div className={classes.gaugeWrapper}>
                    <GaugeChart
                      value={securityScan.securityStats?.insurabilityScore.businessScore.total}
                      markers={insurabilityGraphMarkers}
                    />
                  </div>

                  <div className={classes.gaugeSecurityCardContentInnerContent}>
                    {securityScan.securityStats?.insurabilityScore?.businessScore && (
                      <>
                        <Badge
                          variant={getInsurabilityScoreBadgeVariant(
                            securityScan.securityStats?.insurabilityScore?.businessScore?.total || 0,
                          )}
                          bordered
                          borderWidth={2}
                          style={{ display: 'block', padding: '8px 16px' }}
                          typography={TYPOGRAPHY.REGULAR_MEDIUM}
                        >
                          <span style={{ color: 'black' }}>Insurability score:</span>{' '}
                          <b>{Math.round(securityScan.securityStats?.insurabilityScore?.businessScore?.total || 0)}%</b>
                        </Badge>

                        {securityScan?.securityStats?.insurabilityScore?.businessScore?.insurabilityScoreComponents ? (
                          <Modal
                            removePaddingTop
                            title='Insurability Score'
                            openButton={
                              <span className={classes.modalButton}>
                                <InfoRoundedIcon className={classes.modalButtonIcon} />
                                Learn more
                              </span>
                            }
                            // actions={(handleClose) => (
                            //   <>
                            //     <Button onClick={handleClose}>Close</Button>
                            //     <Button
                            //       variant='contained'
                            //       color='primary'
                            //       onClick={() => {
                            //         history.push(
                            //           createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScanId),
                            //         );
                            //       }}
                            //     >
                            //       View Action Items
                            //     </Button>
                            //   </>
                            // )}
                          >
                            <InsurabilityScoreComponentsTable
                              components={
                                securityScan?.securityStats?.insurabilityScore?.businessScore
                                  ?.insurabilityScoreComponents
                              }
                            />
                          </Modal>
                        ) : null}
                      </>
                    )}
                    {securityScan?.securityStats?.insurabilityScore?.businessScore?.allMarketsNumber !== undefined &&
                    securityScan?.securityStats?.insurabilityScore?.businessScore?.eligibleMarketsNumber !==
                      undefined &&
                    securityScan?.securityStats?.insurabilityScore?.businessScore?.quotableMarketsNumber !==
                      undefined ? (
                      <div className={classes.insurabilityMarketsWrapper}>
                        <InsurabilityScoreCircularProgress
                          total={securityScan?.securityStats?.insurabilityScore?.businessScore?.allMarketsNumber}
                          items={securityScan?.securityStats?.insurabilityScore?.businessScore?.eligibleMarketsNumber}
                          title='Addressable Market:'
                          label='carriers'
                          learnMoreSection={
                            <Modal
                              openButton={
                                <span className={classes.modalButton}>
                                  <InfoRoundedIcon className={classes.modalButtonIcon} />
                                  Learn more
                                </span>
                              }
                              title='Addressable Market'
                            >
                              <p>
                                We track the commercial appetite of 64 insurance carriers and apply primary filters to
                                determine those interested in quoting a given company. These filters include: prior
                                claims and breaches, industry classification, and size (revenue and/or number or value
                                of records).
                              </p>
                              <p style={{ fontSize: 14, fontWeight: 700 }}>Why is this Relevant: </p>
                              <p style={{ marginTop: 0 }}>
                                The insurance market employs the highest number of quantitative analysts, and they
                                review the underwriting performance and threat vectors of tens of millions of applicants
                                and insureds every year. This insurability assessment helps rank which controls are most
                                pertinent based on their research.
                              </p>
                            </Modal>
                          }
                        />
                        <InsurabilityScoreCircularProgress
                          total={securityScan?.securityStats?.insurabilityScore?.businessScore?.eligibleMarketsNumber}
                          items={securityScan?.securityStats?.insurabilityScore?.businessScore?.quotableMarketsNumber}
                          title='Quotable Market:'
                          label='carriers'
                          learnMoreSection={
                            <Modal
                              openButton={
                                <span className={classes.modalButton}>
                                  <InfoRoundedIcon className={classes.modalButtonIcon} />
                                  Learn more
                                </span>
                              }
                              title='Quotable Market'
                            >
                              <p>
                                After the primary filter is applied and the addressable market is determined, our
                                secondary and tertiary filters include internal controls, governance, and external
                                security posture. We can then estimate the number of carriers that would quote versus
                                the addressable market.
                              </p>
                              <p style={{ fontSize: 14, fontWeight: 700 }}>Why is this Relevant: </p>
                              <p style={{ marginTop: 0 }}>
                                The insurance market employs the highest number of quantitative analysts, and they
                                review the underwriting performance and threat vectors of tens of millions of applicants
                                and insureds every year. This insurability assessment helps rank which controls are most
                                pertinent based on their research.
                              </p>
                            </Modal>
                          }
                        />
                      </div>
                    ) : null}
                    {/* <Button className={classes.centerButton} onClick={onViewQuestionnaireClick}>
                    View Questionnaire
                  </Button> */}
                  </div>
                </SecurityCard.Content>
              </SecurityCard.Container>
            </div>
          )}

          {/* SECURITY SCORE */}
          {securityScan.securityStats?.securityScore && (
            <div className={classes.column}>
              <SecurityCard.Container className={classes.securityCard}>
                <SecurityCard.Header
                  title={
                    <span>
                      Security Score
                      {!isNotSubmitted && (
                        <>
                          :{' '}
                          <span className={classes.securityScoreTitle}>
                            {mapSecurityScoreLabel(securityScan.securityStats?.securityScore?.businessScore.total)}
                          </span>
                        </>
                      )}
                      <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Security Score'>
                        {/* <p className={cx(classes.greyText, classes.centerText)}> */}
                        <p className={classes.greyText}>This Score is based on the answers in your questionaire.</p>
                      </Modal>
                    </span>
                  }
                />
                <SecurityCard.Content
                  gap={theme.spacing(1.5)}
                  isNotSubmitted={isNotSubmitted}
                  handleGoToForm={handleGoToForm}
                  className={classes.gaugeSecurityCardContent}
                >
                  <div className={classes.gaugeWrapper}>
                    <GaugeChart
                      value={securityScan.securityStats?.securityScore.businessScore.total}
                      markers={securityGraphMarkers}
                    />
                  </div>
                  <div className={classes.gaugeSecurityCardContentInnerContent}>
                    <Button className={classes.centerButton} onClick={onViewQuestionnaireClick}>
                      View Questionnaire
                    </Button>
                  </div>
                </SecurityCard.Content>
              </SecurityCard.Container>
            </div>
          )}
        </div>

        {/* ACTION ITEMS */}
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title={
                <span>
                  Action Items to Improve Your Score
                  <a
                    href='https://telivy.notion.site/Improving-your-Security-Posture-03434f720a3746e4a1a240dd95029fc3'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <InfoRoundedIcon className={classes.infoIcon} />
                  </a>
                </span>
              }
            />
            <SecurityCard.Content
              gap={8}
              isNotSubmitted={isNotSubmitted}
              handleGoToForm={handleGoToForm}
              padding='0 24px 0 24px'
            >
              <ActionItems items={actionItems} handleGoToFinding={handleGoToFinding} />
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      </div>

      {/* EXECUTIVE SUMMARY */}
      {isScanApplication(application) && !isDataVisible && (
        <SecurityCard.Badge variant='info'>
          We are gathering data from your configuration, please check back later!
        </SecurityCard.Badge>
      )}
      {isScanApplication(application) && !!isDataVisible && (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header title='Executive Summary' />
            <SecurityCard.Content gap={theme.spacing(2.5)}>
              <ExecutiveSummary reportData={reportData} isLoading={isLoading} />
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      )}

      {/* FINANCIAL EXPOSURE */}
      <div className={classes.column}>
        <SecurityCard.Container className={classes.securityCard}>
          <SecurityCard.Header title='Financial Exposure' />
          <SecurityCard.Content gap={theme.spacing(2.5)}>
            <div className={classes.doubleColumn} style={{ minHeight: 350, gap: 64 }}>
              <div>
                <div>
                  <p className={classes.greyText}>
                    <b>Ransomware Cost</b>
                  </p>

                  <Money
                    value={
                      securityScan.securityStats.ransomwareCost
                        ? Math.trunc(securityScan.securityStats.ransomwareCost)
                        : 0
                    }
                    typography={TYPOGRAPHY.TITLE_3}
                    isLoading={isSavingFinancialCalculator}
                    color={COLORS.RED_1}
                  />

                  <p className={classes.greyText}>
                    <b>70%</b> of all cyber attacks are ransomware.{' '}
                    <a
                      href='https://telivy.notion.site/Ransomware-05292a9956234d1d82433caf0071d36b'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Learn more
                    </a>
                  </p>
                </div>
                <br />

                <div>
                  <p className={classes.greyText}>
                    <b>Interruption Cost</b>
                  </p>

                  <Money
                    value={
                      securityScan.securityStats.businessInterruptionCost
                        ? Math.trunc(securityScan.securityStats.businessInterruptionCost)
                        : 0
                    }
                    typography={TYPOGRAPHY.TITLE_3}
                    isLoading={isSavingFinancialCalculator}
                    color={COLORS.RED_1}
                  />

                  <p className={classes.greyText}>
                    Lost business represented <b>38%</b> of the overall loss.{' '}
                    <a
                      href='https://telivy.notion.site/Business-Interruption-bb93e193f2794867847edbfd2ffc8415'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Learn more
                    </a>
                  </p>
                </div>
                <br />

                <div>
                  <p className={classes.greyText}>
                    <b>Data Breach Cost</b>
                  </p>
                  <Money
                    value={dataBreachCost}
                    typography={TYPOGRAPHY.TITLE_3}
                    isLoading={isSavingFinancialCalculator}
                    color={COLORS.RED_1}
                  />

                  <div>
                    <p className={classes.greyText}>
                      <b>80%</b> of the breaches included records containing customer PII, at an average cost of{' '}
                      <b>$150</b> per record.{' '}
                      <a
                        href='https://telivy.notion.site/Data-Breach-891d5dec117546f7b034a313cd494d04'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Learn more
                      </a>
                    </p>

                    <br />

                    <Modal
                      openButton={<LinkButton className={classes.modalButton}>Show Cost Breakup</LinkButton>}
                      title='Data Breach Cost Breakup'
                      padding={4}
                      autoWidth
                    >
                      <div>
                        <VerticalStepper
                          mainValue={
                            <Money value={dataBreachCost} typography={TYPOGRAPHY.TITLE_3} className={classes.redText} />
                          }
                          items={dataBreachItems}
                        />
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
              <div>
                <FinancialRiskCalculator
                  application={application}
                  setIsSavingFinancialCalculator={setIsSavingFinancialCalculator}
                />
              </div>
            </div>
          </SecurityCard.Content>
        </SecurityCard.Container>
      </div>

      {/* EXTERNAL SCAN */}
      {isExternalScan || isAdmin ? (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title='External Surface Exposure'
              actions={
                <>
                  {securityScanId === 'latest' && (
                    <RescanButton applicationId={applicationId} securityScanId={securityScanId} isScanning={false} />
                  )}
                  <IconButton onClick={handleOpenExternalScan}>
                    <LaunchRoundedIcon />
                  </IconButton>
                </>
              }
            />
            {!isScanApplication(application) &&
              (!application?.applicationResponse?.primary_operations ||
                !application?.applicationResponse?.annual_revenue) && (
                <SecurityCard.Badge variant='info'>
                  Please{' '}
                  <Link to={`${insertIdToRoute(ROUTES.agent.application.FORM, application.id)}?section=operations`}>
                    fill out industry & revenue
                  </Link>{' '}
                  to compare scores against benchmarks
                </SecurityCard.Badge>
              )}
            <SecurityCard.Content gap={theme.spacing(1.5)} handleGoToForm={handleGoToForm}>
              <div className={classes.doubleColumn}>
                <div className={classes.radarSecurityCardContent} id='score-benchmark-external-surface-exposure-chart'>
                  <div className={classes.radarWrapper}>
                    <ExternalScanRadarChart securityScan={securityScan} />
                  </div>
                </div>
                <div>
                  <SecurityExternalScan
                    sourceRoute={sourceRoute}
                    tile
                    securityScan={securityScan}
                    applicationId={applicationId}
                    securityScanId={securityScanId}
                  />
                  {securityScan.status != SecurityScanStatus.COMPLETED && (
                    <span className={classes.lastScanned}>
                      <CircularProgress size={15} thickness={5} className={classes.scanning} />
                      Scanning {<DomainInfo securityScan={securityScan} />}...
                    </span>
                  )}
                  {securityScan.status == SecurityScanStatus.COMPLETED && securityScan.updatedAt && (
                    <span className={classes.lastScanned}>
                      {securityScanId == 'latest' && (
                        <>
                          <span>Last scanned</span>
                          <DomainInfo securityScan={securityScan} /> on:{' '}
                        </>
                      )}
                      {securityScanId != 'latest' && 'Historical report as on: '}
                      {moment(securityScan.updatedAt).format('Do MMMM YYYY hh:mm:ss A')}
                      {securityScanId != 'latest' && (
                        <Link
                          replace
                          className={classes.viewLatest}
                          to={createSecurityRoute(sourceRoute.security.OVERVIEW, applicationId, 'latest')}
                        >
                          View Latest
                        </Link>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      ) : null}

      {/* DARK WEB PRESENCE */}
      <div className={classes.column}>
        <SecurityCard.Container className={classes.securityCard}>
          <SecurityCard.Header title='Dark Web Presence' />
          <SecurityCard.Content gap={8} className={classes.darkWebWrapper}>
            <DarkWebPresenceChart
              securityScan={securityScan}
              findingsUrl={createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScan.id, '')}
              applicationId={applicationId}
            />
          </SecurityCard.Content>
        </SecurityCard.Container>
      </div>

      {/* SERVICES DETECTED */}
      {/*isAdmin && (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title='Services Detected'
              actions={
                <LinkButton
                  className={classes.blueLinkButton}
                  onClick={() =>
                    history.push(
                      createSecurityRoute(sourceRoute.security.TECHSTACK, applicationId, securityScan.id, ''),
                    )
                  }
                >
                  View all
                </LinkButton>
              }
            />
            <SecurityCard.Content handleGoToForm={handleGoToForm} maxHeight={theme.spacing(44)}>
              <TechstackTools tile application={application} />
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      )*/}
    </div>
  );
};

const getInsurabilityScoreBadgeVariant = (score: number): BadgeVariant => {
  if (score > 50) {
    return 'blue';
  }
  if (score > 25) {
    return 'yellow';
  }
  if (score > 0) {
    return 'red';
  }
  return 'darkRed';
};
