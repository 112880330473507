import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { AxiosError } from 'axios';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { ApplicationWithAgentDTO, SecurityScanStatus } from 'dtos/application';
import { useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { generateRefetchIntervalMs, insertIdToRoute, ROUTES } from 'telivy-constants';
import { isSecurityApplication } from 'telivy-selectors';
import { SecurityAssessments } from 'templates/SecurityAssessments';

import { ApplicationViewContainer } from '../ApplicationViewContainer';

interface ParamsType {
  securityScanId: string;
}

interface Props {
  application: ApplicationWithAgentDTO;
  setSecurityOverallScore: (score: number | null) => void;
}

export const ApplicationSecurityView = ({ application, setSecurityOverallScore }: Props) => {
  const history = useHistory();
  const { securityScanId } = useParams<ParamsType>();
  const [errorMessage, setErrorMessage] = useState('');

  const { data, isLoading, isError } = useApplicationSecurityScan(application.id, securityScanId, {
    refetchInterval: (data) =>
      data && data?.status !== SecurityScanStatus.COMPLETED ? generateRefetchIntervalMs() : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (e) => {
      const response = (e as AxiosError<{ message: string }>).response;
      if (response) {
        setErrorMessage(response.data.message);
      }
    },
  });

  const deepScanId = application?.deepScanId || '';
  const { data: deepScan } = useDeepScan(deepScanId, { enabled: Boolean(deepScanId) });

  useEffect(() => {
    if (data) {
      setSecurityOverallScore(data.securityOverallScore);
    }
  }, [data, setSecurityOverallScore]);

  if (!isSecurityApplication(application) && !application.agent.securityEnabled) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
        <p></p>
      </ApplicationViewContainer>
    );
  }

  if (errorMessage) {
    return <NotFoundContainer message={errorMessage} />;
  }

  if (isLoading || !data?.securityStats) {
    return <LoadingContainer />;
  }

  if (isError || !data) {
    return <NotFoundContainer />;
  }

  return (
    <ApplicationViewContainer>
      <SecurityAssessments
        sourceRoute={ROUTES.agent.application}
        applicationId={application.id}
        application={application}
        securityScan={data}
        deepScan={deepScan}
        onViewQuestionnaireClick={() => history.push(insertIdToRoute(ROUTES.agent.application.FORM, application.id))}
      />
    </ApplicationViewContainer>
  );
};
