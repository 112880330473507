import { Button, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import { ApplicationStatus } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useUpdateApplicationSettings } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(3),
    '& > h2': {
      ...TYPOGRAPHY.TITLE_3,
    },
  },

  dialogContent: {
    paddingTop: `0 !important`,
    paddingBottom: `${theme.spacing(4)}px !important`,
  },

  dialogContentText: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(3),
  },

  emailEnabledSection: {
    marginTop: theme.spacing(3),
  },

  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: 0,

    '& + &': {
      marginTop: theme.spacing(2),
    },
  },

  radioGroupTitle: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(),
  },

  radioLabel: {
    ...TYPOGRAPHY.REGULAR_MEDIUM,
  },

  radioDescription: {
    marginLeft: theme.spacing(4),
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },
}));

interface Props {
  applicationId: string;
  applicationStatus: ApplicationStatus;
  billingEnabled: boolean;
  skipBillingSettings?: boolean;
  className?: string;
  isAdmin?: boolean;
  emailEnabled?: boolean;
}

export const ShareApplicationButton: React.FC<Props> = ({
  applicationId,
  applicationStatus,
  billingEnabled,
  className,
  skipBillingSettings,
  isAdmin,
  emailEnabled,
}) => {
  const classes = useStyles();
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const { mutate } = useUpdateApplicationSettings(applicationId);
  const { showToaster } = useToasters();
  const isApplicationBound = applicationStatus === ApplicationStatus.BOUND;

  const updateBillingEnabled = useCallback(
    (billingEnabled) => {
      mutate({ billingEnabled });
    },
    [mutate],
  );

  const updateEmailEnabled = useCallback(
    (emailEnabled) => {
      mutate({ emailEnabled });
    },
    [mutate],
  );

  const handleCopyLink = useCallback(() => {
    const link = `${window.location.origin}${insertIdToRoute(ROUTES.application.FORM, applicationId)}/general`;
    navigator.clipboard.writeText(link);
    showToaster('Link is copied to clipboard');
    setLinkModalOpen(false);
  }, [applicationId, showToaster]);

  const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const billingEnabled = (event.target as HTMLInputElement).value === 'true';
    updateBillingEnabled(billingEnabled);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailEnabled = (event.target as HTMLInputElement).value === 'true';
    updateEmailEnabled(emailEnabled);
  };

  const handleClick = useCallback(() => {
    if (skipBillingSettings) {
      handleCopyLink();
    } else {
      setLinkModalOpen(true);
    }
  }, [handleCopyLink, skipBillingSettings]);

  return (
    <>
      <Button variant='outlined' className={className} onClick={handleClick} startIcon={<ShareRoundedIcon />}>
        Share Questionnaire
      </Button>
      <Dialog fullWidth={true} open={linkModalOpen} onClose={() => setLinkModalOpen(false)}>
        <DialogTitle className={classes.dialogTitle}>Share this Link</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            Share this link with your client so they can complete the Questionnaire
          </DialogContentText>

          <FormControl>
            <FormLabel id='billingEnabled-radio' className={classes.radioGroupTitle}>
              Billing Preference
            </FormLabel>
            <RadioGroup
              aria-labelledby='billingEnabled-radio'
              name='billingEnabled-group'
              value={billingEnabled}
              onChange={!isApplicationBound ? handleBillingChange : undefined}
            >
              <div className={classes.radioWrapper}>
                <FormControlLabel
                  value={true}
                  disabled={isApplicationBound}
                  control={<Radio color='primary' />}
                  label={<span className={classes.radioLabel}>Billing On</span>}
                />
                <span className={classes.radioDescription}>
                  Your customer will complete their purchase within Telivy.
                </span>
              </div>
              <div className={classes.radioWrapper}>
                <FormControlLabel
                  value={false}
                  disabled={isApplicationBound}
                  control={<Radio color='primary' />}
                  label={<span className={classes.radioLabel}>Billing Off</span>}
                />
                <span className={classes.radioDescription}>
                  You would like your customer to complete their purchase elswhere.
                </span>
              </div>
            </RadioGroup>
          </FormControl>

          {isAdmin && (
            <FormControl className={classes.emailEnabledSection}>
              <FormLabel id='emailEnabled-radio' className={classes.radioGroupTitle}>
                Email Preference
              </FormLabel>
              <RadioGroup
                aria-labelledby='emailEnabled-radio'
                name='emailEnabled-group'
                value={emailEnabled}
                onChange={!isApplicationBound ? handleEmailChange : undefined}
              >
                <div className={classes.radioWrapper}>
                  <FormControlLabel
                    value={true}
                    disabled={isApplicationBound}
                    control={<Radio color='primary' />}
                    label={<span className={classes.radioLabel}>Email On</span>}
                  />
                  <span className={classes.radioDescription}>Your customer will get email notifications.</span>
                </div>
                <div className={classes.radioWrapper}>
                  <FormControlLabel
                    value={false}
                    disabled={isApplicationBound}
                    control={<Radio color='primary' />}
                    label={<span className={classes.radioLabel}>Email Off</span>}
                  />
                  <span className={classes.radioDescription}>
                    You would like your customer to not be notified on email.
                  </span>
                </div>
              </RadioGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLinkModalOpen(false)}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={handleCopyLink}>
            <LinkRoundedIcon />
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
