import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core';
import { CustomSnackbar } from 'components/CustomSnackbar';
import { LoadingContainer } from 'components/LoadingContainer';
import ProtectedApplicationRoute from 'components/ProtectedApplicationRoute';
import ProtectedRoute from 'components/ProtectedRoute';
import { SnackbarProvider } from 'notistack';
import { useCustomDomainAgent } from 'queries/useAgent';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, useLocation } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { clearAuthTokens, setAuthTokens } from 'services/auth0-tokens';
import { createRedirectUrl, insertIdToRoute, ROUTES } from 'telivy-constants';
import { isUrlSaluswall } from 'telivy-selectors';
import { TELIVY_THEME } from 'telivy-theme';
import { AdminView } from 'views/admin';
import { AdminApplicationCharts } from 'views/admin-application-charts';
import { AgentView } from 'views/agent';
import { ApplicationActivePolicyView } from 'views/application-policy';
import { ClientView } from 'views/client';
import { QuotingView } from 'views/quoting';
import { SecurityAssessmentsView } from 'views/security';
import { ApplicationStartView } from 'views/start';
import { ApplicationThankYouGwsView } from 'views/thank-you/gws';
import { ApplicationThankYouM365View } from 'views/thank-you/m365';
import { VerifyApplicationAccessView } from 'views/verify';
import { WebProposalView } from 'views/web-proposal';
import { WebProposalRedirectView } from 'views/web-proposal/WebProposalRedirectView';

const client = new QueryClient();

const NotFoundRouteHandler: React.FC = () => {
  const { data, isLoading } = useCustomDomainAgent(window.location.origin, {
    enabled: !window.location.origin.endsWith('telivy.com'),
  });

  if (!window.location.origin.endsWith('telivy.com')) {
    if (isLoading) {
      return <LoadingContainer fullScreen />;
    }

    if (data?.id) {
      return <Redirect to={insertIdToRoute(ROUTES.START, data.id)} />;
    }
  }

  return <Redirect to={ROUTES.agent.ROOT} />;
};

export const App: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [loading, setLoading] = useState(isLoading);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getAccessTokenSilently()
        .then((token) => setAuthTokens({ accessToken: token }))
        .finally(() => {
          setLoading(false);
        });
    } else if (!isAuthenticated) {
      clearAuthTokens();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setLoading(false);
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading || loading) {
    return <LoadingContainer fullScreen />;
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      content={(key, message) => <CustomSnackbar id={key} message={message} />}
    >
      <ThemeProvider theme={TELIVY_THEME}>
        <QueryClientProvider client={client}>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          <Helmet
            titleTemplate={isUrlSaluswall ? '%s – SalusWall' : '%s – Telivy'}
            defaultTitle={isUrlSaluswall ? 'SalusWall' : 'Telivy'}
          />
          <Switch>
            {/* misc */}
            <Route path={ROUTES.START} component={ApplicationStartView} />
            <Route path={ROUTES.VERIFY} component={VerifyApplicationAccessView} />
            <Route path={ROUTES.M365_THANK_YOU} component={ApplicationThankYouM365View} />
            <Route path={ROUTES.GWS_THANK_YOU} component={ApplicationThankYouGwsView} />

            {/* client application */}
            <ProtectedApplicationRoute path={ROUTES.application.PROPOSAL} component={WebProposalView} />
            <ProtectedApplicationRoute path={ROUTES.application.QUOTING} component={QuotingView} />
            <ProtectedApplicationRoute path={ROUTES.application.POLICY} component={ApplicationActivePolicyView} />
            <ProtectedApplicationRoute path={ROUTES.application.security.ROOT} component={SecurityAssessmentsView} />
            <ProtectedApplicationRoute path={ROUTES.application.FORM} component={ClientView} />
            <Route
              path={ROUTES.application.ROOT}
              render={({ match: { params }, location: { search } }) => {
                return <Redirect to={createRedirectUrl(ROUTES.application.FORM, params.id as string, search)} />;
              }}
            />

            {/* agent views */}
            <Route path={ROUTES.ADMIN} component={AdminView} />
            <Route path={ROUTES.ADMIN_APPLICATION_CHARTS} component={AdminApplicationCharts} />
            <ProtectedRoute
              redirectToSignup
              path={ROUTES.SIGN_UP}
              component={() => <Redirect to={ROUTES.agent.ROOT} />}
            />
            <ProtectedRoute path={ROUTES.agent.ROOT} component={AgentView} />

            {/* backward compatibility */}
            <Route path={ROUTES.OLD_WEB_PROPOSAL} component={WebProposalRedirectView} />
            <Route
              path={ROUTES.OLD_QUOTING}
              render={({ match: { params }, location: { search } }) => {
                return <Redirect to={createRedirectUrl(ROUTES.application.QUOTING, params.id as string, search)} />;
              }}
            />
            <Route
              path={ROUTES.OLD_APPLICATION_POLICY}
              render={({ match: { params }, location: { search } }) => {
                return <Redirect to={createRedirectUrl(ROUTES.application.POLICY, params.id as string, search)} />;
              }}
            />
            <Route
              path={ROUTES.OLD_CLIENT_APPLICATION}
              render={({ match: { params }, location: { search } }) => {
                return <Redirect to={createRedirectUrl(ROUTES.application.FORM, params.id as string, search)} />;
              }}
            />

            {/* not found */}
            <Route component={NotFoundRouteHandler} />
          </Switch>
        </QueryClientProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
