import {
  Backdrop,
  Button,
  ClickAwayListener,
  FormControlLabel,
  FormLabel,
  Grow,
  Paper,
  Popper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import { LinkButton } from 'components/LinkButton';
import { Money } from 'components/Money';
import React, { useRef, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  label: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    paddingTop: theme.spacing(2.25),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  options: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
  },
  saveButton: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
  },

  aggLimitSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
  },
  aggLimitSelect: {
    color: COLORS.BLUE_1,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  selectedLimit: number;
  setSelectedLimit: (selectedLimit: number) => void;
  availableLimits: number[];
}

export const ChangeAggLimitSelect = ({ selectedLimit, setSelectedLimit, availableLimits }: Props) => {
  const classes = useStyles();
  const [selectedLimitValue, setSelectedLimitValue] = useState<number>(selectedLimit);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isAggLimitModalOpened, setIsAggLimitModalOpened] = useState<boolean>(false);

  const handleSubmit = () => {
    setSelectedLimit(selectedLimitValue);
    setIsAggLimitModalOpened(false);
  };

  const handleClose = () => {
    setSelectedLimitValue(selectedLimit);
    setIsAggLimitModalOpened(false);
  };

  return (
    <>
      <LinkButton
        icon={<FilterListRoundedIcon />}
        typography={TYPOGRAPHY.SMALL_MEDIUM}
        color={COLORS.GREY_1}
        onClick={() => setIsAggLimitModalOpened(true)}
        ref={buttonRef}
        noUnderline
        contentClassName={classes.aggLimitSelectWrapper}
        style={isAggLimitModalOpened ? { zIndex: 3 } : undefined}
      >
        Aggregate Limit:{' '}
        <div className={classes.aggLimitSelect}>
          <Money value={selectedLimit} />
          <ArrowDropDownRoundedIcon />
        </div>
      </LinkButton>
      <Backdrop
        style={{
          zIndex: 2,
        }}
        open={isAggLimitModalOpened}
      >
        <Popper
          open={isAggLimitModalOpened}
          anchorEl={buttonRef.current}
          role={undefined}
          transition
          style={{ zIndex: 5 }}
          placement='bottom-start'
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                marginTop: 8,
                boxShadow: '0px 2px 4px rgb(35 40 48 / 16%), 0px 0px 1px rgb(35 40 48 / 50%)',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <FormControl>
                    <FormLabel id='application-status-radio-group-label' className={classes.label}>
                      Filter quotes based on:
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='application-status-radio-group-label'
                      value={selectedLimitValue}
                      name='application-status-radio-group'
                      className={classes.options}
                      onChange={(e) => setSelectedLimitValue(parseInt(e.currentTarget?.value))}
                    >
                      {availableLimits.map((limit) => (
                        <FormControlLabel
                          key={limit}
                          value={limit}
                          control={<Radio color='primary' />}
                          label={<Money value={limit} />}
                        />
                      ))}
                    </RadioGroup>
                    <Button variant='contained' color='primary' className={classes.saveButton} onClick={handleSubmit}>
                      Save
                    </Button>
                  </FormControl>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Backdrop>
    </>
  );
};
