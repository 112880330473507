import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO, SecurityScanStatus } from 'dtos/application';
import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import { uniq } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan, useDeepScanNetworkHosts, useDeepScanTargets } from 'queries/useDeepScan';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { createSecurityRoute, generateRefetchIntervalMs, insertIdToRoute } from 'telivy-constants';
import { isAgentAnAdmin, isScanApplication } from 'telivy-selectors';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { Devices } from './Devices';
import { Domains } from './Domains';
import { Integrations } from './Integrations';
import { Inventory } from './Inventory';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  assets: {
    DOMAINS: string;
    DEVICES: string;
    INVENTORY: string;
    INTEGRATIONS: string;
    ROOT: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
}

export const ApplicationAssetsView = ({ application, sourceRoute }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { data: agent } = useCurrentAgent();
  const deepScanId = application.deepScanId || '';

  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);
  const externalScanDisabled = useExternalScanDisabled();

  const securityScanId = 'latest';
  const { data: securityScan, isLoading } = useApplicationSecurityScan(application.id, securityScanId, {
    refetchInterval: (data) =>
      data && data?.status !== SecurityScanStatus.COMPLETED ? generateRefetchIntervalMs() : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { data: deepScanTargets, isLoading: isTargetsLoading } = useDeepScanTargets(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const { data: deepScanNetworkHosts, isLoading: isNetworkHostsLoading } = useDeepScanNetworkHosts(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const viewOptions: Option<ValueOf<SourceRoute['assets']>>[] = React.useMemo(() => {
    const options = [
      {
        label: `Agents (${deepScanTargets?.length || 0})`,
        value: createSecurityRoute(sourceRoute.assets.DEVICES, application.id),
      },
    ];

    // if (isAgentAnAdmin(agent)) {
    options.push({
      label: `Inventory (${deepScanNetworkHosts ? uniqBy(deepScanNetworkHosts, 'ipAddress').length : 0})`,
      value: createSecurityRoute(sourceRoute.assets.INVENTORY, application.id),
    });
    // }

    if (securityScan) {
      const allDomains = [securityScan.domain]
        .concat(securityScan.subDomains || [], securityScan.ipAddresses || [], securityScan.otherDomains || [])
        .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1);

      options.push({
        label: `Domains / IP Addresses (${uniq(allDomains).length})`,
        value: createSecurityRoute(sourceRoute.assets.DOMAINS, application.id),
      });
    }

    if (deepScan) {
      options.push({
        label: `Softwares (${(deepScan.m365Connected ? 1 : 0) + (deepScan.gwsConnected ? 1 : 0)})`,
        value: createSecurityRoute(sourceRoute.assets.INTEGRATIONS, application.id),
      });
    }

    return options;
  }, [securityScan, sourceRoute, application, deepScanTargets, deepScanNetworkHosts, deepScan]);

  if (!isScanApplication(application)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  if (isLoading || isTargetsLoading || isNetworkHostsLoading || !deepScanTargets) {
    return <LoadingContainer />;
  }

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Assets - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute['assets']>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => history.push(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Switch>
        <Route
          path={sourceRoute.assets.DOMAINS}
          render={() =>
            securityScan && (
              <Domains
                securityScan={securityScan}
                securityScanId={securityScanId}
                applicationId={application.id}
                application={application}
                sourceRoute={sourceRoute}
                externalScanDisabled={externalScanDisabled}
              />
            )
          }
        />

        <Route
          path={sourceRoute.assets.DEVICES}
          render={() => (
            <Devices
              deepScanTargets={deepScanTargets}
              deepScanId={deepScanId}
              application={application}
              isAdmin={isAgentAnAdmin(agent)}
              enableCharts={enableCharts}
            />
          )}
        />

        <Route
          path={sourceRoute.assets.INVENTORY}
          render={() => <Inventory deepScanTargets={deepScanTargets} deepScanNetworkHosts={deepScanNetworkHosts} />}
        />

        <Route
          path={sourceRoute.assets.INTEGRATIONS}
          render={() => <Integrations deepScan={deepScan} application={application} />}
        />

        <Route
          exact
          path={sourceRoute.assets.ROOT}
          render={() => <Redirect to={insertIdToRoute(sourceRoute.assets.DEVICES, application.id)} />}
        />
      </Switch>
    </ApplicationViewContainer>
  );
};
