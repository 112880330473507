import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CompanyLogo } from 'components/CompanyLogo';
import { CreateApplicationButton } from 'components/CreateApplicationButton';
import { useCurrentAgent } from 'queries/useAgent';
import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
// import { isAgentAGlobalAdmin } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';

import { Menu } from './Menu';
import { TrialStatus } from './TrialStatus';
import { UserStatus } from './UserStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.GREY_6,
    padding: `0 ${theme.spacing(4)}px`,
    gap: theme.spacing(1),
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  logo: {
    height: theme.spacing(4),
  },
  menu: {},
  trial: {},
  createButton: {
    marginLeft: 'auto',
  },
  userWrapper: {},
}));

function Navbar() {
  const classes = useStyles();
  const { data: agentData } = useCurrentAgent();
  // const isGlobalAdmin = isAgentAGlobalAdmin(agentData);

  return (
    <Box component='nav' aria-label='navbar' className={classes.root}>
      <Link to={ROUTES.agent.DASHBOARD} className={classes.logoWrapper}>
        <CompanyLogo className={classes.logo} />
      </Link>
      <Menu className={classes.menu} agent={agentData} />
      <TrialStatus className={classes.trial} agent={agentData} />
      {agentData && <CreateApplicationButton className={classes.createButton} agent={agentData} />}
      <UserStatus className={classes.userWrapper} />
    </Box>
  );
}

export default withRouter(Navbar);
