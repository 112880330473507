import { RiskLine, RiskLineDataPoint } from 'components/charts/RiskLine';
import { MonitoringSnapshotDTO } from 'dtos/application';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';

export const useMonitoringDataSecurityChart = (
  enableCharts: boolean,
  data?: MonitoringSnapshotDTO[],
  selectedAssets?: string[],
  onlyActive?: boolean,
  classname?: string,
) => {
  const dataRisk = useMemo(() => {
    const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
      const finished = Object.keys(snapshot.scanStatus || {}).every(
        (h) =>
          snapshot.scanStatus[h].telivyAgentInstalledAt ||
          (snapshot.scanStatus[h].system.scan_pii_total_found && snapshot.piiScanStats[h]),
      );
      const message = finished ? 'Latest Scan' : 'Scan In Progress';
      const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
      acc[date] = acc[date] || snapshot;

      return acc;
    }, {});

    const snapshotsDates = sortBy(
      Object.keys(snapshotsByDate).map((date) => {
        return { date, createdAt: snapshotsByDate[date].createdAt };
      }),
      (snapshot) => snapshot.createdAt,
    ).map((d) => d.date);

    let selectedAssetsFinal: string[] | undefined = undefined;
    if (onlyActive && snapshotsDates.length > 1) {
      const latestSnapshot = snapshotsDates[snapshotsDates.length - 2];
      selectedAssetsFinal = Object.keys(snapshotsByDate[latestSnapshot].piiScanStats).filter((s) => {
        return (
          !snapshotsByDate[latestSnapshot].scanStatus ||
          !snapshotsByDate[latestSnapshot].scanStatus[s]?.system?.rescan_scheduled
        );
      });
    } else {
      selectedAssetsFinal = selectedAssets;
    }

    const summedByDate = sortBy(
      snapshotsDates.map((date, index) => {
        const prevDate = snapshotsDates[index - 1];

        const filteredAssets =
          (selectedAssetsFinal || []).length === 0
            ? Object.keys(snapshotsByDate[date].piiScanStats)
            : selectedAssetsFinal || [];

        const assetsData = filteredAssets
          .filter((s) => {
            return !snapshotsByDate[date].scanStatus || !snapshotsByDate[date].scanStatus[s]?.system?.rescan_scheduled;
          })
          .map((s) => {
            const completed =
              snapshotsByDate[date].scanStatus &&
              (snapshotsByDate[date].scanStatus[s]?.telivyAgentInstalledAt ||
                (snapshotsByDate[date].scanStatus[s]?.system.scan_pii_total_found &&
                  snapshotsByDate[date].piiScanStats[s]));

            return {
              name: completed ? s : `${s} *`,
              value: snapshotsByDate[date].piiScanStats[s]?.risk || 0,
              previous: (prevDate && snapshotsByDate[prevDate].piiScanStats[s]?.risk) || 0,
            };
          });

        const totalRisk = assetsData.reduce((a, b) => a + b.value, 0);
        const totalRiskPrev = prevDate
          ? Object.values(snapshotsByDate[prevDate].piiScanStats).reduce((a, b) => a + b.risk || 0, 0)
          : 0;

        return {
          date,
          totalRisk,
          totalRiskPrev,
          assetsData,
          createdAt: snapshotsByDate[date].createdAt,
        };
      }),
      (snapshot) => snapshot.createdAt,
    );

    return summedByDate
      .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
        acc.push({
          name: snapshot.date,
          score: snapshot.totalRisk,
          distribution: ([] as any).concat(snapshot.assetsData, [
            { name: 'Total', value: snapshot.totalRisk, previous: snapshot.totalRiskPrev },
          ]),
        });

        return acc;
      }, [])
      .slice(0, 365);
  }, [data, selectedAssets, onlyActive]);

  const chart = useMemo(() => {
    return (
      <RiskLine
        locked={!enableCharts}
        id='monitoring-data-security'
        className={classname}
        data={dataRisk}
        showTooltip
        // title='Critical Vulnerabilities'
      />
    );
  }, [classname, enableCharts, dataRisk]);

  return {
    chart,
    dataRisk,
  };
};
