import { LinkButton } from 'components/LinkButton';
import { ApplicationDTO } from 'dtos/application';
import { ProposalDTO } from 'dtos/proposal';
import { useDownloadProposalPdf } from 'queries/useApplications';
import React, { useCallback } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface Props {
  proposal: ProposalDTO;
  application: ApplicationDTO;
  className?: string;
  label: string;
  color?: ValueOf<typeof COLORS>;
  icon?: React.ReactNode;
  typography?: ValueOf<typeof TYPOGRAPHY>;
  bordered?: boolean;
  noUnderline?: boolean;
}

export const DownloadProposal = ({
  proposal,
  application,
  className,
  label,
  color,
  icon,
  typography,
  bordered,
  noUnderline,
}: Props) => {
  const { mutate, isLoading } = useDownloadProposalPdf(proposal.applicationId);
  const businessName = application.applicationResponse.organization_name;

  const handleClick = useCallback(() => {
    mutate({
      proposal,
      filename: undefined,
      businessName,
    });
  }, [proposal, mutate, businessName]);

  return (
    <LinkButton
      className={className}
      disabled={isLoading}
      onClick={handleClick}
      icon={icon}
      typography={typography || TYPOGRAPHY.REGULAR_MEDIUM}
      color={color || COLORS.GREY_2}
      bordered={bordered}
      noUnderline={noUnderline}
    >
      {isLoading ? 'Downloading...' : label}
    </LinkButton>
  );
};
