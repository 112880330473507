import { makeStyles } from '@material-ui/core/styles';
import { LoadingContainer } from 'components/LoadingContainer';
import { Searchbar } from 'components/SearchBar';
import { SingleFilter } from 'components/SingleFilter';
import { SecurityScanDTO } from 'dtos/application';
import { SecurityFindingBySlugDTO } from 'dtos/security-finding';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useSecurityFindingsByDomain, useSecurityFindingsBySlug } from 'queries/useSecurityFinding';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';

import { SourceRoute } from '..';
import { FindingsTable } from '../components/FindingsTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  sourceRoute?: SourceRoute;
  securityScanId: string;
}

interface QueryParams {
  target?: string;
  q?: string;
}

export const SecurityFindings = ({ applicationId, sourceRoute, securityScan, securityScanId }: Props) => {
  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search) as unknown as QueryParams, [search]);
  const classes = useStyles();
  const history = useHistory();
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);
  const { data: findingsByDomains } = useSecurityFindingsByDomain({
    scanId: securityScan.id,
  });

  const domains = useMemo(() => findingsByDomains?.map((finding) => finding.domain) ?? [], [findingsByDomains]);
  const { data: findings, isLoading: loadingFindings } = useSecurityFindingsBySlug({
    scanId: securityScan.id,
    domains: selectedDomains,
  });

  useEffect(() => {
    if (queryParams.target) {
      setSelectedDomains([queryParams.target]);
    }
  }, [queryParams.target]);

  useEffect(() => {
    if (queryParams.q) {
      setSearchValue(queryParams.q);
    }
  }, [queryParams.q]);

  const handleClick = (finding: SecurityFindingBySlugDTO) => {
    if (sourceRoute) {
      history.push(createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug));
    }
  };

  if (loadingFindings) {
    return <LoadingContainer />;
  }

  return (
    <div>
      <div className={classes.filters}>
        <Searchbar placeholder='Search issues...' value={searchValue} setValue={setSearchValue} />
        <SingleFilter
          buttonText={`Filter ${selectedDomains.map((d) => `${d}`).join(', ')}`}
          options={domains}
          popperText='Subdomain/Address'
          setSelectedOptions={setSelectedDomains}
          defaultSelectedOptions={selectedDomains}
          showDropdownIcon
        />
      </div>

      <FindingsTable
        showFactor
        onRowClick={handleClick}
        data={findings?.filter((f) => {
          return f.name.toLowerCase().includes(debouncedSearchValue.toLowerCase());
        })}
      />
    </div>
  );
};
