import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, FormHelperText, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomTextField } from 'components/CustomTextField';
import { RadioSelect } from 'components/RadioSelect';
import { ApplicationDTO, TargetType } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useEditApplication } from 'queries/useApplications';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';
import isURL from 'validator/lib/isURL';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  text: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  select: {
    marginBottom: theme.spacing(1),
  },

  disclaimer: {
    margin: theme.spacing(4),
  },
  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

interface Form {
  targetType: TargetType;
  targetValue: string;
}

const schema = yup.object().shape({
  targetValue: yup
    .string()
    .required('Value is required')
    .min(3, 'Value must be at least 3 characters')
    .test(
      'test-domain',
      'Value must be a valid domain format',
      (value) => !!value?.split(',').some((t) => isURL(t.trim())),
    ),
});

interface Props {
  application: ApplicationDTO;
  className?: string;
  locked?: boolean;
}

export const CreateTargetModal = ({ className, application, locked }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const [applicationEditedData, setApplicationEditedData] = useState<any>({});

  useEffect(() => {
    if (application.applicationResponse) {
      setApplicationEditedData(application.applicationResponse);
    }
  }, [application]);

  const { mutate, isLoading } = useEditApplication(application.id, {
    onSuccess: (data) => {
      setApplicationEditedData(data.applicationResponse);
      showToaster('Target Added');
      setOpen(false);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    (data: Form) => {
      const targetValueSplit = data.targetValue.split(',').map((t) => t.trim());
      if (applicationEditedData.domain_prim === 'nodomain.local' && targetValueSplit.length > 0) {
        if (data.targetType == TargetType.IP_ADDRESS) {
          return toasterErrorHandler(null, 'Please Add Domain First');
        } else if (data.targetType == TargetType.DOMAIN) {
          applicationEditedData.domain_prim = targetValueSplit.shift();
        }
      }

      if (data.targetType == TargetType.IP_ADDRESS) {
        applicationEditedData.ip_addresses = applicationEditedData.ip_addresses || [];

        if (!applicationEditedData.ip_addresses.includes(data.targetValue)) {
          applicationEditedData.ip_addresses = [...applicationEditedData.ip_addresses, ...targetValueSplit];
          mutate({
            applicationResponse: applicationEditedData,
          });
        } else {
          toasterErrorHandler(null, 'Target Already Added');
        }
      } else if (data.targetType == TargetType.DOMAIN) {
        applicationEditedData.other_domains = applicationEditedData.other_domains || [];

        if (!applicationEditedData.other_domains.some((d: string) => targetValueSplit.includes(d))) {
          applicationEditedData.other_domains = [...applicationEditedData.other_domains, ...targetValueSplit];
          mutate({
            applicationResponse: applicationEditedData,
          });
        } else {
          toasterErrorHandler(null, 'Target Already Added');
        }
      }
    },
    [mutate, applicationEditedData, toasterErrorHandler],
  );

  return (
    <>
      <Button className={className} onClick={() => setOpen(true)} variant='contained' color='primary'>
        Add Target
      </Button>

      <Dialog open={open} onClose={handleClose}>
        {/* <form onSubmit={handleSubmit(onSubmit)} className={classes.form}> */}
        {locked ? (
          <div className={classes.disclaimer}>
            <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
              Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> to upgrade account.
            </SecurityCard.Badge>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CloseRoundedIcon />
              </IconButton>
              <h3 className={classes.title}>Add Target</h3>
              <p className={classes.text}>Add target for external scan.</p>
              <div style={{ marginTop: theme.spacing(2) }}>
                <Controller
                  name='targetType'
                  control={control}
                  render={({ field }) => (
                    <RadioSelect<TargetType>
                      responsive
                      className={classes.select}
                      options={[
                        { label: 'IP Address', value: TargetType.IP_ADDRESS },
                        { label: 'Other Domain', value: TargetType.DOMAIN },
                      ]}
                      selectedValue={field.value}
                      onOptionSelect={(opt) => field.onChange(opt.value)}
                      selectedFn={(optionValue, selectedValue) => optionValue === selectedValue}
                    />
                  )}
                />
                <Controller
                  name='targetValue'
                  control={control}
                  render={({ field }) => (
                    <>
                      <CustomTextField
                        fullWidth
                        {...field}
                        data-cy='create-target-value-input'
                        label='Value (use comma to separate multiple values)'
                        placeholder='Enter Target'
                        error={!!errors.targetValue}
                        InputLabelProps={{ shrink: true }}
                        {...register('targetValue')}
                      />
                    </>
                  )}
                />
                {errors.targetValue && <FormHelperText error>{errors.targetValue?.message}</FormHelperText>}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add Target'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};
