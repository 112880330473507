import { makeStyles } from '@material-ui/core/styles';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { computeDeepScanTargetStatuses, DeepScanTargetDTO, ExtendedDeepScanTargetDTO } from 'dtos/deep-scan';
import { useRiskAssessmentDisabled } from 'hooks/useAccountDisabled';
import { useRiskAssessmentPlusEnabled } from 'hooks/useRiskAssessmentPlusEnabled';
import { ReactComponent as AssetsIcon } from 'images/assets.svg';
import uniq from 'lodash/uniq';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';
import { RescanAllButton } from 'views/agent/views/application-details/views/security/RescanAllButton';
import { UninstallAllButton } from 'views/agent/views/application-details/views/security/UninstallAllButton';

import { TargetsTable } from './TargetsTable';

const useStyles = makeStyles((theme) => ({
  root: {},

  infoBox: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    background: 'rgba(218, 216, 216, 0.1)',
    border: '1px solid #D0D0D0',
    borderRadius: theme.spacing(1),
  },

  statusBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'inline-flex',
    width: 'auto',
  },
  statusContent: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    gap: theme.spacing(0.5),
    fontWeight: 400,
    color: COLORS.BLACK,
    alignItems: 'center',
  },

  monitoringFormContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2),
    // background: 'rgba(218, 216, 216, 0.1)',
    border: '1px solid #eee',
    borderRadius: theme.spacing(1),
  },

  monitoringForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  deepScanTargets: DeepScanTargetDTO[];
  deepScanId: string;
  application: ApplicationWithAgentDTO;
  isAdmin: boolean;
  enableCharts: boolean;
}

export const Devices = ({ deepScanTargets, deepScanId, application, isAdmin, enableCharts }: Props) => {
  const classes = useStyles();

  const riskAssessmentDisabled = useRiskAssessmentDisabled();
  const riskAssessmentPlusEnabled = useRiskAssessmentPlusEnabled();

  const { data: deepScan } = useDeepScan(deepScanId);
  const pii = deepScan?.actifileID ? 'actifile' : deepScan?.piiToolsHost ? 'pii' : 'telivy';
  const extendedTargets: ExtendedDeepScanTargetDTO[] = useMemo(() => {
    return deepScanTargets.map((target) => ({
      ...target,
      statuses: computeDeepScanTargetStatuses(target, pii),
    }));
  }, [deepScanTargets, pii]);

  // const allIssues = useMemo(() => {
  //   const items = extendedTargets.reduce<Array<string | undefined>>((acc, target) => {
  //     return [
  //       ...acc,
  //       target.statuses?.application.issue,
  //       target.statuses.vulnerabilityManagement.issue,
  //       target.statuses.piiAgent.issue,
  //       target.statuses.browserPassword.issue,
  //       target.statuses.telivyAgent.issue,
  //     ];
  //   }, []);

  //   return items.filter((item) => item !== undefined);
  // }, [extendedTargets]);

  const numAssetsWithIssues = useMemo(() => {
    return extendedTargets.reduce<number>((acc, target) => {
      if (target.statuses?.application.action) {
        return acc + 1;
      } else if (target.statuses.vulnerabilityManagement.action) {
        return acc + 1;
      } else if (target.statuses.piiAgent.action) {
        return acc + 1;
      } else if (target.statuses.browserPassword.action) {
        return acc + 1;
      } else if (target.statuses.telivyAgent.action) {
        return acc + 1;
      }

      return acc;
    }, 0);
  }, [extendedTargets]);

  const allActions = useMemo(() => {
    const items = extendedTargets.reduce<Array<any>>((acc, target) => {
      return [
        ...acc,
        { target: target.hostname, action: target.statuses?.application.action },
        { target: target.hostname, action: target.statuses.vulnerabilityManagement.action },
        { target: target.hostname, action: target.statuses.piiAgent.action },
        { target: target.hostname, action: target.statuses.browserPassword.action },
        { target: target.hostname, action: target.statuses.telivyAgent.action },
      ];
    }, []);

    const actions = items.filter((item) => item.action !== undefined);
    const actionsByTargets: Record<string, string[]> = {};
    for (const d of actions) {
      actionsByTargets[d.action] = actionsByTargets[d.action] || [];
      actionsByTargets[d.action].push(d.target);
    }

    return actionsByTargets;
  }, [extendedTargets]);

  const messageVariant = useMemo(() => {
    const percentage = numAssetsWithIssues / deepScanTargets.length;

    if (percentage === 0 || deepScanTargets.length === 0) {
      return 'success';
    } else if (percentage < 0.2) {
      return 'warning';
    } else {
      return 'error';
    }
  }, [numAssetsWithIssues, deepScanTargets.length]);

  const atleastOneDeviceOnline = useMemo(() => {
    return deepScanTargets.filter((d) => d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin === 'True').length > 0;
  }, [deepScanTargets]);

  return (
    <div className={classes.root}>
      <SecurityCard.Badge variant={messageVariant} icon={<AssetsIcon />} className={classes.statusBox} bordered>
        <div className={classes.statusContent}>
          No. of Assets with issues: <b>{numAssetsWithIssues}</b> out of <b>{deepScanTargets.length}</b>
        </div>
      </SecurityCard.Badge>

      {Object.keys(allActions).length > 0 && application.applicationStatus != ApplicationStatus.UNINSTALL_ALL && (
        <div className={classes.infoBox}>
          <div>
            <b>Action items:</b>
          </div>
          <ul>
            {Object.keys(allActions).map((action) => (
              <li key={action}>
                {action} {action === 'Please reach out to support' ? 'about' : 'on'}{' '}
                {uniq(allActions[action]).join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )}

      {atleastOneDeviceOnline && (
        <div className={classes.monitoringFormContainer}>
          <UninstallAllButton
            applicationId={application.id}
            isUninstallAll={application.applicationStatus == ApplicationStatus.UNINSTALL_ALL}
            className={classes.monitoringForm}
          />
          <RescanAllButton
            applicationId={application.id}
            isRescanAll={application.applicationStatus == ApplicationStatus.RESCAN_ALL}
            className={classes.monitoringForm}
            locked={
              (application.isLightScan && riskAssessmentDisabled) ||
              (application.isDeepScan && !riskAssessmentPlusEnabled)
            }
          />
        </div>
      )}

      <TargetsTable
        targets={extendedTargets}
        deepScanId={deepScanId}
        isAdmin={isAdmin}
        enableCharts={enableCharts}
        isDemo={deepScan?.nodewareId === 'demo'}
        locked={
          (application.isLightScan && riskAssessmentDisabled) || (application.isDeepScan && !riskAssessmentPlusEnabled)
        }
      />
    </div>
  );
};
