import { Box, Button, CircularProgress, makeStyles, withStyles } from '@material-ui/core';
import cx from 'classnames';
import { CarrierLogo } from 'components/CarrierLogo';
import { formatNumberWithCommas, Money } from 'components/Money';
import dayjs from 'dayjs';
import { QuoteDocumentType, QuoteDTO } from 'dtos/quotes';
import { useQuoteSublimits } from 'hooks/useQuoteSublimits';
import startCase from 'lodash/startCase';
// import { ReactComponent as RecommendedIcon } from 'images/recommended-icon.svg';
import React from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ProposalInfoModal } from 'views/web-proposal/ProposalInfoModal';

import { DownloadQuoteDocument } from './DownloadQuoteDocument';

export type QuoteCardVariant = 'PEACH' | 'GREEN' | undefined;

interface StylesProps {
  variant: QuoteCardVariant;
  recommended?: boolean;
  showDetails?: boolean;
}

export const QUOTE_CARD_WIDTH = 240;

const CustomCircularProgress = withStyles(() => ({
  root: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '50%',
  },
  svg: {
    transform: 'rotate(180deg)',
    color: COLORS.BLUE_2,
  },
}))(CircularProgress);

const useStyles = makeStyles((theme) => ({
  root: ({ recommended }: StylesProps) => ({
    width: QUOTE_CARD_WIDTH,
    boxSizing: 'border-box',
    position: 'relative',
    padding: `0 ${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    color: COLORS.TEXT,
    backgroundColor: COLORS.WHITE,

    '&:after': {
      content: '',
      width: 100,
      height: '90%',
      borderRight: '1px solid #000',
      background: '#000',
    },

    ...(recommended && {
      backgroundColor: `${COLORS.BLUE_1}0d`,
      borderRadius: theme.spacing(),
    }),
  }),

  recommendedBadge: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_1,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(3),
    position: 'absolute',
    top: theme.spacing(-1.5),
    left: theme.spacing(),
  },

  buttonWrapper: ({ recommended }: StylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: `${theme.spacing(1.5)}px 0`,
    borderBottom: `1px solid transparent`,
    ...(recommended && {
      '&:first-of-type': {
        borderColor: `${COLORS.BLUE_2}80`,
      },
    }),
  }),
  scoreWrapper: ({ recommended }: StylesProps) => ({
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.BLUE_1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px 0`,
    borderBottom: `1px solid transparent`,
    ...(recommended && {
      '&:first-of-type': {
        borderColor: `${COLORS.BLUE_2}80`,
      },
    }),
  }),
  telivyScoreLabel: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.WHITE,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.BLUE_1,
    borderRadius: '50%',
    margin: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  button: {
    ...TYPOGRAPHY.SMALL_BOLD,
    border: 'none',
    color: COLORS.BLUE_1,
    textDecoration: 'none',
    transition: 'all 0.3s',
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(0.625)}px ${theme.spacing(1.25)}px`,

    '&:hover': {
      backgroundColor: `${COLORS.BLUE_1}1a`,
    },
  },

  carrierInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(),
    margin: `${theme.spacing(1.5)}px 0 ${theme.spacing(1.25)}px`,
  },
  carrierLogo: {
    height: theme.spacing(3.5),
  },
  carrierBadge: {
    ...TYPOGRAPHY.REGULAR_BOLD,
    width: theme.spacing(4),
    height: theme.spacing(4),
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${COLORS.BLUE_1}`,
    borderRadius: theme.spacing(),
    color: COLORS.BLUE_1,
    backgroundColor: COLORS.WHITE,
  },

  premium: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  premiumDollarSign: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
  },
  premiumValue: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: theme.spacing(5),
    lineHeight: `${theme.spacing(4.5)}px`,
  },
  cents: {
    alignSelf: 'flex-end',
    ...TYPOGRAPHY.MICRO_MEDIUM,
  },

  selectButton: {
    padding: `${theme.spacing(1.25)}px 0`,
  },

  quoteInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    margin: `${theme.spacing(2.5)}px 0 0`,
  },
  item: ({ showDetails }: StylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${COLORS.BLUE_2}80`,

    '&:last-of-type': {
      ...(!showDetails && {
        borderBottom: 'none',
      }),
    },
  }),
  value: {
    ...TYPOGRAPHY.MONO_BOLD_REGULAR,
    color: COLORS.BLACK,
  },
  label: ({ recommended }: StylesProps) => ({
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.BLUE_1,
    textTransform: 'uppercase',
    transition: 'all 0.3s',

    ...(!recommended && {
      opacity: 0,
      visibility: 'hidden',

      '.swiper-slide-active &, $root:hover &': {
        opacity: 1,
        visibility: 'visible',
      },
    }),
  }),

  footer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface Props {
  quote: QuoteDTO;
  className?: string;
  variant?: QuoteCardVariant;
  recommended?: boolean;
  showDetails?: boolean;
  onChooseClick: (quote: QuoteDTO) => void;
}

export const QuoteCard = ({ quote, variant, recommended, showDetails, className, onChooseClick }: Props) => {
  const classes = useStyles({ variant, showDetails, recommended });
  const { lists: sublimitsList } = useQuoteSublimits(quote, {
    iconColor: COLORS.BLUE_2,
    iconRight: true,
  });
  const quoteLetter = quote.documents.find((t) => t.documentType === QuoteDocumentType.QUOTE_LETTER);
  const securityReport = quote.documents.find((t) => t.documentType === QuoteDocumentType.SECURITY_REPORT);
  const prefilledApp = quote.documents.find((t) => t.documentType === QuoteDocumentType.PREFILLED_APP);

  return (
    <div className={cx(classes.root, className)}>
      {/* {recommended && <RecommendedIcon className={classes.recommendedBadge} />} */}

      <div className={classes.scoreWrapper}>
        Telivy Score
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CustomCircularProgress variant='determinate' size={32} value={quote.score ? quote.score * 10 : 0} />
          <Box className={classes.telivyScoreLabel}>{quote.score ? Number(quote.score).toFixed(1) : '-'}</Box>
        </Box>
      </div>

      <div className={classes.carrierInfo}>
        <CarrierLogo carrier={quote.carrier} className={classes.carrierLogo} />
        <span className={classes.carrierBadge}>{quote.carrier.amBestRating}</span>
      </div>
      <div className={classes.premium}>
        <span className={classes.premiumDollarSign}>$</span>
        <span className={classes.premiumValue}>
          {formatNumberWithCommas(Math.floor(quote.annualPolicyCost || quote.premium))}
        </span>
        <sub className={classes.cents}>
          {((quote.annualPolicyCost || quote.premium) - Math.floor(quote.annualPolicyCost || quote.premium))
            .toString()
            .slice(1, 4)}
        </sub>
      </div>
      <Button
        fullWidth
        variant='contained'
        color='primary'
        onClick={() => onChooseClick(quote)}
        className={classes.selectButton}
        data-cy='proposal-select-button'
      >
        Select Quote
      </Button>

      <div className={classes.quoteInfoWrapper}>
        <div className={classes.item}>
          <Money value={quote.aggregateLimit} className={classes.value} />
          <div className={classes.label}>
            <ProposalInfoModal iconRight iconColor={COLORS.BLUE_2} name='aggLimit' />
          </div>
        </div>
        <div className={classes.item}>
          <Money value={quote.retention} className={classes.value} />
          <div className={classes.label}>
            <ProposalInfoModal iconRight iconColor={COLORS.BLUE_2} name='retention' />
          </div>
        </div>
        <div className={classes.item}>
          <Money value={quote.premium} className={classes.value} />
          <div className={classes.label}>
            <ProposalInfoModal iconRight iconColor={COLORS.BLUE_2} name='premium' />
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.value}>{dayjs(quote.effectiveDate).format('M/D/YYYY')}</div>
          <div className={classes.label}>Policy Start Date</div>
        </div>
        <div className={classes.item}>
          <div className={classes.value}>{startCase(quote.carrierProduct)}</div>
          <div className={classes.label}>Carrier Product</div>
        </div>
        {showDetails &&
          sublimitsList.map((sublimit) => (
            <div className={classes.item} key={sublimit.title}>
              <div className={classes.value}>{sublimit.value}</div>
              <div className={classes.label}>{sublimit.label}</div>
            </div>
          ))}
      </div>

      {showDetails && (
        <>
          <div className={classes.buttonWrapper}>
            {quoteLetter && (
              <DownloadQuoteDocument
                quote={quote}
                document={quoteLetter}
                label={DOCUMENT_TYPE_HUMANIZED_NAME[quoteLetter.documentType]}
                className={classes.button}
              />
            )}
            {securityReport && (
              <DownloadQuoteDocument
                quote={quote}
                document={securityReport}
                label={DOCUMENT_TYPE_HUMANIZED_NAME[securityReport.documentType]}
                className={classes.button}
              />
            )}
            {prefilledApp && (
              <DownloadQuoteDocument
                quote={quote}
                document={prefilledApp}
                label={DOCUMENT_TYPE_HUMANIZED_NAME[prefilledApp.documentType]}
                className={classes.button}
              />
            )}
          </div>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => onChooseClick(quote)}
            className={classes.selectButton}
          >
            Select Quote
          </Button>
        </>
      )}
    </div>
  );
};
