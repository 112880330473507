import { makeStyles } from '@material-ui/core';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { EmptyState } from 'components/EmptyState';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface Props {
  triggerDownload: () => void;
  loading?: boolean;
}

export const SecurityDownload = ({ loading, triggerDownload }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      triggerDownload();
    }
  }, [history, loading, triggerDownload]);

  return (
    <div className={classes.root}>
      {loading && (
        <EmptyState
          title='Generating report...'
          text='Give us a second to generate your report.'
          icon={<CloudDownloadRoundedIcon />}
        />
      )}
    </div>
  );
};
