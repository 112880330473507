/* eslint-disable indent */
import { makeStyles } from '@material-ui/core';
import { GridLabelProps, RadarSliceTooltipProps, ResponsiveRadar } from '@nivo/radar';
import { Chip, TableTooltip } from '@nivo/tooltip';
import { animated } from '@react-spring/web';
import { SecurityScanDTO } from 'dtos/application';
import { SecurityScanType } from 'dtos/security-finding';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanGrades } from 'hooks/external-scan/useExternalScanGrades';
import { startCase } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { SCAN_TYPE_NAME } from 'templates/SecurityAssessments/views/SecurityExternalScan';
import { getChartValueForGrade } from 'templates/SecurityAssessments/views/SecurityHistory';

interface Props {
  securityScan: SecurityScanDTO;
}

enum Keys {
  SCORE = 'score',
  BENCHMARK = 'benchmark',
}

interface GraphItem {
  key: SecurityScanType;
  [Keys.SCORE]?: number;
  [Keys.BENCHMARK]?: number;
}

const useStyles = makeStyles((theme) => ({
  notes: {
    padding: theme.spacing(3),
    fontSize: '10px !important',
    color: 'grey',
  },
}));

export const ExternalScanRadarChart = ({ securityScan }: Props) => {
  const classes = useStyles();

  const { grades, previousGrades } = useExternalScanGrades({ securityScan });
  const {
    findingsByType,
    isLoadingPatchingCadence,
    isLoadingScanDns,
    isLoadingScanEmail,
    isLoadingScanIpReputation,
    isLoadingScanNetwork,

    allSubdomains,
    allSubdomainsIPAddresses,

    scannedSubdomainsIpReputation,
    scannedSubdomainsNetwork,
    scannedSubdomainsApplicationSecurity,
  } = useExternalScanFindings({ securityScan, options: { skipInfo: true, skipEmpty: true } });

  const counts = useMemo<{ [key in SecurityScanType]: number }>(
    () => ({
      [SecurityScanType.SOCIAL_ENGINEERING]: findingsByType[SecurityScanType.SOCIAL_ENGINEERING]?.length || 0,
      [SecurityScanType.HACKER_CHATTER]: findingsByType[SecurityScanType.HACKER_CHATTER]?.length || 0,
      [SecurityScanType.NETWORK_SECURITY]: findingsByType[SecurityScanType.NETWORK_SECURITY]?.length || 0,
      [SecurityScanType.ENDPOINT_SECURITY]: findingsByType[SecurityScanType.ENDPOINT_SECURITY]?.length || 0,
      [SecurityScanType.APPLICATION_SECURITY]: findingsByType[SecurityScanType.APPLICATION_SECURITY]?.length || 0,
      [SecurityScanType.DNS_HEALTH]: findingsByType[SecurityScanType.DNS_HEALTH]?.length || 0,
      [SecurityScanType.IP_REPUTATION]: findingsByType[SecurityScanType.IP_REPUTATION]?.length || 0,
      [SecurityScanType.PATCHING_CADENCE]: findingsByType[SecurityScanType.PATCHING_CADENCE]?.length || 0,
      [SecurityScanType.INSURABILITY]: findingsByType[SecurityScanType.INSURABILITY]?.length || 0,
    }),
    [findingsByType],
  );

  const calculateScore = useCallback(
    (type: SecurityScanType) => {
      const grade = grades?.[type] || previousGrades?.[type];
      return grade ? getChartValueForGrade(grade) : 0;
    },
    [grades, previousGrades],
  );

  // const hasBenchmark = !!securityScan?.benchmark?.data?.moduleBenchmarks;
  const hasBenchmark = true;

  const isSubdomainsLoading = useCallback(
    (allSubdomains?: string[], scannedSubdomains?: string[]) =>
      allSubdomains && scannedSubdomains && scannedSubdomains?.length < allSubdomains?.length,
    [],
  );

  const data = useMemo((): GraphItem[] => {
    return [
      {
        key: SecurityScanType.SOCIAL_ENGINEERING,
        score: isLoadingScanEmail ? 0 : calculateScore(SecurityScanType.SOCIAL_ENGINEERING),
        benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.socialEngineering || 0) * 20,
      },
      {
        key: SecurityScanType.NETWORK_SECURITY,
        score:
          isLoadingScanNetwork || isSubdomainsLoading(allSubdomainsIPAddresses, scannedSubdomainsNetwork)
            ? 0
            : calculateScore(SecurityScanType.NETWORK_SECURITY),
        benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.networkScan || 0) * 20,
      },
      // {
      //   key: SecurityScanType.ENDPOINT_SECURITY,
      //   score: calculateScore(SecurityScanType.ENDPOINT_SECURITY),
      //   benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.endpointSecurity || 0) * 20,
      // },
      {
        key: SecurityScanType.APPLICATION_SECURITY,
        score:
          isLoadingScanNetwork || isSubdomainsLoading(allSubdomains, scannedSubdomainsApplicationSecurity)
            ? 0
            : calculateScore(SecurityScanType.APPLICATION_SECURITY),
        benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.applicationSecurity || 0) * 20,
      },
      {
        key: SecurityScanType.DNS_HEALTH,
        score: isLoadingScanDns ? 0 : calculateScore(SecurityScanType.DNS_HEALTH),
        benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.dnsHealth || 0) * 20,
      },
      {
        key: SecurityScanType.IP_REPUTATION,
        score:
          isLoadingScanIpReputation || isSubdomainsLoading(allSubdomainsIPAddresses, scannedSubdomainsIpReputation)
            ? 0
            : calculateScore(SecurityScanType.IP_REPUTATION),
        benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.ipReputation || 0) * 20,
      },
      {
        key: SecurityScanType.PATCHING_CADENCE,
        score: isLoadingPatchingCadence ? 0 : calculateScore(SecurityScanType.PATCHING_CADENCE),
        benchmark: (securityScan?.benchmark?.data?.moduleBenchmarks?.patchingCadence || 0) * 20,
      },
    ];
  }, [
    isLoadingScanEmail,
    calculateScore,
    securityScan?.benchmark?.data?.moduleBenchmarks?.socialEngineering,
    securityScan?.benchmark?.data?.moduleBenchmarks?.networkScan,
    securityScan?.benchmark?.data?.moduleBenchmarks?.applicationSecurity,
    securityScan?.benchmark?.data?.moduleBenchmarks?.dnsHealth,
    securityScan?.benchmark?.data?.moduleBenchmarks?.ipReputation,
    securityScan?.benchmark?.data?.moduleBenchmarks?.patchingCadence,
    isLoadingScanNetwork,
    isSubdomainsLoading,
    allSubdomainsIPAddresses,
    scannedSubdomainsNetwork,
    allSubdomains,
    scannedSubdomainsApplicationSecurity,
    isLoadingScanDns,
    isLoadingScanIpReputation,
    scannedSubdomainsIpReputation,
    isLoadingPatchingCadence,
  ]);

  return (
    <>
      <ResponsiveRadar
        data={data as any}
        keys={hasBenchmark ? [Keys.BENCHMARK, Keys.SCORE] : [Keys.SCORE]}
        indexBy='key'
        maxValue={100}
        gridLabel={LabelComponent({ numberOfFindings: counts })}
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: 'color' }}
        valueFormat={(value: number | null, key: string) => (key === Keys.SCORE ? `${parseScoreToGrade(value)}` : '')}
        isInteractive={true}
        colors={hasBenchmark ? ['#6610F226', '#516AE699'] : ['#516AE699']}
        gridLevels={4}
        gridShape='linear'
        gridLabelOffset={32}
        sliceTooltip={RadarSliceTooltipComponent}
        dotSize={4}
        dotColor='#41505e'
        enableDotLabel={true}
        fillOpacity={0.2}
        blendMode='multiply'
        motionConfig='wobbly'
        legends={[
          {
            anchor: 'top-left',
            direction: 'column',
            translateX: -50,
            translateY: -50,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: '#999',
            data: [
              {
                id: Keys.SCORE,
                label: 'Score',
                color: '#516AE699',
              },
              {
                id: Keys.BENCHMARK,
                label: 'Benchmark *',
                color: '#6610F226',
              },
            ],
            symbolSize: 12,
            symbolShape: 'square',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
      <i className={classes.notes}>* Benchmarked against revenue and NAICS code.</i>
    </>
  );
};

const RadarSliceTooltipComponent = ({ index, data }: RadarSliceTooltipProps) => {
  const rows = useMemo(
    () =>
      data
        ?.sort((a, b) => {
          const keysOrder = [Keys.SCORE, Keys.BENCHMARK];
          const keyA = keysOrder.indexOf(a?.id as Keys);
          const keyB = keysOrder.indexOf(b?.id as Keys);
          return keyA - keyB;
        })
        ?.map((datum) => [
          <Chip key={datum?.id} color={datum?.color} />,
          startCase(datum?.id),
          datum?.value ? `${parseScoreToGrade(datum?.value)} (${Math.round(datum?.value)}%)` : 'N/A',
        ]),
    [data],
  );

  return <TableTooltip title={<strong>{SCAN_TYPE_NAME[index as SecurityScanType]}</strong>} rows={rows} />;
};

const LabelComponent =
  ({ numberOfFindings }: { numberOfFindings: Partial<Record<SecurityScanType, number>> }) =>
  ({ id, anchor, animated: animatedProps }: GridLabelProps) => {
    const TEXT = { fontFamily: 'sans-serif', fontSize: 11, fill: 'rgb(51, 51, 51)' };
    const BOLD_TEXT = { ...TEXT, fontWeight: 600 };

    return (
      <animated.g transform={(animatedProps?.transform as any) || undefined}>
        <text dominantBaseline='central' textAnchor={anchor}>
          <tspan style={BOLD_TEXT}>{SCAN_TYPE_NAME[id as SecurityScanType]} </tspan>
          <tspan style={TEXT}>({numberOfFindings[id as SecurityScanType]} findings)</tspan>
        </text>
      </animated.g>
    );
  };
