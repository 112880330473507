import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 auto',
  },
  breadcrumbsItem: {
    display: 'flex',
    alignItems: 'center',
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.GREY_3,
    borderBottom: `1px solid ${COLORS.GREY_5}`,
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
  active: {
    color: COLORS.BLUE_1,
    borderBottom: `2px solid ${COLORS.BLUE_1}`,
  },
  itemTitle: {},
  clickable: {
    cursor: 'pointer',
  },
}));

interface BreadcrumbsItem {
  title: string;
  unCheckable?: boolean;
  handleClick?: () => void;
}

interface Props {
  activeItemIdx: number;
  items: BreadcrumbsItem[];
}

export const Breadcrumbs = ({ activeItemIdx, items }: Props) => {
  const classes = useStyles({});
  const activeItem = items[activeItemIdx];

  return (
    <div className={classes.root}>
      {items.map((item, idx) => (
        <div
          key={idx}
          className={cx(
            classes.breadcrumbsItem,
            item === activeItem && classes.active,
            item.handleClick && classes.clickable,
          )}
          onClick={() => (item.handleClick ? item.handleClick() : undefined)}
          role='button'
          tabIndex={0}
        >
          <span className={classes.itemTitle}>{item.title}</span>
        </div>
      ))}
    </div>
  );
};
