import { Button } from '@material-ui/core';
import { DeepScanDTO } from 'dtos/deep-scan';
import React from 'react';

import { CONFIGURATION_STEP } from './index';
import { SectionTemplate } from './SectionTemplate';

interface Props {
  deepScan: DeepScanDTO;
  onVerify: () => void;
}

export const InstallSaasAlertsSection = ({ deepScan, onVerify }: Props) => {
  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      type={CONFIGURATION_STEP.SAAS_ALERTS}
      title='Configure SaaS Alerts'
      description='Complete the following steps to configure SaaS Alerts.'
    >
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          window.open(deepScan?.saasAlertLink || '', '_blank');
        }}
      >
        Click here to connect your SaaS apps
      </Button>
    </SectionTemplate>
  );
};
