import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { BottomActionsNavigation } from 'components/BottomActionsNavigation';
import { CustomTextField } from 'components/CustomTextField';
import { AgentDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useCreatePublicApplications, useVerifyCaptcha } from 'queries/usePublic';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { RECAPTCHA_SITE_KEY } from 'telivy-constants';
import { isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import isURL from 'validator/lib/isURL';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  heading: {
    maxWidth: 460,
    ...TYPOGRAPHY.TITLE_2,
    letterSpacing: '-0.03em',
    lineHeight: `${theme.spacing(6.5)}px`,
    marginBottom: theme.spacing(2),
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.TITLE_3,
      lineHeight: `${theme.spacing(3.5)}px`,
    },
  },
  description: {
    maxWidth: 390,
    letterSpacing: '-0.03em',
    marginBottom: theme.spacing(2),
    color: COLORS.GREY_2,
    textAlign: 'center',
    padding: `0 ${theme.spacing(1.5)}px`,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(1),
    maxWidth: 390,
    width: '100%',
  },

  bottomNavigation: {
    display: 'flex',
  },

  submitButton: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(2),
    },
  },
}));

interface ParamsType {
  id: string;
}

interface QueryParamsType {
  utmSource?: string;
  organizationName?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
}

interface Form {
  organizationName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  domain: string;
}

const defaultSchema = yup.object().shape({
  organizationName: yup
    .string()
    .required('Business Name is required')
    .min(3, 'Business Name must be at least 3 characters'),
  contactName: yup.string().required('Contact Name is required').min(3, 'Contact Name must be at least 3 characters'),
  contactEmail: yup
    .string()
    .required('Business Email is required')
    .email('Business Email must be a valid email format'),
  contactPhone: yup.string(),
});

const domainSchema = yup.object().shape({
  domain: yup
    .string()
    .required('Domain is required')
    .min(3, 'Domain must be at least 3 characters')
    .test('test-domain', 'Domain must be a valid domain format', (value) => isURL(value || '')),
});

interface Props {
  onApplicationCreated: (url: string) => void;
  agent: AgentDTO | undefined;
}

export const InitForm = ({ onApplicationCreated, agent }: Props) => {
  const classes = useStyles();
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search) as unknown as QueryParamsType, [search]);
  const { toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useCreatePublicApplications({
    onSuccess: onApplicationCreated,
    onError: (error: AxiosError | unknown) => {
      toasterErrorHandler(error, 'Something wrong happened when creating application. Please try again');
    },
  });

  const { mutate: mutateVerifyCaptcha, isLoading: isLoadingVerifyCaptcha } = useVerifyCaptcha({
    onSuccess: (result: { success: boolean }) => {
      if (result.success) {
        setcaptchaVerified(true);
      } else {
        setcaptchaVerified(false);
        recaptchaRef.current?.reset();
        toasterErrorHandler(new Error('Error'), 'Something wrong happened when verifying captcha. Please try again');
      }
    },
    onError: (error: AxiosError | unknown) => {
      setcaptchaVerified(false);
      recaptchaRef.current?.reset();
      toasterErrorHandler(error, 'Something wrong happened when verifying captcha. Please try again');
    },
  });

  const schema = isSecurityAgency(agent) ? defaultSchema.concat(domainSchema) : defaultSchema;
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const contactEmail = watch('contactEmail');

  // prefill form with query params
  React.useEffect(() => {
    if (queryParams.organizationName) {
      setValue('organizationName', queryParams.organizationName);
    }
    if (queryParams.contactEmail) {
      setValue('contactEmail', queryParams.contactEmail);
    }
    if (queryParams.contactPhone) {
      setValue('contactPhone', queryParams.contactPhone);
    }
    if (queryParams.contactName) {
      setValue('contactName', queryParams.contactName);
    }
  }, [queryParams, register, setValue]);

  const onSubmit = (data: Form) => {
    mutate({
      agentId: id,
      utmSource: queryParams.utmSource || 'Unknown',
      ...data,
    });
  };

  React.useEffect(() => {
    if (contactEmail) {
      const parts = contactEmail.split('@');
      if (parts.length > 1) {
        setValue('domain', parts[1]);
      }
    }
  }, [contactEmail, register, setValue]);

  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [captchaVerified, setcaptchaVerified] = React.useState<boolean>(false);

  const onCaptchaChange = () => {
    setcaptchaVerified(false);
    const recaptchaValue = recaptchaRef.current?.getValue();
    if (recaptchaValue) {
      mutateVerifyCaptcha({
        recaptchaValue,
      });
    }
  };

  const FORM_DESCRIPTION = isSecurityAgency(agent)
    ? ''
    : 'Whats your company name and a good email for us to send your quotes to?';

  return (
    <div className={classes.root}>
      <Typography variant='h1' className={classes.heading}>
        Tell us about your company
      </Typography>
      {FORM_DESCRIPTION && <p className={classes.description}>{FORM_DESCRIPTION}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div>
          <Controller
            name='organizationName'
            control={control}
            render={({ field }) => (
              <CustomTextField
                fullWidth
                {...field}
                label='Company Name *'
                data-cy='start-company-name-input'
                placeholder="Ollie's Olive Oil Company"
                error={!!errors.organizationName}
                InputLabelProps={{ shrink: true }}
                {...register('organizationName')}
              />
            )}
          />
          {errors.organizationName && <FormHelperText error>{errors.organizationName?.message}</FormHelperText>}
        </div>

        <div>
          <Controller
            name='contactEmail'
            control={control}
            render={({ field }) => (
              <>
                <CustomTextField
                  fullWidth
                  {...field}
                  data-cy='start-email-input'
                  label='Your Work Email *'
                  placeholder='john@olliesolive.com'
                  error={!!errors.contactEmail}
                  InputLabelProps={{ shrink: true }}
                  {...register('contactEmail')}
                />
              </>
            )}
          />
          {errors.contactEmail && <FormHelperText error>{errors.contactEmail?.message}</FormHelperText>}
        </div>

        {isSecurityAgency(agent) && (
          <div>
            <Controller
              name='domain'
              control={control}
              render={({ field }) => (
                <>
                  <CustomTextField
                    fullWidth
                    {...field}
                    data-cy='start-email-input'
                    label='Company Domain *'
                    placeholder='olliesolive.com'
                    error={!!errors.domain}
                    InputLabelProps={{ shrink: true }}
                    {...register('domain')}
                  />
                </>
              )}
            />
            {errors.domain && <FormHelperText error>{errors.domain?.message}</FormHelperText>}
          </div>
        )}

        <div>
          <Controller
            name='contactName'
            control={control}
            render={({ field }) => (
              <>
                <CustomTextField
                  fullWidth
                  {...field}
                  data-cy='start-name-input'
                  label='Your Full Name *'
                  placeholder='John Doe'
                  error={!!errors.contactName}
                  InputLabelProps={{ shrink: true }}
                  {...register('contactName')}
                />
              </>
            )}
          />
          {errors.contactName && <FormHelperText error>{errors.contactName?.message}</FormHelperText>}
        </div>

        <div>
          <Controller
            name='contactPhone'
            control={control}
            render={({ field }) => (
              <>
                <CustomTextField
                  fullWidth
                  {...field}
                  data-cy='start-phone-input'
                  label='Contact Phone'
                  placeholder='(123)-456-7890'
                  error={!!errors.contactPhone}
                  InputLabelProps={{ shrink: true }}
                  {...register('contactPhone')}
                />
              </>
            )}
          />
          {errors.contactPhone && <FormHelperText error>{errors.contactPhone?.message}</FormHelperText>}
        </div>

        <div>
          <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
        </div>

        <BottomActionsNavigation roundedButton className={classes.bottomNavigation}>
          <Button
            type='submit'
            color='primary'
            data-cy='start-create-button'
            variant='contained'
            disabled={isLoading || isLoadingVerifyCaptcha || !captchaVerified}
            className={classes.submitButton}
          >
            {isLoading ? 'Creating...' : 'Next'}
          </Button>
        </BottomActionsNavigation>
      </form>
    </div>
  );
};
