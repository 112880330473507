import { Hidden } from '@material-ui/core';
import cx from 'classnames';
import { BottomActionsNavigation } from 'components/BottomActionsNavigation';
import { Card } from 'components/Card';
import { PolicyDTO } from 'dtos/policy';
import { ReactComponent as CheckGreenIcon } from 'images/confirm.svg';
import { ReactComponent as CheckGreyIcon } from 'images/confirm-grey3.svg';
import { ReactComponent as PageIcon } from 'images/page.svg';
import { useCheckPolicyEnvelopeStatus, usePolicyDocumentsToSign } from 'queries/usePolicy';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';

import { QuoteSummary } from '../QuoteSummary';
import { useQueryParams } from '../useQueryParams';
import { useSectionStyles } from '../useSectionStyles';
import { SignButton } from './SignButton';

interface Props {
  policy: PolicyDTO;
}

export const SignDocumentsSection = ({ policy }: Props) => {
  const classes = useSectionStyles({ variant: 'sign' });
  const { data: documents, isLoading } = usePolicyDocumentsToSign(policy.id);

  // check signing status
  const { mutate: mutateEnvelope, isLoading: isCheckingEnvelopeStatus } = useCheckPolicyEnvelopeStatus();

  const queryEnvelope = useQueryParams();
  useEffect(() => {
    if (queryEnvelope.get('event') === 'signing_complete' && policy && !policy.attestationDone) {
      mutateEnvelope(policy.id);
    }
  }, [mutateEnvelope, queryEnvelope, policy]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Sign Documents</title>
      </Helmet>

      <section className={classes.section}>
        <div className={classes.heading} key='heading'>
          <h1 className={classes.title}>Sign Documents</h1>

          <p className={classes.description}>
            {isCheckingEnvelopeStatus ? (
              'We are checking the signing result, please wait a moment...'
            ) : (
              <>
                <Hidden smDown>Sign the following documents to purchase your quote.</Hidden>
                <Hidden mdUp>Sign the following to purchase your quote.</Hidden>
              </>
            )}
          </p>
        </div>

        <div className={classes.cardWrapper}>
          <div>
            <Hidden mdUp>
              <h3 className={classes.cardTitle}>Quote Summary</h3>
            </Hidden>
            <Card className={classes.summaryCard}>
              <div className={classes.cardInnerWrapper}>
                <Hidden smDown>
                  <h3 className={classes.cardTitle}>
                    <CheckGreenIcon className={classes.cardTitleIcon} />
                    Quote Summary
                  </h3>
                </Hidden>
                <QuoteSummary policy={policy} variant='sign' />
              </div>
            </Card>
          </div>

          <div>
            <Hidden mdUp>
              <h3 className={classes.cardTitle}>Sign Documents</h3>
            </Hidden>
            <Card className={classes.outlinedCard}>
              <div className={cx(classes.cardInnerWrapper, classes.cardDocsInnerWrapper)}>
                <Hidden smDown>
                  <h3 className={classes.cardTitle}>
                    <PageIcon className={classes.cardTitleIcon} />
                    Sign Documents
                  </h3>
                </Hidden>

                <div className={classes.viewWrapper}>
                  {isLoading ? 'Loading documents...' : null}
                  {documents?.map((d) => (
                    <div className={classes.viewItem} key={d.id}>
                      <p className={classes.viewItemKey}>
                        {!policy.attestationDone ? (
                          <CheckGreyIcon className={classes.viewIcon} />
                        ) : (
                          <CheckGreenIcon className={classes.viewIcon} />
                        )}

                        {DOCUMENT_TYPE_HUMANIZED_NAME[d.documentType]}
                      </p>
                    </div>
                  ))}
                  <BottomActionsNavigation className={classes.viewButtonWrapper}>
                    <SignButton policy={policy} isChecking={isCheckingEnvelopeStatus} />
                  </BottomActionsNavigation>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
};
