import { Hidden, makeStyles, Theme } from '@material-ui/core';
import { Money } from 'components/Money';
import { PolicyDTO } from 'dtos/policy';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

type Variant = 'sign' | 'thankyou' | 'billing';

interface StyleProps {
  variant: Variant;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  summaryWrapper: (props) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',

    ...(props.variant === 'thankyou' && {
      paddingBottom: theme.spacing(6),
    }),

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.25),
    },
  }),
  summaryItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > p': {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
  },

  summaryRating: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },
  summaryPremium: {
    margin: 0,
  },
  summaryCarrier: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
  },
  summaryValue: {
    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.SMALL_MEDIUM,
    },
  },
  summaryKey: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.GREY_2,
    margin: 0,
  },
}));

interface Props {
  policy: PolicyDTO;
}

export const QuoteSummary = ({ policy, variant }: Props & StyleProps) => {
  const classes = useStyles({ variant });

  return (
    <div className={classes.summaryWrapper}>
      <Hidden smDown>
        <div className={classes.summaryItem}>
          <p className={classes.summaryValue}>{policy.quote.carrier.displayName}</p>
          <p className={classes.summaryKey}>Carrier</p>
        </div>
        <div className={classes.summaryItem}>
          <h2 className={classes.summaryPremium}>
            <Money value={policy.quote.premium} typography={TYPOGRAPHY.TITLE_2} />
          </h2>
          <p className={classes.summaryKey}>Premium</p>
        </div>
        <div className={classes.summaryItem}>
          <p className={classes.summaryValue}>{policy.quote.carrier.amBestRating}</p>
          <p className={classes.summaryKey}>Rating</p>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.summaryItem}>
          <p className={classes.summaryRating}>{policy.quote.carrier.amBestRating} Rating</p>
        </div>
        <div className={classes.summaryItem}>
          <h2 className={classes.summaryPremium}>
            <Money value={policy.quote.premium} typography={TYPOGRAPHY.TITLE_2} />
          </h2>
        </div>
        <div className={classes.summaryItem}>
          <p className={classes.summaryCarrier}> Premium from {policy.quote.carrier.displayName}</p>
        </div>
      </Hidden>
    </div>
  );
};
