import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { Step } from 'components/Step';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SUPPORT_URL } from 'telivy-constants';
import { isScanApplication } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { ConfigureGoogleWS } from './ConfigureGoogleWS';
import { ConfigureMicrosoft365 } from './ConfigureMicrosoft365';
import { ConfigurePhishingSection } from './ConfigurePhishingSection';
import { InstallAgentsSection } from './InstallAgentsSection';
import { InstallSaasAlertsSection } from './InstallSaasAlerts';
import { ScanBrowserPasswordsSection } from './ScanBrowserPasswordsSection';
import { UninstallAgentsSection } from './UninstallAgentsSection';
import { UploadEmployeeListSection } from './UploadEmployeeListSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(6),
  },
  sidebar: {
    width: 270,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  content: {
    flex: 1,
    paddingRight: theme.spacing(3),
  },
  steps: {},

  greyText: {
    color: COLORS.GREY_1,
  },
  redText: {
    color: COLORS.RED_1,
  },
  greenText: {
    color: COLORS.GREEN_1,
  },

  message: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  setSecurityOverallScore: (score: number | null) => void;
}

export enum CONFIGURATION_STEP {
  INSTALLATION = 'installation',
  BROWSER_PASSWORDS = 'browser-passwords',
  EMPLOYEES_LIST = 'employees-list',
  PHISHING = 'phishing',
  SAAS_ALERTS = 'saas-alerts',
  CLOUD_SOFTWARES = 'cloud-softwares',
  UNINSTALLATION = 'uninstallation',
}

interface StepConfiguration {
  type: CONFIGURATION_STEP;
  completed: boolean;
  label: string;
  locked?: boolean;
}

export const ApplicationConfigurationView = ({ application, setSecurityOverallScore }: Props) => {
  const classes = useStyles();
  const deepScanId = application.deepScanId || '';
  const { data: applicationData } = useApplicationSecurityScan(application.id, 'latest');
  const { data, isLoading, error } = useDeepScan(deepScanId, {
    enabled: Boolean(deepScanId),
    retry: 1,
  });
  const [step, setStep] = useState(CONFIGURATION_STEP.INSTALLATION);

  useEffect(() => {
    if (applicationData) {
      setSecurityOverallScore(applicationData.securityOverallScore);
    }
  }, [applicationData, setSecurityOverallScore]);

  const handleNextStep = useCallback(() => {
    if (!data?.isLightScan) {
      switch (step) {
        case CONFIGURATION_STEP.INSTALLATION:
          //   setStep(CONFIGURATION_STEP.BROWSER_PASSWORDS);
          //   break;
          // case CONFIGURATION_STEP.BROWSER_PASSWORDS:
          setStep(CONFIGURATION_STEP.EMPLOYEES_LIST);
          break;
        case CONFIGURATION_STEP.EMPLOYEES_LIST:
          setStep(CONFIGURATION_STEP.PHISHING);
          break;
        case CONFIGURATION_STEP.PHISHING:
          setStep(CONFIGURATION_STEP.SAAS_ALERTS);
          break;
        case CONFIGURATION_STEP.SAAS_ALERTS:
          setStep(CONFIGURATION_STEP.CLOUD_SOFTWARES);
          break;
        case CONFIGURATION_STEP.CLOUD_SOFTWARES:
          setStep(CONFIGURATION_STEP.UNINSTALLATION);
          break;
      }
    }
  }, [step, setStep, data]);

  const steps = useMemo(() => {
    const steps: StepConfiguration[] = [
      {
        type: CONFIGURATION_STEP.INSTALLATION,
        completed:
          data?.installedAgents ||
          [
            ApplicationStatus.IN_PROGRESS,
            ApplicationStatus.REPORT_REQUESTED,
            ApplicationStatus.REPORT_COMPLETED,
            ApplicationStatus.RESCAN_ALL,
            ApplicationStatus.UNINSTALL_ALL,
          ].includes(application.applicationStatus) ||
          false,
        label: 'Install Telivy Agent',
      },
      // {
      //   type: CONFIGURATION_STEP.BROWSER_PASSWORDS,
      //   completed: data?.scannedBrowserPasswords || false,
      //   label: 'Scan Browser Passwords',
      // },
    ];

    if (!data?.isLightScan) {
      steps.push({
        type: CONFIGURATION_STEP.PHISHING,
        completed: data?.configuredPhishing || false,
        label: 'Configure Phishing',
      });

      if (data?.saasAlertLink) {
        steps.push({
          type: CONFIGURATION_STEP.SAAS_ALERTS,
          completed: data?.completedSaasAlerts || false,
          label: 'Configure SaaS Applications',
        });
      }
    }

    steps.push({
      type: CONFIGURATION_STEP.CLOUD_SOFTWARES,
      completed: data?.m365Connected || data?.gwsConnected || false,
      label: 'Configure Softwares',
    });

    steps.push({
      type: CONFIGURATION_STEP.EMPLOYEES_LIST,
      completed: data?.uploadedEmployeeList || false,
      label: data?.isLightScan ? 'Dark Web Email List' : 'Upload Employee List',
    });

    steps.push({
      type: CONFIGURATION_STEP.UNINSTALLATION,
      completed: data?.installedAgents || false,
      label: 'Uninstall Telivy Agent',
    });

    return steps;
  }, [data, application]);

  const section = useMemo(() => {
    if (!data) {
      return null;
    }

    const map = {
      [CONFIGURATION_STEP.INSTALLATION]: (
        <InstallAgentsSection onVerify={handleNextStep} deepScan={data} application={application} />
      ),
      [CONFIGURATION_STEP.BROWSER_PASSWORDS]: <ScanBrowserPasswordsSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.EMPLOYEES_LIST]: <UploadEmployeeListSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.PHISHING]: <ConfigurePhishingSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.SAAS_ALERTS]: <InstallSaasAlertsSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.CLOUD_SOFTWARES]: (
        <>
          <ConfigureMicrosoft365 onVerify={handleNextStep} deepScan={data} application={application} />
          <br />
          <br />
          <br />
          {application.agent.agency?.enableGoogleWorkspace && (
            <ConfigureGoogleWS onVerify={handleNextStep} deepScan={data} application={application} />
          )}
        </>
      ),
      [CONFIGURATION_STEP.UNINSTALLATION]: (
        <UninstallAgentsSection onVerify={handleNextStep} deepScan={data} application={application} />
      ),
    };

    return map[step];
  }, [data, handleNextStep, application, step]);

  if (error?.response?.status === 403) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Forbidden'
          text={<>You do not have access to view this page.</>}
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  if (!isScanApplication(application) || (!data && !isLoading)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Configuration - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.root}>
        <div className={classes.sidebar}>
          <div className={classes.steps}>
            {steps.map((stepData, index) => {
              if (stepData.type == CONFIGURATION_STEP.UNINSTALLATION) return;

              return (
                <Step
                  totalQuestions={3}
                  currIndex={3}
                  progress={0}
                  doNotGreyOut
                  readonly
                  checkV2
                  key={index}
                  idx={index}
                  title={stepData.label}
                  locked={stepData.locked}
                  isActive={stepData.type === step}
                  onClick={() => setStep(stepData.type)}
                  isError={data ? !stepData.completed : undefined}
                  description={stepData.completed ? 'Complete' : 'Incomplete'}
                  descriptionClassName={stepData.completed ? classes.greenText : classes.redText}
                />
              );
            })}

            <Step
              doNotGreyOut
              progress={0}
              currIndex={3}
              readonly
              checkV2
              isError={true}
              hideErrors
              descriptionClassName={classes.greyText}
              idx={4}
              title='Uninstall Telivy Agent'
              isActive={CONFIGURATION_STEP.UNINSTALLATION === step}
              description='After CSRA'
              onClick={() => {
                setStep(CONFIGURATION_STEP.UNINSTALLATION);
              }}
              totalQuestions={3}
            />
          </div>
        </div>

        <main className={classes.content}>
          <SecurityCard.Badge variant='info' className={classes.message}>
            Please refer to our{' '}
            <a href={SUPPORT_URL} target='_blank' rel='noreferrer'>
              support center
            </a>{' '}
            for help. Reach out to{' '}
            <a href={`mailto:support@telivy.com?subject=Question about ${applicationData?.domain}`}>
              support@telivy.com
            </a>{' '}
            if you still have questions
          </SecurityCard.Badge>
          {section}
        </main>
      </div>
    </ApplicationViewContainer>
  );
};
