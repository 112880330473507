import { makeStyles } from '@material-ui/core/styles';
import { Badge } from 'components/Badge';
import { LoadingContainer } from 'components/LoadingContainer';
import { SingleFilter } from 'components/SingleFilter';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO, MonitoringFrequency, MonitoringSnapshotDTO } from 'dtos/application';
// import { useDataRiskByDarkWebSecurityChart } from 'hooks/charts/useDataRiskByDarkWebSecurityChart';
// import { useDataRiskByPasswordSecurityChart } from 'hooks/charts/useDataRiskByPasswordSecurityChart';
// import { useDataRiskByVulnerabilityChart } from 'hooks/charts/useDataRiskByVulnerabilityChart';
import { useMonitoringApplicationSecurityChart } from 'hooks/charts/monitoring/useApplicationSecurityChart';
import { useMonitoringDarkWebPresenceChart } from 'hooks/charts/monitoring/useDarkWebPresenceChart';
import { useMonitoringDataSecurityChart } from 'hooks/charts/monitoring/useDataSecurityChart';
import { useMonitoringIdentityAccessChart } from 'hooks/charts/monitoring/useIdentityAccessChart';
import { useMonitoringInternalGradesHistoryChart } from 'hooks/charts/monitoring/useInternalGradesHistoryChart';
import { useMonitoringM365SecurityChart } from 'hooks/charts/monitoring/useM365SecurityChart';
import { useMonitoringNetworkSecurityChart } from 'hooks/charts/monitoring/useNetworkSecurityChart';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
// import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { useApplicationMonitoringSnapshots } from 'queries/useApplications';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useEffect, useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { getGradeBadgeVariant } from 'templates/SecurityAssessments/components/ExecutiveSummaryItem';
// import { ScoreBox, ScoreRanking } from 'templates/SecurityAssessments/components/ScoreBox';

// import { MouseOverPopover } from '../../components/MouseOverPopover';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  red: {
    color: COLORS.RED_1,
    fontStyle: 'italic',
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(4),
  },

  subTitle: {
    ...TYPOGRAPHY.LARGE,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(2),
  },

  badge: {
    marginRight: theme.spacing(2),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },

  charts: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  chartColumn: {
    width: '50%',
  },
  chartContainer: {
    flex: 1,
    // borderRadius: theme.spacing(1),
    // border: `1px solid ${COLORS.GREY_5}`,
    backgroundColor: `rgba(#F3F7F9, 0.5)`,
    // padding: theme.spacing(3),
  },

  lineChart: {
    height: 400,
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  onlyActive: boolean;
}

const PER_PAGE = 10;
const LIMITS: Record<string, number> = {
  '7 days': 7,
  '30 days': 30,
  '2 months': 60,
  YTD: 365,
};

export const RiskAssessmentHistory = ({ application, onlyActive }: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const [selectedLimit, setSelectedLimit] = useState<keyof typeof LIMITS>('YTD');
  useEffect(() => {
    if (application.monitoringFrequency == MonitoringFrequency.WEEKLY) {
      setSelectedLimit('30 days');
    }
  }, [application, setSelectedLimit]);

  const deepScanId = application.deepScanId || '';
  const { data, isLoading } = useApplicationMonitoringSnapshots(application.id, LIMITS[selectedLimit]);
  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);

  const [sorter, setSorter] = useState<Sorter<keyof MonitoringSnapshotDTO> | undefined>({
    key: 'createdAt',
    order: SortOrder.DESC,
  });

  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const allAssets = useMemo(() => uniq(([] as string[]).concat(...(data?.map((d) => d.hostnames) ?? []))), [data]);

  // const allAssets = useMemo(() => (data && data[0]?.hostnames) || [], [data]);

  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const allAccounts = useMemo(
    () => uniq(([] as string[]).concat(...(data?.map((d) => Object.keys(d.darkWebScanFindings)) ?? []))),
    [data],
  );

  const { chart: networkSecurityChart } = useMonitoringNetworkSecurityChart(
    enableCharts,
    data,
    selectedAssets,
    onlyActive,
    classes.lineChart,
  );
  const { chart: dataSecurityChart } = useMonitoringDataSecurityChart(
    enableCharts,
    data,
    selectedAssets,
    onlyActive,
    classes.lineChart,
  );
  const { chart: identityAccessChart } = useMonitoringIdentityAccessChart(
    enableCharts,
    data,
    selectedAssets,
    onlyActive,
    classes.lineChart,
  );
  const { chart: darkWebPresenceChart } = useMonitoringDarkWebPresenceChart(
    enableCharts,
    data,
    selectedAccounts,
    classes.lineChart,
  );
  const { chart: m365SecurityChart } = useMonitoringM365SecurityChart(enableCharts, data, classes.lineChart);
  const { chart: applicationSecurityChart } = useMonitoringApplicationSecurityChart(
    enableCharts,
    data,
    classes.lineChart,
  );
  const { chart: gradesHistoryChart } = useMonitoringInternalGradesHistoryChart(enableCharts, data, classes.lineChart);

  const columns = useMemo((): Column<MonitoringSnapshotDTO, keyof MonitoringSnapshotDTO>[] => {
    return [
      {
        title: 'Date',
        sortKey: 'createdAt',
        render: (row) => {
          const finished = Object.keys(row.scanStatus || {}).every(
            (h) =>
              (row.scanStatus[h].telivyAgentInstalledAt ||
                (row.scanStatus[h].system.scan_apps_os_version &&
                  row.nmapScanStats[h] &&
                  row.scanStatus[h].system.scan_pii_total_found &&
                  row.piiScanStats[h] &&
                  row.scanStatus[h].system.scan_applications_total_found)) &&
              row.scanStatus[h].browserPasswordExecutedAt,
          );
          const message = finished ? 'Latest Scan' : 'Scan In Progress';
          return row.isLive ? message : moment(row.createdAt).format('Do MMMM YYYY');
        },
      },
      {
        title: 'Critical Vulnerabilities',
        render: (row) => {
          const criticalCount = Object.values(row.nmapScanStats)
            .map((s) => s.cveCounts?.high || 0)
            .reduce((a, b) => a + b, 0);

          const score = row.reportData.networkSecurity?.score;

          return (
            <>
              <Badge
                typography={TYPOGRAPHY.LARGE}
                variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
                className={classes.badge}
              >
                {parseScoreToGrade((score || 0) * 100)}
              </Badge>
              <div>{criticalCount}</div>
            </>
          );
        },
      },
      {
        title: 'Total Data Risk',
        render: (row) => {
          const dataRisk = `${deepScan?.piiConfig?.currency}${Object.values(row.piiScanStats)
            .map((s) => s.risk || 0)
            .reduce((a, b) => a + b, 0)
            .toLocaleString()}`;

          const score = row.reportData.dataSecurity?.score;

          return (
            <>
              <Badge
                typography={TYPOGRAPHY.LARGE}
                variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
                className={classes.badge}
              >
                {parseScoreToGrade((score || 0) * 100)}
              </Badge>
              <div>{dataRisk}</div>
            </>
          );
        },
      },
      {
        title: 'Weak Passwords',
        render: (row) => {
          const weakPasswords = Object.values(row.passwordScanStats)
            .map((s) => s.passwordStrength?.weak || 0)
            .reduce((a, b) => a + b, 0);

          const score = row.reportData.identityAccessManagement?.score;

          return (
            <>
              <Badge
                typography={TYPOGRAPHY.LARGE}
                variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
                className={classes.badge}
              >
                {parseScoreToGrade((score || 0) * 100)}
              </Badge>
              <div>{weakPasswords}</div>
            </>
          );
        },
      },
      {
        title: 'Dark Web Accounts',
        render: (row) => {
          const darkWebAccounts = Object.keys(row.darkWebScanFindings).length;

          const score = row.reportData.securityAwarenessTraining?.score;

          return (
            <>
              <Badge
                typography={TYPOGRAPHY.LARGE}
                variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
                className={classes.badge}
              >
                {parseScoreToGrade((score || 0) * 100)}
              </Badge>
              <div>{darkWebAccounts}</div>
            </>
          );
        },
      },
      {
        title: 'M365 Security Score',
        render: (row) => {
          const m365Score = row.reportData.m365Security?.score
            ? `${row.reportData.m365Security?.score.toFixed(2)}%`
            : 'N/A';

          const score = row.reportData.m365Security?.score;

          return (
            <>
              <Badge
                typography={TYPOGRAPHY.LARGE}
                variant={getGradeBadgeVariant(parseScoreToGrade(score || 0))}
                className={classes.badge}
              >
                {parseScoreToGrade(score)}
              </Badge>
              <div>{m365Score}</div>
            </>
          );
        },
      },
      {
        title: 'Risky Applications',
        render: (row) => {
          const riskApps = row.appsScanStats.riskyApps?.high || 0;

          const score = row.reportData.applicationSecurity?.score;

          return (
            <>
              <Badge
                typography={TYPOGRAPHY.LARGE}
                variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
                className={classes.badge}
              >
                {parseScoreToGrade((score || 0) * 100)}
              </Badge>
              <div>{riskApps}</div>
            </>
          );
        },
      },
    ];
  }, [deepScan, classes]);

  const sortedData = useMemo(() => {
    if (sorter) {
      const sortedData = sortBy(data, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return sortedData.reverse();
      }

      return sortedData;
    }

    return data;
  }, [data, sorter]);

  const pageData = useMemo(() => {
    return (sortedData || []).slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE);
  }, [page, sortedData]);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <>
      <div className={classes.section}>
        <div className={classes.charts}>
          <div className={classes.chartColumn}>
            <div className={classes.filterBar}>
              <div className={classes.subTitle}>
                Network Security
                <br />
                <span className={classes.red}> Number of Critical Vulnerabilities</span>
              </div>

              <div>
                <SingleFilter
                  buttonText={`Assets ${selectedAssets.length > 0 ? `(${selectedAssets.length})` : ''}`}
                  defaultSelectedOptions={selectedAssets}
                  popperText='Filter Assets'
                  showDropdownIcon
                  options={allAssets}
                  setSelectedOptions={(a) => {
                    setSelectedAssets(a);
                  }}
                />
                <SingleFilter
                  type='radio'
                  buttonText={selectedLimit}
                  defaultSelectedOptions={[selectedLimit]}
                  popperText='Select Period'
                  showDropdownIcon
                  options={Object.keys(LIMITS)}
                  setSelectedOptions={(a) => {
                    setSelectedLimit(a[0]);
                  }}
                  startIcon={<></>}
                  className={classes.marginLeft}
                />
              </div>
            </div>

            <div className={classes.chartContainer}>{networkSecurityChart}</div>
          </div>

          <div className={classes.chartColumn}>
            <div className={classes.filterBar}>
              <div className={classes.subTitle}>
                Data Security
                <br />
                <span className={classes.red}> Total Data Risk ({deepScan?.piiConfig?.currency})</span>
              </div>

              <div>
                <SingleFilter
                  buttonText={`Assets ${selectedAssets.length > 0 ? `(${selectedAssets.length})` : ''}`}
                  defaultSelectedOptions={selectedAssets}
                  popperText='Filter Assets'
                  showDropdownIcon
                  options={allAssets}
                  setSelectedOptions={(a) => {
                    setSelectedAssets(a);
                  }}
                />
                <SingleFilter
                  type='radio'
                  buttonText={selectedLimit}
                  defaultSelectedOptions={[selectedLimit]}
                  popperText='Select Period'
                  showDropdownIcon
                  options={Object.keys(LIMITS)}
                  setSelectedOptions={(a) => {
                    setSelectedLimit(a[0]);
                  }}
                  startIcon={<></>}
                  className={classes.marginLeft}
                />
              </div>
            </div>

            <div className={classes.chartContainer}>{dataSecurityChart}</div>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.charts}>
          <div className={classes.chartColumn}>
            <div className={classes.filterBar}>
              <div className={classes.subTitle}>
                Identity and Access Management
                <br />
                <span className={classes.red}> Number of Weak Password</span>
              </div>

              <div>
                <SingleFilter
                  buttonText={`Assets ${selectedAssets.length > 0 ? `(${selectedAssets.length})` : ''}`}
                  defaultSelectedOptions={selectedAssets}
                  popperText='Filter Assets'
                  showDropdownIcon
                  options={allAssets}
                  setSelectedOptions={(a) => {
                    setSelectedAssets(a);
                  }}
                />
                <SingleFilter
                  type='radio'
                  buttonText={selectedLimit}
                  defaultSelectedOptions={[selectedLimit]}
                  popperText='Select Period'
                  showDropdownIcon
                  options={Object.keys(LIMITS)}
                  setSelectedOptions={(a) => {
                    setSelectedLimit(a[0]);
                  }}
                  startIcon={<></>}
                  className={classes.marginLeft}
                />
              </div>
            </div>

            <div className={classes.chartContainer}>{identityAccessChart}</div>
          </div>

          <div className={classes.chartColumn}>
            <div className={classes.filterBar}>
              <div className={classes.subTitle}>
                Dark Web Presence
                <br />
                <span className={classes.red}> Number of Dark Web Findings</span>
              </div>

              <div>
                <SingleFilter
                  buttonText={`Accounts ${selectedAccounts.length > 0 ? `(${selectedAccounts.length})` : ''}`}
                  defaultSelectedOptions={selectedAccounts}
                  popperText='Filter Accounts'
                  showDropdownIcon
                  options={allAccounts}
                  setSelectedOptions={(a) => {
                    setSelectedAccounts(a);
                  }}
                />
                <SingleFilter
                  type='radio'
                  buttonText={selectedLimit}
                  defaultSelectedOptions={[selectedLimit]}
                  popperText='Select Period'
                  showDropdownIcon
                  options={Object.keys(LIMITS)}
                  setSelectedOptions={(a) => {
                    setSelectedLimit(a[0]);
                  }}
                  startIcon={<></>}
                  className={classes.marginLeft}
                />
              </div>
            </div>

            <div className={classes.chartContainer}>{darkWebPresenceChart}</div>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.charts}>
          <div className={classes.chartColumn}>
            <div className={classes.filterBar}>
              <div className={classes.subTitle}>
                M365 Security
                <br />
                <span className={classes.red}> Security Score (%)</span>
              </div>

              <div>
                <SingleFilter
                  type='radio'
                  buttonText={selectedLimit}
                  defaultSelectedOptions={[selectedLimit]}
                  popperText='Select Period'
                  showDropdownIcon
                  options={Object.keys(LIMITS)}
                  setSelectedOptions={(a) => {
                    setSelectedLimit(a[0]);
                  }}
                  startIcon={<></>}
                  className={classes.marginLeft}
                />
              </div>
            </div>

            <div className={classes.chartContainer}>{m365SecurityChart}</div>
          </div>

          <div className={classes.chartColumn}>
            <div className={classes.filterBar}>
              <div className={classes.subTitle}>
                Application Security
                <br />
                <span className={classes.red}> Number of Risky Applications Visited</span>
              </div>

              <div>
                <SingleFilter
                  type='radio'
                  buttonText={selectedLimit}
                  defaultSelectedOptions={[selectedLimit]}
                  popperText='Select Period'
                  showDropdownIcon
                  options={Object.keys(LIMITS)}
                  setSelectedOptions={(a) => {
                    setSelectedLimit(a[0]);
                  }}
                  startIcon={<></>}
                  className={classes.marginLeft}
                />
              </div>
            </div>

            <div className={classes.chartContainer}>{applicationSecurityChart}</div>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.subTitle}>
            Score History
            <br />
            <span className={classes.red}> Grades Over Time</span>
          </div>

          <div>
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>

        <div className={classes.chartContainer}>{gradesHistoryChart}</div>
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.subTitle}>
            Score Logs
            <br />
            <span className={classes.red}> Risk Over Time</span>
          </div>

          <div>
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>

        <div className={classes.boxBorder}>
          <Table<MonitoringSnapshotDTO, keyof MonitoringSnapshotDTO>
            rowContentCentered
            sorter={sorter}
            pagination={isLoading ? undefined : { page, perPage: PER_PAGE, total: data?.length || 0 }}
            columns={columns}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setPage(pagination?.page);
              }

              const isSorterChanging = sorter?.key !== sorting?.key || sorter?.order !== sorting?.order;
              setSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setPage(0);
              }
            }}
            className={classes.table}
            rowKey={(row) => row.id}
            data={pageData}
            loading={isLoading}
            noOverflow
          />
        </div>
      </div>
    </>
  );
};
