import { Breadcrumbs } from 'components/Breadcrumbs';
import { useApplication } from 'queries/useApplications';
import React from 'react';

interface Props {
  activeItemIdx: number;
  applicationId: string;
}

const BREADCRUMBS = [
  { title: 'Application' },
  { title: 'Quotes' },
  { title: 'Enroll' },
  { title: 'Sign Documents' },
  { title: 'Billing' },
];

const BREADCRUMBS_NO_BILLING = [
  { title: 'Application' },
  { title: 'Quotes' },
  { title: 'Enroll' },
  { title: 'Sign Documents' },
];

export const AttestationBreadcrumbs = ({ activeItemIdx, applicationId }: Props) => {
  const { data } = useApplication(applicationId, {
    enabled: !!applicationId,
  });

  return (
    <Breadcrumbs activeItemIdx={activeItemIdx} items={data?.billingEnabled ? BREADCRUMBS : BREADCRUMBS_NO_BILLING} />
  );
};
