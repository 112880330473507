import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO, SecurityScanDTO } from 'dtos/application';
import { DeepScanTargetDTO } from 'dtos/deep-scan';
import { SecurityFindingBySlugDTO, SecurityScanType, SecuritySeverity } from 'dtos/security-finding';
// import { useByRiskBySeverityChart } from 'hooks/charts/useByRiskBySeverityChart';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanPreviousFindings } from 'hooks/external-scan/useExternalScanPreviousFindings';
import { useChartImage } from 'hooks/useChartImage';
// import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanTargetsNmap } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { createSecurityRoute } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { SourceRoute } from '../..';
import { renderScanResults } from '../../components/ExternalScanItem';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';
// import { VulnerabilitiesDetailsModal } from '../../components/VulnerabilitiesDetailsModal';
import { NmapVulnerabilitiesDetailsModal } from '../../components/VulnerabilitiesDetailsModal/NmapVulnerabilitiesDetailsModal';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },

  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },

  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },

  red: {
    color: COLORS.RED_1,
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
  },

  headerRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  chartContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    '&:first-child': {
      flex: 2,
    },
  },
  chart: {
    height: 380,
  },
  actionButton: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  sourceRoute: SourceRoute;
  securityScanId: string;
  application: ApplicationWithAgentDTO;
  // isAdmin?: boolean;
}

export const calculateScoreLabel = (score?: number) => {
  let rating: ScoreRanking = 'Low';
  if (score && score > 900) {
    rating = 'Low';
  } else if (score && score > 500) {
    rating = 'Medium';
  } else {
    rating = 'High';
  }

  return rating;
};

export const DeepScanNetworkSecurity = ({
  applicationId,
  sourceRoute,
  securityScan,
  securityScanId,
  application,
}: Props) => {
  const classes = useStyles();
  // const [sorter, setSorter] = useState<Sorter<keyof NodewareAsset> | undefined>({
  //   key: 'score',
  //   order: SortOrder.ASC,
  // });

  const [targetSorter, setTargetSorter] = useState<Sorter<string> | undefined>({
    key: 'nmapScanStats.cveCounts.high',
    order: SortOrder.DESC,
  });

  const {
    findingsByType,
    isErrorPatchingCadence,
    isErrorScanNetwork,
    isErrorScanIpReputation,
    isLoadingPatchingCadence,
    isLoadingScanNetwork,
    isLoadingScanIpReputation,
  } = useExternalScanFindings({ securityScan });

  const { previousFindingsByType } = useExternalScanPreviousFindings({ securityScan });

  const getFindingUrl = (finding: SecurityFindingBySlugDTO) =>
    createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug);

  const deepScanId = application.deepScanId || '';
  const { data: deepScan } = useDeepScan(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  // const { data: nodewareAssets, isLoading: isLoadingNodeware } = useDeepScanNodeware(deepScanId, {
  //   enabled: Boolean(deepScanId && deepScan?.nodewareId),
  // });
  // const enableCharts = useChartsEnabled(deepScan, application);

  const { data: deepScanTargets, isLoading: isTargetsLoading } = useDeepScanTargetsNmap(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  // const columns = useMemo((): Column<NodewareAsset, keyof NodewareAsset>[] => {
  //   return [
  //     {
  //       title: 'Host',
  //       sortKey: 'hostname',
  //       render: (row) => (row.hostname || row.name).replace(' (Agent)', ''),
  //     },
  //     {
  //       title: 'Mac Address',
  //       sortKey: 'macAddress',
  //       render: (row) => row.macAddress,
  //     },
  //     {
  //       title: 'High Severity',
  //       sortKey: 'totals.critical',
  //       render: (row) => (row.totals?.critical || 0) + (row.totals?.high || 0),
  //     },
  //     {
  //       title: 'Medium Severity',
  //       sortKey: 'totals.medium',
  //       render: (row) => row.totals?.medium || 0,
  //     },
  //     {
  //       title: 'Low Severity',
  //       sortKey: 'totals.low',
  //       render: (row) => row.totals?.low || 0,
  //     },
  //     {
  //       title: 'Informational',
  //       sortKey: 'totals.info',
  //       render: (row) => row.totals?.info || 0,
  //     },
  //     {
  //       title: 'Actions',
  //       // sortKey: 'score',
  //       render: (row) => {
  //         // const rating: ScoreRanking = calculateScoreLabel(row.score);

  //         return (
  //           <div className={classes.row}>
  //             {/*<ScoreBox ranking={rating} label={`${row.score}`} />*/}
  //             <VulnerabilitiesDetailsModal
  //               nodewareAsset={row}
  //               deepScanId={deepScanId}
  //               label='Details'
  //               className={classes.actionButton}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   ];
  // }, [classes, deepScanId]);

  // const { sortedNodewareAssets } = useByRiskBySeverityChart(enableCharts, nodewareAssets, sorter);
  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  // const nodewareAssetsCharts = useChartImage(
  //   { className: classes.chart, title: 'Vulnerability by Devices' },
  //   charts?.nodewareAssets,
  //   deepScan,
  //   application,
  // );
  // const nodewareAssetsByRiskChart = useChartImage(
  //   { className: classes.chart, title: 'Device Score by Risk Level' },
  //   charts?.nodewareAssetsByRisk,
  //   deepScan,
  //   application,
  // );

  const internalVulnerabilitiesChart = useChartImage(
    { className: classes.chart, title: 'Vulnerability by Devices' },
    charts?.internalVulnerabilities,
    deepScan,
    application,
  );
  const targetsByInternalVulnerabilitiesRiskChart = useChartImage(
    { className: classes.chart, title: 'Vulnerability by Risk Level' },
    charts?.targetsByInternalVulnerabilitiesRisk,
    deepScan,
    application,
  );

  const targetColumns = useMemo((): Column<DeepScanTargetDTO, string>[] => {
    return [
      {
        title: 'Host',
        sortKey: 'hostname',
        render: (row) => row.hostname,
      },
      {
        title: 'Mac Address',
        sortKey: 'macAddress',
        render: (row) => row.macAddress,
      },
      {
        title: 'Scan Status',
        render: (row) => {
          let ranking: ScoreRanking = 'Medium';
          let status = 'Pending';

          if (
            row.nmapScanStats &&
            ((row.telivyAgentDetails?.scan_applications_total_found !== undefined &&
              row.telivyAgentDetails?.scan_applications_total_found !== null) ||
              Object.values(row.browserPasswordExecutionDetails || {}).filter(
                (b) => b.scan_applications_total_found !== undefined && b.scan_applications_total_found !== null,
              ).length > 0)
          ) {
            ranking = 'Low';
            status = 'Finished';
          } else if (
            row.telivyAgentDetails?.scan_vulnerabilities_error ||
            row.telivyAgentDetails?.scan_applications_error
          ) {
            ranking = 'High';
            status = 'Error';
          } else if (!row.telivyAgentDetails?.scan_network_started || !row.telivyAgentDetails?.scan_applications_date) {
            ranking = 'None';
            status = 'Not started';
          }

          return <ScoreBox ranking={ranking} label={status} />;
        },
      },
      {
        title: 'High Severity',
        sortKey: 'nmapScanStats.cveCounts.high',
        render: (row) => row.nmapScanStats?.cveCounts?.high,
      },
      {
        title: 'Medium Severity',
        sortKey: 'nmapScanStats.cveCounts.medium',
        render: (row) => row.nmapScanStats?.cveCounts?.medium,
      },
      {
        title: 'Low Severity',
        sortKey: 'nmapScanStats.cveCounts.low',
        render: (row) => row.nmapScanStats?.cveCounts?.low,
      },
      {
        title: 'Informational',
        sortKey: 'nmapScanStats.cveCounts.info',
        render: (row) => row.nmapScanStats?.cveCounts?.info,
      },
      {
        title: 'Actions',
        render: (row) => {
          return (
            row.nmapScanStats && (
              <div className={classes.row}>
                <NmapVulnerabilitiesDetailsModal
                  target={row}
                  deepScanId={deepScanId}
                  className={classes.actionButton}
                  label='Details'
                />
              </div>
            )
          );
        },
      },
    ];
  }, [classes, deepScanId]);

  const sortedDeepScanTargets = useMemo(() => {
    if (targetSorter && deepScanTargets) {
      const notNullTargets = deepScanTargets.filter((d) => d.nmapScanStats);
      const nullTargets = deepScanTargets.filter((d) => !d.nmapScanStats);

      const notNullData = sortBy(notNullTargets, targetSorter.key);
      const nullData = sortBy(nullTargets, targetSorter.key);

      if (targetSorter.order === SortOrder.DESC) {
        return ([] as DeepScanTargetDTO[]).concat(notNullData.reverse(), nullData.reverse());
      }

      return ([] as DeepScanTargetDTO[]).concat(notNullData, nullData);
    }

    return deepScanTargets;
  }, [deepScanTargets, targetSorter]);

  const isLoadingAny = !securityScan || isTargetsLoading;

  if (isLoadingAny) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Network Security</div>

      {/*<div className={classes.section}>
        <div className={classes.title}>
          Internal Vulnerabilities
          <span className={classes.findings}> ({sortedNodewareAssets.length} devices)</span>
        </div>

        <div className={classes.headerRow}>
          <div className={classes.chartContainer}>{nodewareAssetsCharts}</div>
          <div className={classes.chartContainer}>{nodewareAssetsByRiskChart}</div>
        </div>
        <div className={classes.boxBorder}>
          <Table<NodewareAsset, keyof NodewareAsset>
            sorter={sorter}
            columns={columns}
            onChange={(pagination, sorting) => {
              setSorter(sorting);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => row.uuid}
            data={sortedNodewareAssets}
            loading={isLoadingNodeware}
          />
        </div>
      </div>*/}

      <div className={classes.section}>
        <div className={classes.summary} id='summary'>
          <div className={classes.item}>
            <div className={classes.number}>{(deepScanTargets || []).length.toLocaleString()}</div>
            <div className={classes.description}>Total Assets Scanned</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>
              {(deepScanTargets || [])
                .map((t) => t.nmapScanStats?.cveCounts?.high || 0)
                .reduce((a, b) => a + b, 0)
                .toLocaleString()}
            </div>
            <div className={classes.description}>Critical Internal Vulnerabilities</div>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Internal Vulnerabilities
          <span className={classes.findings}> ({sortedDeepScanTargets?.length} devices)</span>
        </div>

        <div className={classes.headerRow}>
          <div className={classes.chartContainer}>{internalVulnerabilitiesChart}</div>
          <div className={classes.chartContainer}>{targetsByInternalVulnerabilitiesRiskChart}</div>
        </div>
        <div className={classes.boxBorder}>
          <Table<DeepScanTargetDTO, string>
            sorter={targetSorter}
            columns={targetColumns}
            onChange={(pagination, sorting) => {
              setTargetSorter(sorting);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => row.id}
            data={sortedDeepScanTargets}
            loading={isTargetsLoading}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          External Vulnerabilities
          <span className={classes.findings}>
            {' '}
            (
            {
              findingsByType[SecurityScanType.PATCHING_CADENCE].filter(
                (f) => f.count > 0 && f.severity !== SecuritySeverity.INFO,
              ).length
            }{' '}
            findings)
            {isLoadingPatchingCadence ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorPatchingCadence || securityScan.previousScan?.patchingCadenceScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.PATCHING_CADENCE] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.PATCHING_CADENCE] || undefined,
              )}
            </div>
          )}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Open Ports and Network Vulnerabilities
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.NETWORK_SECURITY].filter((f) => f.count > 0).length} findings)
            {isLoadingScanNetwork ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanNetwork || securityScan.previousScan?.networkScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.NETWORK_SECURITY] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.NETWORK_SECURITY] || undefined,
              )}
            </div>
          )}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          IP Reputation
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.IP_REPUTATION].filter((f) => f.count > 0).length} findings)
            {isLoadingScanIpReputation ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanIpReputation || securityScan.previousScan?.ipReputationScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.IP_REPUTATION] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.IP_REPUTATION] || undefined,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
