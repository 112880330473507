import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, SUPPORT_URL } from 'telivy-constants';
import { isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const generateItems = (agent?: AgentDTO) => {
  const isGlobalAdmin = agent?.role === 'agency_admin' && agent?.agency?.isMasterAgency;
  // const isAgencyAdmin = !isGlobalAdmin && (agent?.role === 'agency_admin' || agent?.role === 'telivy_admin');

  const items = [
    // {
    //   url: ROUTES.agent.SUMMARY,
    //   label: isSecurityAgency(agent) ? 'Summary' : 'Security Summary',
    //   hide: !isSecurityAgency(agent),
    // },
    {
      url: ROUTES.agent.DASHBOARD,
      label: isSecurityAgency(agent) ? 'Assessments' : 'Applications',
      newTab: false,
    },
    {
      url: SUPPORT_URL,
      label: 'Support',
      newTab: true,
    },
  ];

  // if (isAgencyAdmin) {
  items.push({
    url: ROUTES.agent.account.ROOT,
    label: 'Account',
    newTab: false,
  });
  // }

  if (isGlobalAdmin) {
    items.push({
      url: ROUTES.agent.admin.ROOT,
      label: 'Global Admin',
      newTab: false,
    });
  }

  return items;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    color: COLORS.GREY_1,
    borderBottom: `2px solid transparent`,
    transition: 'all 0.3s',
  },
  activeItem: {
    borderColor: COLORS.BLUE_1,
  },

  icon: {
    marginRight: theme.spacing(),
  },
}));

interface Props {
  agent?: AgentDTO;
  className?: string;
}

export const Menu = ({ agent, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.list}>
        {generateItems(agent).map((el, idx) =>
          !el.newTab ? (
            <Link
              to={el.url}
              key={idx}
              className={cx(classes.item, el.url === location.pathname && classes.activeItem)}
            >
              {el.label}
            </Link>
          ) : (
            <a
              href={el.url}
              key={idx}
              target='_blank'
              rel='noreferrer'
              className={cx(classes.item, el.url === location.pathname && classes.activeItem)}
            >
              {el.label}
            </a>
          ),
        )}
      </div>
    </div>
  );
};
