import { makeStyles } from '@material-ui/core/styles';
// import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import cx from 'classnames';
// import { Badge, BadgeVariant } from 'components/Badge';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanGwsDomainUsers, DeepScanGwsUser } from 'dtos/deep-scan';
// import { useChartImage } from 'hooks/useChartImage';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
// import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanGoogleWsSecurityData } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ApplicationViewContainer } from 'views/agent/views/application-details/views/ApplicationViewContainer';

import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';
import { SecurityCard } from '../../components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  mapContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
  },

  mapContainerItem: {
    flex: 1,
    flexDirection: 'column',
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '&:last-child': {
      paddingTop: 0,
    },
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },
  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },
  red: {
    color: COLORS.RED_1,
  },
  green: {
    color: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.BLUE_1,
  },

  chart: {
    // maxHeight: 600,
  },
  chartContainer: {
    marginBottom: theme.spacing(2),
  },
  disclaimer: {
    width: 700,
    margin: '0 auto',
  },
  errorBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
  },

  legend: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },

  noBorder: {
    border: 'none',
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  isAdmin: boolean;
}

// const STRONG_THRESHOLD = 80;
// const MODERATE_THRESHOLD = 40;
const PER_PAGE = 8;
// const PER_PAGE_LOCATIONS = 15;

// const getScoreBadgeVariant = (scoreInPercentage: number | null): BadgeVariant => {
//   if (scoreInPercentage && scoreInPercentage > STRONG_THRESHOLD) {
//     return 'green';
//   } else if (scoreInPercentage && scoreInPercentage > MODERATE_THRESHOLD) {
//     return 'yellow';
//   } else {
//     return 'red';
//   }
// };

const getMFAScoreRanking = (mfaStatus: string): ScoreRanking => {
  switch (mfaStatus) {
    case 'Enabled':
      return 'Low';
    case 'Not enabled':
      return 'High';
    default:
      return 'None';
  }
};

export const DeepScanGoogleWsSecurity = ({ application, isAdmin }: Props) => {
  const classes = useStyles();
  const deepScanId = application.deepScanId || '';
  const { data: usersRaw, isLoading: isLoadingUsers } = useDeepScanGoogleWsSecurityData<DeepScanGwsDomainUsers>(
    deepScanId,
    'users',
    { enabled: Boolean(deepScanId) },
  );

  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);

  const [userPage, setUserPage] = useState(0);
  const [userSorter, setUserSorter] = useState<Sorter<keyof DeepScanGwsUser> | undefined>({
    key: 'name',
    order: SortOrder.ASC,
  });

  const users = useMemo(() => usersRaw?.users || [], [usersRaw]);

  const userColumns = useMemo((): Column<DeepScanGwsUser, keyof DeepScanGwsUser>[] => {
    return [
      {
        title: 'Name',
        sortKey: 'name',
        render: (row) => row.name.fullName,
      },
      {
        title: 'Email',
        sortKey: 'primaryEmail',
        render: (row) => row.primaryEmail,
      },
      {
        title: 'Sign-in Status',
        render: (row: DeepScanGwsUser) => (
          <ScoreBox
            ranking={getMFAScoreRanking(row.suspended || row.archived ? 'Not Enabled' : 'Enabled')}
            label={row.suspended ? 'Suspended' : row.archived ? 'Archived' : 'Active'}
          />
        ),
      },
      {
        title: 'MFA Status',
        render: (row) => {
          const mfaStatus = row.isEnrolledIn2Sv ? 'Enabled' : 'Not enabled';
          return <ScoreBox ranking={getMFAScoreRanking(mfaStatus)} label={mfaStatus} />;
        },
      },
      {
        title: 'Last Login',
        render: (row: DeepScanGwsUser) => {
          const date = row.lastLoginTime;
          return date ? dayjs(date).format('M/D/YYYY h:mm A') : 'N/A';
        },
      },
    ];
  }, []);

  const sortedUsers = useMemo(() => {
    const filteredUsers = users.filter((u) => u.primaryEmail);

    if (userSorter) {
      const data = sortBy(filteredUsers, userSorter.key);

      if (userSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(filteredUsers, (user) => user.name.fullName);
  }, [users, userSorter]);

  const usersData = useMemo(() => {
    return (sortedUsers || []).slice(userPage * PER_PAGE, userPage * PER_PAGE + PER_PAGE);
  }, [userPage, sortedUsers]);

  if (isLoadingUsers) {
    return <LoadingContainer />;
  }

  if (!enableCharts && !isAdmin) {
    return (
      <ApplicationViewContainer>
        <div className={classes.disclaimer}>
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        </div>
      </ApplicationViewContainer>
    );
  }

  if (!usersRaw) {
    return (
      <ApplicationViewContainer>
        <EmptyState title='Fetching data from Google Workspace in progress. Please check back after 15 minutes.' />
      </ApplicationViewContainer>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Google Workspace Security</div>

      <div className={classes.section}>
        {usersData.length > 0 && (
          <div className={classes.summary}>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.blue)}>{users?.length}</div>
              <div className={classes.description}>Total Accounts</div>
            </div>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.red)}>
                {(users || []).filter((u) => !u.isEnrolledIn2Sv).length}
              </div>
              <div className={classes.description}>Accounts without MFA</div>
            </div>
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Accounts Status
          <span className={classes.findings}> ({users.length} users)</span>
        </div>
        {usersRaw?.error && usersRaw?.error.indexOf('Not Authorized') > -1 && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            To get accounts data from Google Workspace, please connect using an admin account and ensure that Google
            Admin SDK API is enabled.
          </SecurityCard.Badge>
        )}
        {usersRaw?.error && isAdmin && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            [Admin] Error: {usersRaw?.error}
          </SecurityCard.Badge>
        )}
        {usersData.length > 0 && (
          <div className={classes.boxBorder}>
            <Table<DeepScanGwsUser, keyof DeepScanGwsUser>
              columns={userColumns}
              pagination={
                isLoadingUsers ? undefined : { page: userPage, perPage: PER_PAGE, total: sortedUsers?.length || 0 }
              }
              sorter={userSorter}
              onChange={(pagination, sorting) => {
                if (pagination?.page !== undefined) {
                  setUserPage(pagination?.page);
                }

                const isSorterChanging = userSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
                setUserSorter(sorting);

                if (isSorterChanging && pagination?.page !== 0) {
                  setUserPage(0);
                }
              }}
              rowContentCentered
              className={classes.table}
              rowKey={(row) => `${row.id}`}
              data={usersData}
              loading={isLoadingUsers}
            />
          </div>
        )}
      </div>
    </div>
  );
};
