import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import {
  BrowserPasswordDetailsDTO,
  DeepScanDetailsDTO,
  DeepScanTargetDetailsDTO,
  TelivyAgentDetailsDTO,
} from 'dtos/deep-scan';
import React, { useMemo } from 'react';

import { DetailsModal } from './DetailsModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
}));

interface Props {
  children?: React.ReactNode;
  hideDate?: boolean;
  date?: string | null;
  title: string;
  showInstallData?: boolean;
  checkRunAsAdmin?: boolean;
  checkRunAsUser?: boolean;
  checkInstalledBrowsers?: boolean;
  details?:
    | DeepScanTargetDetailsDTO
    | DeepScanDetailsDTO
    | TelivyAgentDetailsDTO
    | Record<string, BrowserPasswordDetailsDTO>
    | null;
  isAdmin: boolean;
}

export const DetailsColumn = ({
  details,
  children,
  title,
  showInstallData,
  checkRunAsUser,
  // checkRunAsAdmin,
  checkInstalledBrowsers,
  date,
  hideDate,
  isAdmin,
}: Props) => {
  const classes = useStyles();
  const parsedDate = date ? dayjs(date).format('M/D/YYYY h:mm A') : 'Not installed';

  const installData = useMemo(() => {
    const installDetails = details as TelivyAgentDetailsDTO;
    const sbpDetails = details as Record<string, BrowserPasswordDetailsDTO>;

    // if (checkRunAsAdmin && installDetails) {
    //   if (!installDetails.nodeware_installed && installDetails.run_as_admin !== 'True') {
    //     return 'Error: Please Run as Admin';
    //   }
    // }

    if (checkRunAsUser && sbpDetails) {
      for (const k in sbpDetails) {
        if (sbpDetails[k].run_as_admin !== 'False') {
          return 'Error: Please Run as Local User';
        }
      }
    }

    if (checkInstalledBrowsers && sbpDetails) {
      for (const k in sbpDetails) {
        if (sbpDetails[k].installed_browsers && !sbpDetails[k].installed_browsers?.length) {
          return 'Error: No supported browsers detected';
        }
      }
    }

    if (showInstallData && installDetails) {
      const pending = [];
      if (!installDetails.nodeware_installed && !installDetails.scan_apps_os_version) {
        pending.push('vulnerability');
      }
      if (
        !installDetails.actifile_installed &&
        !installDetails.pii_tools_installed &&
        installDetails.scan_pii_total_found === undefined
      ) {
        pending.push('pii');
      }
      if (!installDetails.augment_installed && installDetails.scan_applications_total_found === undefined) {
        pending.push('apps');
      }
      if (!installDetails.schedule_scan_install_done) {
        pending.push('passwords');
      }
      return `Error: Tools not installed ${pending.length}/4 ${JSON.stringify(pending)}`;
    }

    if (details) {
      return 'Error: Incomplete Execution';
    }

    return 'Not Started';
  }, [showInstallData, details, checkRunAsUser, checkInstalledBrowsers]);

  return (
    <div className={classes.root}>
      {children}
      {!hideDate && date ? `Completed at ${parsedDate}` : null}
      {!date ? installData : null}
      {isAdmin && details ? <DetailsModal title={title} details={details} /> : null}
    </div>
  );
};
