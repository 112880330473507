import { makeStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Badge, BadgeVariant } from 'components/Badge';
import { Modal } from 'components/Modal';
import { Column, Props as TableProps, Table } from 'components/Table';
import { BrowserPasswordDTO } from 'dtos/deep-scan';
import React, { useMemo } from 'react';
import { COLORS } from 'telivy-theme';

type Props = Omit<TableProps<BrowserPasswordDTO, keyof BrowserPasswordDTO>, 'columns' | 'rowKey'>;

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
  breaches: {
    minWidth: '80px',
    maxWidth: '80px',
  },
}));

const STRONG_THRESHOLD = 0.62;
const MODERATE_THRESHOLD = 0.38;
export const getPasswordStrengthBadgeVariant = (passwordStrength: number | null): BadgeVariant => {
  if (passwordStrength && passwordStrength > STRONG_THRESHOLD) {
    return 'green';
  } else if (passwordStrength && passwordStrength > MODERATE_THRESHOLD) {
    return 'yellow';
  } else if (passwordStrength) {
    return 'red';
  } else {
    return 'grey';
  }
};

export const getPasswordStrengthTitle = (passwordStrength: number | null): string => {
  if (passwordStrength && passwordStrength > STRONG_THRESHOLD) {
    return 'Strong';
  } else if (passwordStrength && passwordStrength > MODERATE_THRESHOLD) {
    return 'Moderate';
  } else if (passwordStrength) {
    return 'Weak';
  } else {
    return 'N/A';
  }
};

export const BrowserPasswordsTable = ({ onRowClick, ...restProps }: Props) => {
  const classes = useStyles();

  const columns = useMemo((): Array<Column<BrowserPasswordDTO, keyof BrowserPasswordDTO>> => {
    return [
      {
        title: 'Hostname',
        width: '10%',
        sortKey: 'hostname',
        render: (browserPassword) => {
          return browserPassword.hostname;
        },
      },
      {
        title: 'Local User',
        width: '10%',
        sortKey: 'localUser',
        render: (browserPassword) => {
          return browserPassword.localUser;
        },
      },
      {
        title: 'Browser',
        width: '10%',
        sortKey: 'browser',
        render: (browserPassword) => {
          return browserPassword.browser;
        },
      },
      {
        title: 'URL',
        width: '20%',
        sortKey: 'url',
        render: (browserPassword) => {
          return <span style={{ wordBreak: 'break-word' }}>{browserPassword.url}</span>;
        },
      },
      {
        title: 'Username',
        width: '15%',
        sortKey: 'username',
        render: (browserPassword) => {
          return browserPassword.username;
        },
      },
      {
        title: (
          <span>
            Password
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Password'>
              <p>Empty password field indicates either password was not saved in browser or could not be decrypted</p>
            </Modal>
          </span>
        ),
        width: '15%',
        sortKey: 'passwordMasked',
        render: (browserPassword) => {
          return <span style={{ wordBreak: 'break-word' }}>{browserPassword.passwordMasked}</span>;
        },
      },
      {
        title: 'Password Strength',
        sortKey: 'passwordStrength',
        width: '10%',
        render: (browserPassword) => (
          <Badge
            bordered
            variant={getPasswordStrengthBadgeVariant(browserPassword.passwordStrength)}
            style={{ minWidth: 80, width: 80 }}
          >
            {getPasswordStrengthTitle(browserPassword.passwordStrength)}
          </Badge>
        ),
      },
      {
        title: (
          <span className={classes.breaches}>
            Password Breaches
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Password Breaches'>
              <p>
                Number of breaches this specific password was found in. Note that this count is across multiple
                usernames.
              </p>
            </Modal>
          </span>
        ),
        width: '10%',
        sortKey: 'passwordBreached',
        render: (browserPassword) => {
          return browserPassword.passwordMasked !== 'NA' ? browserPassword.passwordBreached : null;
        },
      },
    ];
  }, [classes]);

  return (
    <Table<BrowserPasswordDTO>
      hideHeadersWhenEmpty
      columns={columns}
      onRowClick={onRowClick}
      rowKey={(el) => el.id}
      {...restProps}
    />
  );
};
