import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    borderColor: COLORS.GREY_4,
    color: COLORS.GREY_1,
  },
}));

interface Props {
  isDownloading?: boolean;
  onClick?: () => void;
}

export const DownloadDocumentsButton: React.FC<Props> = ({ isDownloading, onClick }) => {
  const classes = useStyles();

  return (
    <Button color='primary' className={classes.root} disabled={isDownloading} onClick={onClick}>
      {isDownloading ? 'Downloading...' : 'Download'}
    </Button>
  );
};
